import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useGlobal } from "../../contexts/globalContext";

const Footer = (props) => {
  const { global } = useGlobal();
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              <Link
                to={`/`}
                onClick={(event) => {
                  event.preventDefault();
                  window.open('https://www.clinicacaoecia.com.br/', '_blank')
                }}
                style={{ color: '#495057' }}
              >
                {global.ambiente} {new Date().getFullYear()} © {global.empresa}.
              </Link>
            </Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                <Link
                  to={`/`}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open('https://catec.inf.br/', '_blank')
                  }}
                  style={{ color: '#495057' }}
                >
                  Desenvolvido por {global.fabricante}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
