import React, { useEffect, useState, useCallback } from "react";
import queryString from "query-string";
import {
  Container,
  FormGroup,
  Label,
  Button,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation-safe";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import Select from "react-select";

import api from "../../../services/api";
import MessageBox from "../../../components/Common/MessageBox";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const NotificacaoEnvioManual = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [modalSucesso, setModalSucesso] = useState(false);

  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);

  const [textareabadge, settextareabadge] = useState(0);
  const [textcount, settextcount] = useState(0);

  function textareachange(event) {
    var count = event.target.value.length;
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(event.target.value.length);
  }

  useEffect(() => {
    async function getCliente() {
      try {
        setAguarde(true);

        const response = await api.get("/web/cliente/notificacao");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const listaCliente = response.data.data.map((cliente) => {
          return {
            label: cliente.nome,
            value: cliente.clienteId,
          };
        });

        setClientes([{ label: "Todos", value: "-1" }, ...listaCliente]);
      } catch (err) {
        setClientes([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCliente();
  }, [props.location.search, setAguarde, setMessageBox]);

  useEffect(() => {
    const values = queryString.parse(props.location.search);

    let auxId;
    if (values.id) {
      auxId = values.id;
    } else {
      auxId = -1;
    }

    if (auxId > 0) {
      const auxCliente = clientes.filter(
        (cliente) => cliente.value.toString() === auxId
      );

      setClienteSelecionado(auxCliente[0]);
    }
  }, [clientes, props.location.search]);

  const optionCliente = [
    {
      options: clientes,
    },
  ];

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        if (clienteSelecionado.value === undefined) {
          await setMessageBox({
            show: true,
            title: "Erro",
            description: "Cliente não informado",
            error: true,
          });

          return;
        }

        setAguarde(true);
        let url = `/web/notificacao/enviar`;
        let param = {
          titulo: values.titulo,
          texto: values.mensagem,
        };

        if (clienteSelecionado.label === "Todos") {
          url += "/todos";
        } else {
          param = {
            clienteId: clienteSelecionado.value,
            ...param,
          };
        }

        const response = await api.post(url, param);
        setAguarde(false);

        /*
        const response = await api.post(`/web/notificacao/enviar`, {
          clienteId: clienteSelecionado.value,
          titulo: values.titulo,
          texto: values.mensagem,
        });
*/

        if (!response.data.success) {
          throw response.data;
        }

        setModalSucesso(true);
      } catch (err) {
        setAguarde(false);

        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [clienteSelecionado, setAguarde, setMessageBox]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Envio Manual" breadcrumbItem="Notificações" />

          {modalSucesso ? (
            <MessageBox
              show={modalSucesso}
              title="Sucesso"
              description="Notificação enviado com sucesso!"
              success
              handleConfirm={() => {
                setModalSucesso(false);
                window.location.reload();
              }}
            />
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label>Cliente</Label>
                          <Select
                            placeholder="Selecione um Cliente"
                            value={clienteSelecionado}
                            onChange={setClienteSelecionado}
                            options={optionCliente}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="titulo"
                            label="Título"
                            className="form-control"
                            placeholder="Informe o título da mensagem a ser enviada."
                            type="text"
                            required
                            maxLength="75"
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Título não informado"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <AvField
                            name="mensagem"
                            label="Mensagem"
                            className="form-control"
                            placeholder="Informe a mensagem a ser enviada."
                            type="textarea"
                            onChange={(e) => {
                              textareachange(e);
                            }}
                            maxLength="225"
                            rows="3"
                            required
                            errorMessage="Mensagem não informada"
                          />
                          {textareabadge ? (
                            <span className="badgecount badge badge-success">
                              {" "}
                              {textcount} / 225{" "}
                            </span>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="text-sm-right">
                      <Button
                        type="submit"
                        color="success"
                        className="btn-rounded mr-1 waves-effect waves-light"
                      >
                        Enviar
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotificacaoEnvioManual;
