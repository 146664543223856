import React, { memo } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
// import enumTypeMessageBox from "./enumTypeMessageBox";

const MessageBox = ({
  show,
  title,
  description,
  success,
  error,
  warning,
  handleConfirm,
}) => {
  // const [success, setSuccess] = useState();
  // const [error, setError] = useState();
  // const [warning, setWarning] = useState();

  /*
  switch (type) {
    case enumTypeMessageBox.SUCCESS:
      setSuccess(true);
      break;
    case enumTypeMessageBox.ERROR:
      setError(true);
      break;
    case enumTypeMessageBox.WARNING:
      setWarning(true);
      break;
    default:
  }
  */

  return (
    <SweetAlert
      show={show}
      success={success}
      error={error}
      warning={warning}
      title={title}
      onConfirm={handleConfirm}
    >
      {description}
    </SweetAlert>
  );
};

export default memo(MessageBox);
