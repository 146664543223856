import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//i18n
import { withNamespaces } from "react-i18next";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell"></i>
          {/*<i className="bx bx-bell bx-tada"></i>
          <span className="badge badge-danger badge-pill">1</span> */}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notificações")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  {" "}
                  Ver mais
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            <Link to="" className="notification-item">
              <div className="media">
                <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t("Nenhuma notificação não lida!")}
                  </h6>
                </div>
              </div>  
            </Link>
            {/*
            <Link to="" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="bx bx-cart"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t("Alerta positivo de vendas")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "Suas vendas superaram as espectativas em relação ao mês anterior"
                      )}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      {props.t("1 hora atrás")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            */}
          </SimpleBar>
          <div className="p-2 border-top">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notificacao"
            >
              {" "}
              {props.t("Ir para Notificações")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withNamespaces()(NotificationDropdown);
