import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../components/ActionButton";
import api from "../../../services/api";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const UsuarioIncluirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const history = useHistory();

  const [perfil, setPerfil] = useState([]);
  const [perfilSelecionado, setPerfilSelecionado] = useState([]);

  useEffect(() => {
    setPerfil([
      { label: "Sistema", value: 2 },
      { label: "Vendedor", value: 4 },
    ]);
  }, [setAguarde]);

  const optionPerfil = [
    {
      options: perfil,
    },
  ];

  const handleSubmit = useCallback(
    async (_event, values) => {
      try {
        if (perfilSelecionado.value) {
          setAguarde(true);

          const response = await api.post("/web/usuario/", {
            nome: values.nome,
            email: values.email,
            perfilId: perfilSelecionado.value,
          });
          if (!response.data.success) {
            throw response.data;
          }

          setAguarde(false);
          history.push("/cadastros/usuario/listar");
        } else {
          await setMessageBox({
            show: true,
            title: "Atenção",
            description: "Perfil não selecionado!",
            warning: true,
          });
        }
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [history, perfilSelecionado.value, setAguarde, setMessageBox]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações do Usuário - Inclusão"
            breadcrumbItem="Usuários"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <AvField
                            name="nome"
                            label="Nome"
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Nome não informado"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="4">
                        <FormGroup>
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder=""
                            type="email"
                            required
                            autoComplete="off"
                            errorMessage="Email inválido"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="4">
                        <FormGroup>
                          <Label>Perfil</Label>
                          <Select
                            placeholder="Selecione um Perfil"
                            value={perfilSelecionado}
                            onChange={setPerfilSelecionado}
                            options={optionPerfil}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsuarioIncluirPage;
