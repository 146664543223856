const columnsProfissional = (profissionais) => {
  return {
    columns: [
      {
        label: "#",
        field: "profissionalId",
        sort: "asc",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
      },
      {
        label: "CPF",
        field: "cpf",
      },
      {
        label: "Celular",
        field: "celular",
      },
      {
        label: "Gênero",
        field: "genero",
      },
      {
        label: "Nascimento",
        field: "dataNascimento",
      },
      {
        label: "Status",
        field: "usuarioStatus",
      },
      {
        label: "Último Acesso",
        field: "usuarioDataUltimoAcesso",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: profissionais,
  };
};

const columnsDispositivo = (dispositivos) => {
  return {
    columns: [
      {
        label: "#",
        field: "dispositivoId",
        sort: "asc",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
      },
      {
        label: "Modelo",
        field: "modelo",
      },
      {
        label: "SO",
        field: "plataforma",
      },
      {
        label: "Serial",
        field: "pushToken",
      },
      {
        label: "Padrão",
        field: "padrao",
      },
      {
        label: "Data Cadastro",
        field: "dataCadastro",
      },
      {
        label: "Último Acesso",
        field: "dataUltimoAcesso",
      },
    ],
    rows: dispositivos,
  };
};

export { columnsProfissional, columnsDispositivo };
