import React, { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import IntlCurrencyInput from "react-intl-currency-input";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import { isAfter, parseISO } from "date-fns";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Button,
  Tooltip,
} from "reactstrap";
import ShowToast from "../../../helpers/toast_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import api from "../../../services/api";
import currencyConfig from "../../../helpers/currency_helper";
import { columnsParticipantes, columnsCupons } from "./datatableColumns";
import "../../../shared/datatables.scss";

const SorteioManual = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [modalGanhador, setModalGanhador] = useState(false);
  const [tituloParticipantes, setTituloParticipantes] = useState(
    "LISTA DE PARTICIPANTES"
  );
  const [ttop, setttop] = useState(false);
  const [participantes, setParticipantes] = useState([]);
  const [participanteSorteado, setParticipanteSorteado] = useState([]);
  const [cupons, setCupons] = useState([]);
  const [cupomSorteado, setCupomSorteado] = useState([]);
  const [valorMinimo, setValorMinimo] = useState(0);
  const [bloqueiaTela, setBloqueiaTela] = useState(false);
  const [sorteioRealizado, setSorteioRealizado] = useState(false);
  const [tituloCupons, setTituloCupons] = useState("LISTA DE CUPONS");
  const premioSorteado = useRef(null);
  const dataInicial = useRef(null);
  const dataFinal = useRef(null);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleOpenWhatsApp = useCallback((fone) => {
    window.open(
      `https://api.whatsapp.com/send/?phone=55${fone}&text&app_absent=0`,
      "_blank"
    );
  }, []);

  const handleChangeValorMinimo = useCallback(
    async (event, value, maskedValue) => {
      await event.preventDefault();
      setValorMinimo(parseFloat(value));
    },
    []
  );

  const handleLimparSorteio = useCallback(async () => {
    setParticipantes([]);
    setCupons([]);
    setBloqueiaTela(false);
    setSorteioRealizado(false);
    setValorMinimo(0);
    premioSorteado.current.value = "";

    setTituloParticipantes("LISTA DE PARTICIPANTES");
    setTituloCupons("LISTA DE CUPONS");
  }, []);

  const handleSortearNovamente = useCallback(async () => {
    const auxListaParticipante = participantes.filter(
      (participante) =>
        participante.clienteId !== participanteSorteado[0].clienteId
    );

    const auxListaCupons = cupons.filter(
      (cupom) => cupom.clienteId !== participanteSorteado[0].clienteId
    );

    setParticipantes(auxListaParticipante);
    setParticipanteSorteado(auxListaParticipante);
    setCupons(auxListaCupons);
    setCupomSorteado(auxListaCupons);
    setSorteioRealizado(false);

    setTituloParticipantes("LISTA DE PARTICIPANTES");
    setTituloCupons("LISTA DE CUPONS");
  }, [participantes, cupons, participanteSorteado]);

  const handleBuscarClienteParticipante = useCallback(async () => {
    try {
      if (dataInicial.current.value === "") {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: "Data Inicial não informada!",
          error: true,
        });
        return;
      }

      if (dataFinal.current.value === "") {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: "Data Final não informada!",
          error: true,
        });
      }

      if (
        isAfter(
          parseISO(dataInicial.current.value),
          parseISO(dataFinal.current.value)
        )
      ) {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: "Data inicial deve ser menor ou igual a data final!",
          error: true,
        });
        return;
      }

      setAguarde(true);

      const response = await api.get(
        `/web/sorteiomanual/de/${dataInicial.current.value}/ate/${dataFinal.current.value}/minimo/${valorMinimo}`
      );

      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      setBloqueiaTela(true);

      setParticipantes(response.data.data.participantes);
      setCupons(response.data.data.cuponsGerados);

      setParticipanteSorteado(response.data.data.participantes);
      setCupomSorteado(response.data.data.cuponsGerados);
    } catch (err) {
      setAguarde(false);
      setParticipantes([]);
      setCupons([]);

      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [setAguarde, setMessageBox, valorMinimo]);

  const handleSortear = useCallback(async () => {
    try {
      setAguarde(true);

      await delay(3000);
      for (let i = 0; i < cupons.length; i++) {
        const j = Math.floor(Math.random() * (i + 1));
        [cupons[i], cupons[j]] = [cupons[j], cupons[i]];
      }

      const listaParticipanteSorteado = participantes.filter(
        (participante) => participante.clienteId === cupons[0].clienteId
      );

      const participanteSorteadoAction = listaParticipanteSorteado.map(
        (participante) => {
          return {
            ...participante,
            action: (
              <>
                <Link
                  to={`/`}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(`mailto:${participante.email}`);
                  }}
                  className="mr-1 text-dark"
                >
                  <i
                    className="bx bx-mail-send font-size-18 align-middle mr-1"
                    id="exibirTooltip"
                  ></i>
                </Link>
                <Link
                  to={`/`}
                  onClick={(event) => {
                    event.preventDefault();
                    handleOpenWhatsApp(participante.celular);
                  }}
                  className="mr-1 text-success"
                >
                  <i
                    className="mdi mdi-whatsapp font-size-18 align-middle mr-1"
                    id="whatsAppTooltip"
                  ></i>
                </Link>
              </>
            ),
          };
        }
      );

      const listaCupomSorteado = cupons.filter(
        (cupom) => cupom.cupom === cupons[0].cupom
      );

      setTituloParticipantes("PARTICIPANTE GANHADOR");
      setTituloCupons("CUPOM GANHADOR");

      setParticipanteSorteado(participanteSorteadoAction);
      setCupomSorteado(listaCupomSorteado);
      setSorteioRealizado(true);

      setAguarde(false);
    } catch (err) {
      setAguarde(false);

      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [cupons, handleOpenWhatsApp, participantes, setAguarde, setMessageBox]);

  const handleNotificarGanhador = useCallback(async () => {
    try {
      setModalGanhador(false);
      setAguarde(true);

      const { clienteId } = participanteSorteado[0];
      const response = await api.post("/web/sorteiomanual/confirmar", {
        clienteId,
        descricaoPremio: premioSorteado.current.value,
      });

      setAguarde(false);
      if (!response.data.success) {
        throw response.data;
      }

      handleLimparSorteio();
      ShowToast("Ganhador notificado com sucesso!");
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [
    setAguarde,
    setMessageBox,
    participanteSorteado,
    premioSorteado,
    handleLimparSorteio,
  ]);

  const handleConfirmarGanhador = useCallback(async () => {
    if (premioSorteado.current.value === "") {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Prêmio não informado!",
        error: true,
      });
      return;
    }
    setModalGanhador(true);
  }, [setMessageBox, premioSorteado]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Sorteio Manual" breadcrumbItem="Sorteio" />

          {modalGanhador && (
            <SweetAlert
              showCancel
              title="Ganhador"
              cancelBtnBsStyle="danger"
              confirmBtnBsStyle="success"
              confirmBtnText="Confirmar"
              cancelBtnText="Cancelar"
              onConfirm={handleNotificarGanhador}
              onCancel={() => {
                setModalGanhador(false);
              }}
            >
              <h1 class="text-success">{participanteSorteado[0].nome}</h1>
              <h4>Cupom: {cupomSorteado[0].cupom}</h4>
            </SweetAlert>
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>Prêmio Sorteado</Label>
                          <input
                            ref={premioSorteado}
                            className="form-control"
                            placeholder="Descreva aqui o prêmio que será sorteado..."
                            type="text"
                            id="premioSorteado"
                            autoFocus={true}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Data da Compra Inicial</Label>
                          <input
                            ref={dataInicial}
                            className="form-control"
                            type="date"
                            id="dataInicial"
                            disabled={bloqueiaTela}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Data da Compra Final</Label>
                          <input
                            ref={dataFinal}
                            className="form-control"
                            type="date"
                            id="dataFinal"
                            disabled={bloqueiaTela}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label id="lblVlrMinimo">
                            Valor Mínimo &nbsp;
                            <i className="mdi mdi-information"></i>
                            <Tooltip
                              placement="top"
                              isOpen={ttop}
                              target="lblVlrMinimo"
                              toggle={() => {
                                setttop(!ttop);
                              }}
                            >
                              Cada compra gera 1 cupom quando informado valor
                              mínimo igual a zero
                            </Tooltip>
                          </Label>
                          <IntlCurrencyInput
                            currency="BRL"
                            autoSelect={true}
                            value={parseFloat(valorMinimo)}
                            config={currencyConfig}
                            max={9999.99}
                            onChange={handleChangeValorMinimo}
                            className="form-control"
                            disabled={bloqueiaTela}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label></Label>
                          <div className="text-sm-right">
                            {bloqueiaTela ? (
                              <Button
                                type="button"
                                color="danger"
                                className="btn-rounded waves-effect waves-light"
                                onClick={handleLimparSorteio}
                              >
                                <i className="mdi mdi-lock-reset">&nbsp;</i>
                                Novo Sorteio
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded mr-1 waves-effect waves-light"
                                onClick={handleBuscarClienteParticipante}
                                disabled={bloqueiaTela}
                              >
                                <i className="mdi mdi-magnify">&nbsp;</i>
                                Buscar Participantes
                              </Button>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {participantes.length > 0 && (
            <>
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      {sorteioRealizado ? (
                        <>
                          <Button
                            type="button"
                            color="info"
                            className="btn-rounded waves-effect waves-light"
                            onClick={handleSortearNovamente}
                          >
                            <i className="mdi mdi-dice-multiple">&nbsp;</i>
                            Sortear Novamente
                          </Button>
                          &emsp;
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded waves-effect waves-light"
                            onClick={handleConfirmarGanhador}
                          >
                            <i className="mdi mdi-trophy">&nbsp;</i>
                            Confirmar Ganhador
                          </Button>
                        </>
                      ) : (
                        <Button
                          type="button"
                          color="info"
                          className="btn-rounded waves-effect waves-light"
                          onClick={handleSortear}
                        >
                          <i className="mdi mdi-dice-multiple">&nbsp;</i>
                          Realizar Sorteio
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="col-8">
                  <CardTitle>{tituloParticipantes}</CardTitle>
                  <Card>
                    <CardBody>
                      <MDBDataTable
                        displayEntries={false}
                        searching={false}
                        fixed
                        hover
                        striped
                        bordered
                        responsive
                        responsiveSm
                        responsiveMd
                        responsiveLg
                        responsiveXl
                        small
                        entriesLabel=""
                        entries={10}
                        entriesOptions={[10, 20, 50, 100]}
                        infoLabel={["", "até", "de", ""]}
                        noRecordsFoundLabel="Nemhum registro encontrado"
                        paginationLabel={["<", ">"]}
                        searchLabel="Localizar"
                        noBottomColumns={true}
                        data={columnsParticipantes(participanteSorteado)}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col className="col-4">
                  <CardTitle>{tituloCupons}</CardTitle>
                  <Card>
                    <CardBody>
                      <MDBDataTable
                        displayEntries={false}
                        searching={false}
                        fixed
                        hover
                        striped
                        bordered
                        responsive
                        responsiveSm
                        responsiveMd
                        responsiveLg
                        responsiveXl
                        small
                        entriesLabel=""
                        entries={10}
                        entriesOptions={[10, 20, 50, 100]}
                        infoLabel={["", "até", "de", ""]}
                        noRecordsFoundLabel="Nemhum registro encontrado"
                        paginationLabel={["<", ">"]}
                        searchLabel="Localizar"
                        noBottomColumns={true}
                        data={columnsCupons(cupomSorteado)}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SorteioManual;
