import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const EmpresaAtendimentoAlterarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const history = useHistory();

  const [registro, setRegistro] = useState([]);
  const [atendimentoId, setAtendimentoId] = useState("");

  const [tiposAtendimentos, setTiposAtendimentos] = useState([]);
  const [tipoAtendimentoSelecionado, setTipoAtendimentoSelecionado] = useState(
    []
  );

  const optionTipoAtendimento = [
    {
      options: tiposAtendimentos,
    },
  ];

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        if (tipoAtendimentoSelecionado.value) {
          setAguarde(true);
          const response = await api.patch(
            `/web/horarioatendimentoempresa/padrao/${atendimentoId}`,
            {
              tipoAtendimentoId: tipoAtendimentoSelecionado.value,
              descricao: values.horario,
            }
          );
          setAguarde(false);

          if (!response.data.success) {
            throw response.data;
          }

          history.push("/cadastros/empresa/atendimento/listar");
        }
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [
      atendimentoId,
      history,
      setAguarde,
      setMessageBox,
      tipoAtendimentoSelecionado.value,
    ]
  );

  useEffect(() => {
    async function getTipoAtendimento() {
      try {
        const response = await api.get("/web/tipoatendimento");

        if (!response.data.success) {
          throw response.data;
        }

        const listaTipoAtendimento = response.data.data.map(
          (tipoAtendimento) => {
            return {
              label: tipoAtendimento.descricao,
              value: tipoAtendimento.tipoAtendimentoId,
            };
          }
        );

        setTiposAtendimentos(listaTipoAtendimento);
      } catch (err) {
        setTiposAtendimentos([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getTipoAtendimento();
  }, [setAguarde, setMessageBox]);

  useEffect(() => {
    async function getAtendimentoEmpresa() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        setAtendimentoId(values.id);
        const response = await api.get(
          `/web/horarioatendimentoempresa/padrao/${values.id}`
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const tipoAtendimentoEmpresa = response.data.data;

        const auxTipoAtendimento = tiposAtendimentos.filter(
          (tipoAtendimento) =>
            tipoAtendimento.value === tipoAtendimentoEmpresa.tipoAtendimentoId
        );

        if (auxTipoAtendimento[0]) {
          setTipoAtendimentoSelecionado(auxTipoAtendimento[0]);
        }

        setRegistro(tipoAtendimentoEmpresa);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getAtendimentoEmpresa();
  }, [props.location.search, setAguarde, setMessageBox, tiposAtendimentos]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Horários de Atendimento - Edição"
            breadcrumbItem="Horário de Atendimento"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Tipo de Atendimento</Label>
                          <Select
                            placeholder="Selecione um tipo de atendimento"
                            value={tipoAtendimentoSelecionado}
                            onChange={setTipoAtendimentoSelecionado}
                            options={optionTipoAtendimento}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="8">
                        <FormGroup>
                          <AvField
                            name="horario"
                            label="Horário"
                            value={registro.descricao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            autoFocus={true}
                            errorMessage="Horário não informado"
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmpresaAtendimentoAlterarPage;
