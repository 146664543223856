import React from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//import api from "../../services/api";

//import { useAguarde } from "../../contexts/globalContext";

const Notificacao = (props) => {
  //const { setAguarde } = useAguarde();
  //const [configuracoes, setConfiguracoes] = useState(null);

  /*
  useEffect(() => {
    setAguarde(true);
    api
      .get("/web/configuracao")
      .then((response) => {
        setAguarde(false);
        if (response.data.success) {
          setConfiguracoes(response.data.data);
        } else {
          setConfiguracoes(null);
        }
      });
  }, [setAguarde]); */


  return (
    <React.Fragment>
      <div className="page-content">
        <container fluid={true}>
          <Breadcrumbs
            title="Lista de Notificações"
            breadcrumbItem="Notificações"
          />

          
        </container>
      </div>
    </React.Fragment>
  );
};

export default Notificacao;
