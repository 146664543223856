import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation-safe";

// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatarPadrao from "../../assets/images/users/avatar-padrao.jpg";
// actions
import { editProfile } from "../../store/actions";


import api from "../../services/api";

import { useGlobal, useAguarde, useMessageBox } from "../../contexts/globalContext";

const AlterarSenha = (props) => {
  const history = useHistory();

  const { global } = useGlobal();
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);

  useEffect(() => {
    if (localStorage.getItem("@Cashback:authUser")) {
      const obj = JSON.parse(localStorage.getItem("@Cashback:authUser"));
      
      setname(obj.username);
      setemail(obj.email);
      setidx(obj.uid);
      
    }
  }, [props.success]);

  const handleValidSubmit = useCallback(
    async (event, values) => {
      try {

        if (values.senhaNova !== values.senhaConfirmar) {
          await setMessageBox({
            show: true,
            title: "Erro",
            description: "Nova Senha e Confirmar Senha não conferem.",
            error: true,
          });
          return;
        }
        
        setAguarde(true);

        const response = await api.patch("/web/usuario", {
          usuarioId: idx,
          senhaAtual: values.senhaAtual,
          novaSenha: values.senhaNova
        });

        setAguarde(false);

        if (response.data.success) {
          await setMessageBox({
            show: true,
            title: "Sucesso",
            description: "Senha alterada com sucesso!",
            success: true,
            handleConfirm: () => history.goBack(),
          });
          return;
        }

        await setMessageBox({
          show: true,
          title: "Erro",
          description: response.data.message,
          error: true,
        });

      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
  },[setAguarde, idx, setMessageBox, history]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={global.empresa} breadcrumbItem="Meu Perfil" />

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success && props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="mr-3">
                      <img
                        src={avatarPadrao}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">ID: {idx}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Alterar Senha</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <div className="form-group">
                  <AvField
                    name="senhaAtual"
                    label="Senha Atual"
                    className="form-control"
                    placeholder="Informe a senha atual"
                    type="password"
                    required
                    errorMessage="Senha atual não informada"
                    autoFocus={true}
                  />
                  <AvField
                    name="senhaNova"
                    label="Nova Senha"
                    className="form-control"
                    placeholder="Informe sua nova senha"
                    type="password"
                    required
                    errorMessage="Nova senha não informada"
                  />
                  <AvField
                    name="senhaConfirmar"
                    label="Confirmar Senha"
                    className="form-control"
                    placeholder="Confirme sua nova senha"
                    type="password"
                    required
                    errorMessage="Confirmar senha não informada"
                  />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="success">
                    Alterar
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile })(AlterarSenha)
);
