import React, { useEffect, useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../../../services/api";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import columnsRedeSocial from "./datatableColumns";

const EmpresaRedeSocialListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [redeSocialSelecionado, setRedeSocialSelecionado] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [redeSocial, setRedeSocial] = useState([]);

  const excluirRedeSocial = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(
        `/web/redeSocialempresa/padrao/${redeSocialSelecionado}`
      );
      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const auxRedeSocials = redeSocial.filter(
        (redeSocial) => redeSocial.redeSocialId !== redeSocialSelecionado
      );

      setRedeSocial(auxRedeSocials);

      await setMessageBox({
        show: true,
        title: "Sucesso",
        description: "Rede Social excluída com sucesso.",
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [redeSocialSelecionado, redeSocial, setAguarde, setMessageBox]);

  useEffect(() => {
    async function getRedeSocial() {
      try {
        setAguarde(true);
        const response = await api.get("/web/redesocialempresa/padrao");
        setAguarde(false);
        if (response.data.success) {
          const listaRedeSocial = response.data.data.map((redeSocial) => {
            return {
              ...redeSocial,
              action: (
                <>
                  <Link
                    to={`/cadastros/empresa/redesocial/exibir?id=${redeSocial.redeSocialEmpresaId}`}
                    className="mr-1 text-info"
                  >
                    <i
                      className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                      id="exibirTooltip"
                    ></i>
                  </Link>

                  <Link
                    to={`/cadastros/empresa/redesocial/alterar?id=${redeSocial.redeSocialEmpresaId}`}
                    className="mr-1 text-warning"
                  >
                    <i
                      className="bx bx-edit-alt font-size-18 align-middle mr-1"
                      id="alterarTooltip"
                    ></i>
                  </Link>

                  <Link
                    onClick={() => {
                      setRedeSocialSelecionado(redeSocial.redeSocialEmpresaId);
                      setModalExcluir(true);
                    }}
                    to={() => {}}
                    className="mr-1 text-danger"
                  >
                    <i
                      className="bx bx-trash font-size-18 align-middle mr-1"
                      id="excluirTooltip"
                    ></i>
                  </Link>
                </>
              ),
            };
          });
          setRedeSocial(listaRedeSocial);
        } else {
          setRedeSocial([]);
        }
      } catch (err) {
        setRedeSocial([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getRedeSocial();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Lista de Redes Sociais da Empresa"
            breadcrumbItem="Rede Social da Empresa"
          />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir esta rede social?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirRedeSocial}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/empresa/redesocial/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsRedeSocial(redeSocial)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmpresaRedeSocialListarPage;
