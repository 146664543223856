import React, { Component } from "react";
//mport { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
//import CardUser from "./card-user";
//import MiniWidget from "./mini-widget";
//import SalesAnalytics from "./sales-analytics";
//import TotalSellingProduct from "./total-selling-product";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [
        {
          icon: "bx bx-copy-alt",
          title: "Vacinas",
          value: "145",
          badgeValue: "+ 3.25%",
          color: "success",
          desc: "Maior que o mês anterior",
        },
        {
          icon: "bx bx-archive-in",
          title: "Serviços Gerais",
          value: "R$ 28,45",
          badgeValue: "+ 2.5%",
          color: "success",
          desc: "maior que o mês anterior",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Ticket médio",
          value: "R$ 152,20",
          badgeValue: "0%",
          color: "warning",
          desc: "sem crescimento",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        {/*
        <div className="page-content">
          <Container fluid>
            
            <Breadcrumbs title="Painel de Controle" breadcrumbItem="Vendas" />

           
            <CardUser />

            <Row>
              <Col xl="12">
                <Row>
                  
                  <MiniWidget reports={this.state.reports} />
                </Row>
              </Col>
            </Row>

            <Row>
              <TotalSellingProduct />

              <SalesAnalytics />
            </Row>
          </Container>
        </div>
        */}
      </React.Fragment>
    );
  }
}

export default Dashboard;
