import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import formatPhone from "../../utils/formatPhone";

const TOOLTIPTEXT_DEFAULT_EMAIL = "Enviar email";
const TOOLTIPTEXT_DEFAULT_WHATSAPP = "Enviar whatsapp";
const TOOLTIPTEXT_DEFAULT_NOTIFICATION = "Enviar notificação";

const EmailButton = (props) => {
  return (
    <>
      <Link
        to={`/`}
        onClick={(e) => {
          e.preventDefault();
          window.open(`mailto:${props.email}`);
        }}
        className="mr-1 text-dark"
      >
        <i
          className="bx bx-mail-send font-size-18 align-middle mr-1"
          id="emailTooltip"
        ></i>

        {props.tooltip ? (
          <>
            <UncontrolledTooltip placement="top" target="emailTooltip">
              {props.tooltiptext
                ? props.tooltiptext
                : TOOLTIPTEXT_DEFAULT_EMAIL}
            </UncontrolledTooltip>
          </>
        ) : null}
      </Link>
      {props.visibleText ? <>{props.email}</> : null}
    </>
  );
};

const WhatsAppButton = (props) => {
  return (
    <>
      <Link
        to={`/`}
        onClick={(e) => {
          e.preventDefault();
          window.open(
            `https://api.whatsapp.com/send/?phone=55${props.fone}&text&app_absent=0`,
            "_blank"
          );
        }}
        className="mr-1 text-success"
      >
        <i
          className="mdi mdi-whatsapp font-size-18 align-middle mr-1"
          id="whatsAppTooltip"
        ></i>
        {props.tooltip ? (
          <>
            <UncontrolledTooltip placement="top" target="whatsAppTooltip">
              {props.tooltiptext
                ? props.tooltiptext
                : TOOLTIPTEXT_DEFAULT_WHATSAPP}
            </UncontrolledTooltip>
          </>
        ) : null}
      </Link>
      {props.visibleText ? (
        <>{props.formated ? formatPhone(props.fone) : props.fone}</>
      ) : null}
    </>
  );
};

const NotificationButton = (props) => {
  return (
    <>
      <Link
        to={`/notificacao/envio-manual?id=${props.id}`}
        className="mr-1 text-secondary"
      >
        <i
          className="bx bx-message font-size-18 align-middle mr-1"
          id="notificationTooltip"
        ></i>
        {props.tooltip ? (
          <>
            <UncontrolledTooltip placement="top" target="notificationTooltip">
              {props.tooltiptext
                ? props.tooltiptext
                : TOOLTIPTEXT_DEFAULT_NOTIFICATION}
            </UncontrolledTooltip>
          </>
        ) : null}
      </Link>
    </>
  );
};

export { EmailButton, WhatsAppButton, NotificationButton };
