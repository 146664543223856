const columnsAtendimento = (atendimentos) => {
  return {
    columns: [
      {
        label: "#",
        field: "horarioAtendimentoEmpresaId",
      },
      {
        label: "Atendimento",
        field: "atendimento",
        sort: "asc",
      },
      {
        label: "Horário",
        field: "horario",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: atendimentos,
  };
};

export default columnsAtendimento;
