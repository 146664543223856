import React, { useEffect, useState, useCallback, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { format, isAfter, parseISO } from "date-fns";
import {
  Modal,
  Row,
  Col,
  CardTitle,
  Card,
  CardBody,
  Media,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import api from "../../../services/api";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import formatValue from "../../../utils/formatValue";
import formatCpf from "../../../utils/formatCpf";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import DataTableDefault from "../../../components/DataTable/DataTableDefault";
import { columnsVenda, columnsItemVenda } from "./datatableColumns";
import "../../../shared/datatables.scss";

const VendaHistorico = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();
  const [hoje, setHoje] = useState("");
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);
  const [vendas, setVendas] = useState([]);
  const [itensVenda, setItensVenda] = useState([]);
  const [valorTotal, setValorTotal] = useState("R$ 0,00");
  const [cashbackTotal, setCashbackTotal] = useState("R$ 0,00");
  const [ticketMedio, setTicketMedio] = useState("R$ 0,00");
  const [cashbackMedio, setCashbackMedio] = useState("R$ 0,00");

  const [modalItemVenda, setModalItemVenda] = useState(false);

  const dataInicial = useRef(null);
  const dataFinal = useRef(null);

  const optionCliente = [
    {
      options: clientes,
    },
  ];

  useEffect(() => {
    async function getClientes() {
      try {
        setAguarde(true);

        setHoje(format(new Date(), "yyyy-MM-dd"));

        const response = await api.get("/web/cliente");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const listaCliente = response.data.data.map((cliente) => {
          return {
            label: cliente.nome,
            value: cliente.clienteId,
          };
        });

        setClientes([
          { label: "Todos", value: "-1" },
          { label: "Venda sem Cliente", value: "0" },
          ...listaCliente,
        ]);
      } catch (err) {
        setClientes([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getClientes();
  }, [setAguarde, setMessageBox]);

  const abrirModalItensVenda = useCallback(
    async (vendaId) => {
      try {
        setAguarde(true);
        const response = await api.get(
          `web/historicovenda/${vendaId}/detalhes`
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const listaItemVenda = response.data.data.itens.map((venda) => {
          return {
            ...venda,
            valorUnitario: formatValue(venda.valorUnitario, "currency"),
            valorCreditoResgatado: formatValue(
              venda.valorCreditoResgatado,
              "currency"
            ),
            valorTotal: formatValue(venda.valorTotal, "currency"),
            valorPago: formatValue(
              venda.valorTotal - venda.valorCreditoResgatado,
              "currency"
            ),
            aliquotaCashback: formatValue(venda.aliquotaCashback, "percent"),
            valorCashback: formatValue(venda.valorCashback, "currency"),
          };
        });

        setItensVenda(listaItemVenda);

        setModalItemVenda(true);
      } catch (err) {
        setItensVenda([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [setAguarde, setMessageBox]
  );

  const consultarHistorico = useCallback(async () => {
    try {
      if (dataInicial.current.value === "") {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: "Data Inicial não informada",
          error: true,
        });
        return;
      }

      if (dataFinal.current.value === "") {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: "Data Final não informada",
          error: true,
        });
      }

      if (
        isAfter(
          parseISO(dataInicial.current.value),
          parseISO(dataFinal.current.value)
        )
      ) {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: "Data inicial deve ser menor ou igual a data final",
          error: true,
        });
        return;
      }

      setAguarde(true);

      let response;
      if (clienteSelecionado.value >= 0) {
        response = await api.get(
          `/web/historicovenda/de/${dataInicial.current.value}/ate/${dataFinal.current.value}/cliente/${clienteSelecionado.value}`
        );
      } else {
        response = await api.get(
          `/web/historicovenda/de/${dataInicial.current.value}/ate/${dataFinal.current.value}`
        );
      }

      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const listaVenda = response.data.data.vendasPeriodo.map((venda) => {
        return {
          ...venda,
          valorTotal: formatValue(venda.valorTotal, "currency"),
          valorCreditoResgatado: formatValue(
            venda.valorCreditoResgatado,
            "currency"
          ),
          valorPago: formatValue(
            venda.valorTotal - venda.valorCreditoResgatado,
            "currency"
          ),
          valorCashback: formatValue(venda.valorCashback, "currency"),
          clienteCpf: formatCpf(venda.clienteCpf),
          dataHora: format(parseISO(venda.dataHora), "dd/MM/yyyy hh:mm"),
          action: (
            <>
              <Link
                onClick={() => {
                  abrirModalItensVenda(venda.vendaId);
                }}
                to={() => {}}
                className="mr-1 text-info"
              >
                <i
                  className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                  id="exibirTooltip"
                ></i>
              </Link>
            </>
          ),
        };
      });
      setVendas(listaVenda);

      const {
        totalVenda,
        totalCashback,
        quantidade,
        quantidadeCashback,
      } = response.data.data.vendasPeriodo.reduce(
        (accumulator, venda) => {
          accumulator.totalVenda += venda.valorTotal;
          accumulator.totalCashback += venda.valorCashback;
          accumulator.quantidade += 1;
          if (venda.valorCashback > 0) {
            accumulator.quantidadeCashback += 1;
          }
          return accumulator;
        },
        {
          totalVenda: 0,
          totalCashback: 0,
          quantidade: 0,
          quantidadeCashback: 0,
        }
      );

      setValorTotal(formatValue(totalVenda, "currency"));
      setCashbackTotal(formatValue(totalCashback, "currency"));

      if (quantidade > 0) {
        setTicketMedio(formatValue(totalVenda / quantidade, "currency"));
      } else {
        setTicketMedio("R$ 0,00");
      }

      if (quantidadeCashback > 0) {
        setCashbackMedio(
          formatValue(totalCashback / quantidadeCashback, "currency")
        );
      } else {
        setCashbackMedio("R$ 0,00");
      }

      if (listaVenda.length === 0) {
        await setMessageBox({
          show: true,
          title: "Atenção",
          description: "Nenhum registro encontrado",
          warning: true,
        });
        return;
      }
    } catch (err) {
      setAguarde(false);
      setVendas([]);
      setValorTotal("R$ 0,00");
      setCashbackTotal("R$ 0,00");
      setTicketMedio("R$ 0,00");
      setCashbackMedio("R$ 0,00");

      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [
    abrirModalItensVenda,
    clienteSelecionado.value,
    setAguarde,
    setMessageBox,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Histórico de Vendas" breadcrumbItem="Venda" />

          <Modal
            size="xl"
            isOpen={modalItemVenda}
            toggle={() => setModalItemVenda(!modalItemVenda)}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Itens da Venda</h5>
              <button
                type="button"
                onClick={() => {
                  setModalItemVenda(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <MDBDataTable
                fixed
                hover
                striped
                bordered
                responsive
                responsiveSm
                responsiveMd
                responsiveLg
                responsiveXl
                small
                entriesLabel=""
                entries={50}
                entriesOptions={[10, 20, 50, 100]}
                infoLabel={["Mostrando", "até", "de", "registros"]}
                noRecordsFoundLabel="Nemhum registro encontrado"
                paginationLabel={["Anterior", "Próximo"]}
                searchLabel="Localizar"
                noBottomColumns={true}
                data={columnsItemVenda(itensVenda)}
              />
            </div>
          </Modal>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Data Inicial</Label>
                          <input
                            ref={dataInicial}
                            className="form-control"
                            type="date"
                            defaultValue={hoje}
                            id="dataInicial"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Data Final</Label>
                          <input
                            ref={dataFinal}
                            className="form-control"
                            type="date"
                            defaultValue={hoje}
                            id="dataFinal"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup>
                          <Label>Cliente</Label>
                          <Select
                            placeholder="Todos"
                            value={clienteSelecionado}
                            onChange={setClienteSelecionado}
                            options={optionCliente}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="text-sm-right">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded mr-1 waves-effect waves-light"
                        onClick={consultarHistorico}
                      >
                        Consultar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CardTitle>TOTAIS DO PERÍODO</CardTitle>
          <Row>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Valor Total
                      </p>
                      <h4 className="mb-0 text-primary">{valorTotal}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                      <span className="avatar-title">
                        <i className={"bx bx-coin-stack font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Ticket Médio
                      </p>
                      <h4 className="mb-0 text-primary">{ticketMedio}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                      <span className="avatar-title">
                        <i
                          className={"bx bx-purchase-tag-alt font-size-24"}
                        ></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Cashback Total
                      </p>
                      <h4 className="mb-0 text-danger">{cashbackTotal}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                      <span className="avatar-title bg-danger">
                        <i className={"bx bx-dollar font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Cashback Médio
                      </p>
                      <h4 className="mb-0 text-danger">{cashbackMedio}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                      <span className="avatar-title bg-danger">
                        <i
                          className={"bx bx-purchase-tag-alt font-size-24"}
                        ></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsVenda(vendas)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VendaHistorico;
