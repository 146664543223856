const columnsParticipantes = (participanteSorteado) => {
  return {
    columns: [
      {
        label: "#",
        field: "clienteId",
        sort: "disabled",
      },

      {
        label: "Cliente Participante",
        field: "nome",
        sort: "asc",
      },
      {
        label: "Quantidade de Cupons",
        field: "cupons",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: participanteSorteado,
  };
};

const columnsCupons = (cupomSorteado) => {
  return {
    columns: [
      {
        label: "Cupom",
        field: "cupom",
        sort: "asc",
      },
    ],
    rows: cupomSorteado,
  };
};

export { columnsParticipantes, columnsCupons };
