import React, { useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import IntlNumberInput from "react-intl-number-input";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../components/ActionButton";
import api from "../../../services/api";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const CategoriaIncluirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const inputAliquota = useRef(null);

  const [aliquotaCashback, setAliquotaCashback] = useState(0);

  const history = useHistory();

  const handleAliquotaCashback = useCallback(
    async (event, value, maskedValue) => {
      await event.preventDefault();
      setAliquotaCashback(parseFloat(value));
    },
    []
  );

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        setAguarde(true);
        const response = await api.post("/web/categoria/", {
          descricao: values.descricao,
          aliquotaCashback: inputAliquota.current.state.value,
        });
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        history.push("/cadastros/categoria/listar");
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [history, setAguarde, setMessageBox]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações de Categoria - Inclusão"
            breadcrumbItem="Categoria"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="descricao"
                            label="Descrição"
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Descrição não informada"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup>
                          <Label>Aliquota de Cashback (%)</Label>
                          <IntlNumberInput
                            ref={inputAliquota}
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(aliquotaCashback)}
                            onChange={handleAliquotaCashback}
                            className="form-control"
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategoriaIncluirPage;
