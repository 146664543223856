import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { VoltarButton } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const EmpresaRedeSocialAlterarPage = (props) => {
  const history = useHistory();

  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);

  const [redesSociais, setRedesSociais] = useState([]);
  const [redeSocialSelecionada, setRedeSocialSelecionada] = useState([]);

  const optionRedeSocial = [
    {
      options: redesSociais,
    },
  ];

  useEffect(() => {
    async function getRedeSocial() {
      try {
        const response = await api.get("/web/redesocial");
        if (response.data.success) {
          const listaRedeSocial = response.data.data.map((redeSocial) => {
            return {
              label: redeSocial.descricao,
              value: redeSocial.redeSocialId,
            };
          });

          setRedesSociais(listaRedeSocial);
        } else {
          setRedesSociais([]);
        }
      } catch (err) {
        setRedesSociais([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getRedeSocial();
  }, [setAguarde, setMessageBox]);

  useEffect(() => {
    async function getRedeSocialEmpresa() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        const response = await api.get(
          `/web/redesocialempresa/padrao/${values.id}`
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const redeSocialEmpresa = response.data.data;

        const auxRedeSocial = redesSociais.filter(
          (redeSocial) => redeSocial.value === redeSocialEmpresa.redeSocialId
        );

        if (auxRedeSocial[0]) {
          setRedeSocialSelecionada(auxRedeSocial[0]);
        }

        setRegistro(redeSocialEmpresa);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getRedeSocialEmpresa();
  }, [props.location.search, redesSociais, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Rede Social da Empresa - Visualização"
            breadcrumbItem="Rede Social da Empresa"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm className="form-horizontal">
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label>Rede Social</Label>
                          <Select
                            placeholder="Selecione uma rede social"
                            value={redeSocialSelecionada}
                            onChange={setRedeSocialSelecionada}
                            options={optionRedeSocial}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="conta"
                            label="Conta"
                            value={registro.conta}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <AvField
                            name="link"
                            label="Link"
                            value={registro.link}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmpresaRedeSocialAlterarPage;
