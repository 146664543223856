import React from "react";
import { MDBDataTable } from "mdbreact";
import { Card, CardBody } from "reactstrap";

import "./datatables.scss";

const DataTableDefault = (props) => {
  return (
    <Card>
      <CardBody>
        <MDBDataTable
          fixed
          hover
          striped
          bordered
          responsive
          responsiveSm
          responsiveMd
          responsiveLg
          responsiveXl
          small
          entriesLabel=""
          entries={50}
          entriesOptions={[10, 20, 50, 100]}
          infoLabel={["Mostrando", "até", "de", "registros"]}
          noRecordsFoundLabel="Nemhum registro encontrado"
          paginationLabel={["Anterior", "Próximo"]}
          searchLabel="Localizar"
          noBottomColumns={true}
          data={props.dataSource}
        />
      </CardBody>
    </Card>
  );
};

export default DataTableDefault;
