import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Container, Row, Col, FormGroup, Card, CardBody } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { VoltarButton } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const AnimalTipoAnimalExibirPage = (props) => {
  const history = useHistory();

  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);

  useEffect(() => {
    async function getTipoAtendimento() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);

        const response = await api.get(`/web/tipoanimal/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        setRegistro(response.data.data);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getTipoAtendimento();
  }, [props.location.search, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações de Tipo de Animal - Visualização"
            breadcrumbItem="Tipo Animal"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm className="form-horizontal">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <AvField
                            name="descricao"
                            label="Descrição"
                            value={registro.descricao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AnimalTipoAnimalExibirPage;
