import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container, Form, FormGroup, Label, Button, Row, Col, Card, CardTitle, CardBody, Media } from "reactstrap";
import { format } from 'date-fns';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import formatValue from '../../../utils/formatValue';

import Select from "react-select";

import api from "../../../services/api";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const CashbackExtrato = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);
  const [meses, setMeses] = useState([]);
  const [ano, setAno] = useState([]);
  const [mesSelecionado, setMesSelecionado] = useState([]);

  const [totalEntrada, setTotalEntrada] = useState('R$ 0,00');
  const [totalSaida, setTotalSaida] = useState('R$ 0,00');
  const [entradaMedia, setEntradaMedia] = useState('R$ 0,00');
  const [saidaMedia, setSaidaMedia] = useState('R$ 0,00');

  const inputAno = useRef(null);

  useEffect(() => {
    async function getCliente() {
      try {
        setAguarde(true);

        setAno(format(new Date(), 'yyyy'))
    
        const response = await api.get('/web/cliente');
        setAguarde(false);
        
        if (!response.data.success) {
          throw response.data;
        }

        const listaCliente = response.data.data.map(cliente => {
          return {
            label: cliente.nome,
            value: cliente.clienteId,
          }
        });

        setClientes(listaCliente);

        setMeses([
          {label: 'Janeiro', value: 1},
          {label: 'Fevereiro', value: 2},
          {label: 'Março', value: 3},
          {label: 'Abril', value: 4},
          {label: 'Maio', value: 5},
          {label: 'Junho', value: 6},
          {label: 'Julho', value: 7},
          {label: 'Agosto', value: 8},
          {label: 'Setembro', value: 9},
          {label: 'Outubro', value: 10},
          {label: 'Novembro', value: 11},
          {label: 'Dezembro', value: 12},
        ]);
      }
       catch (err) {
        setClientes([]);
        setMeses([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCliente();
  }, [setAguarde, setMessageBox]);

  useEffect(() => {
    const mesAtual = format(new Date(), 'MM')

    const auxMes = meses.filter(
      mes => mes.value === parseInt(mesAtual)
    );
    setMesSelecionado(auxMes[0]);
  }, [meses]);

  const optionMes = [
    {
      options: meses
    },
  ];

  const optionCliente = [
    {
      options: clientes
    },
  ];

  const consultarExtrato = useCallback(async () => {
    try {
      if (mesSelecionado.value === undefined) {
        return;
      }

      if (clienteSelecionado.value === undefined) {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: 'Cliente não informado',
          error: true,
        });

        return;
      }

      if (inputAno.current.value === '') {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: 'Ano não informado',
          error: true,
        });

        return;
      }

      if (inputAno.current.value < 2020) {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: 'Ano inválido',
          error: true,
        });

        return;
      }
      
      let mesAno;
      if (mesSelecionado.value < 10) {
        mesAno = '0' + mesSelecionado.value + inputAno.current.value;
      } else {
        mesAno = mesSelecionado.value + '' + inputAno.current.value;
      }

      setAguarde(true);
      const response = await api.get(`/web/extratocashback/mesAno/${mesAno}/cliente/${clienteSelecionado.value}`);
      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const listaExtrato = response.data.data.extratoMes.map((extrato) => {
        return {
          id: extrato.id,
          dia: format(new Date(extrato.dia), 'dd'),
          valor: formatValue(extrato.valor, 'currency'),
          validade : extrato.validade ? (
            ` (válido até ${format(new Date(extrato.validade), 'dd/MM/yyyy')})`
          ) : (
            ''
          ),
          natureza: extrato.natureza === 'E' ? (
            'bx bx-plus-circle h2 text-primary'
          ) : (
            'bx bx-minus-circle h2 text-danger'
          ),
          cor: extrato.natureza === 'E' ? (
            'text-primary'
          ) : (
            'text-danger'
          ),
          descricao: extrato.descricao,
        };
      });

      setRegistro(listaExtrato);

      const { totalEntrada, totalSaida, quantidadeEntrada, quantidadeSaida } = response.data.data.extratoMes.reduce(
        (accumulator, extrato) => { 
          if (extrato.natureza === 'E') {
            accumulator.totalEntrada += extrato.valor;
            accumulator.quantidadeEntrada += 1;
          } else {
            accumulator.totalSaida += extrato.valor;
            accumulator.quantidadeSaida += 1;
          }
          return accumulator;
        },
        {
          totalEntrada: 0,
          totalSaida: 0,
          quantidadeEntrada: 0,
          quantidadeSaida: 0,
        },
      );

      setTotalEntrada(formatValue(totalEntrada, 'currency'));
      setTotalSaida(formatValue(totalSaida, 'currency'));

      if (quantidadeEntrada > 0) {
        setEntradaMedia(formatValue(totalEntrada / quantidadeEntrada, 'currency'));
      }
      else {
        setEntradaMedia('R$ 0,00');
      }

      if (quantidadeSaida > 0) {
        setSaidaMedia(formatValue(totalSaida / quantidadeSaida, 'currency'));
      }
      else {
        setSaidaMedia('R$ 0,00');
      }
    } catch (err) {
      setAguarde(false);

      setRegistro([]);
      setTotalEntrada('R$ 0,00');
      setTotalSaida('R$ 0,00');
      setEntradaMedia('R$ 0,00');
      setSaidaMedia('R$ 0,00');
      
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [mesSelecionado, clienteSelecionado, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Extrato de Cashback" breadcrumbItem="Cashback" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <Form className="form-horizontal">
                  <Row>
                      <Col sm="3">
                        <FormGroup>
													<Label>Mês</Label>
                          <Select
                            placeholder="Todos"
														value={mesSelecionado}
														onChange={setMesSelecionado}
														options={optionMes}
														classNamePrefix="select2-selection"
													/>
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
													<Label>Ano</Label>
                          <input 
                            ref={inputAno}
                            className="form-control" 
                            type="text" 
                            defaultValue={ano}
                            maxLength="4"
                            id="ano" />
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup>
													<Label>Cliente</Label>
                          <Select
                            placeholder="Selecione um Cliente"
														value={clienteSelecionado}
														onChange={setClienteSelecionado}
														options={optionCliente}
														classNamePrefix="select2-selection"
													/>
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    <div className="text-sm-right">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded mr-1 waves-effect waves-light"
                        onClick={consultarExtrato}
                      >
                        Consultar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <CardTitle>TOTALIZADORES</CardTitle>
          <Row>
            <Col md="3">
              <Card className="mini-stats-wid">
                  <CardBody>
                      <Media>
                          <Media body>
                              <p className="text-muted font-weight-medium">Total Cashback Recebido</p>
                              <h4 className="mb-0 text-primary">{totalEntrada}</h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                              <span className="avatar-title">
                                  <i className={"bx bx-dollar font-size-24"}></i>
                              </span>
                          </div>
                      </Media>
                  </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                  <CardBody>
                      <Media>
                          <Media body>
                              <p className="text-muted font-weight-medium">Total Cashback Utilizado</p>
                              <h4 className="mb-0 text-danger">{totalSaida}</h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                              <span className="avatar-title bg-danger">
                                  <i className={"bx bx-dollar font-size-24"}></i>
                              </span>
                          </div>
                      </Media>
                  </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                  <CardBody>
                      <Media>
                          <Media body>
                              <p className="text-muted font-weight-medium">Média de Cashback Recebido</p>
                              <h4 className="mb-0 text-primary">{entradaMedia}</h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                              <span className="avatar-title">
                                  <i className={"bx bx-dollar font-size-24"}></i>
                              </span>
                          </div>
                      </Media>
                  </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                  <CardBody>
                      <Media>
                          <Media body>
                              <p className="text-muted font-weight-medium">Média de Cashback Utilizado</p>
                              <h4 className="mb-0 text-danger">{saidaMedia}</h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                              <span className="avatar-title bg-danger">
                                  <i className={"bx bx-dollar font-size-24"}></i>
                              </span>
                          </div>
                      </Media>
                  </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="">
                    <ul className="verti-timeline list-unstyled">
                      {/* Render Horizontal Timeline Events */}
                      {registro.map((extrato, key) => (
                        <li key={key} className="event-list">
                          <div className="event-timeline-dot">
                            {extrato.dia}
                          </div>
                          <div className="media">
                            <div className="mr-1">
                              <i className={extrato.natureza}></i>
                            </div>
                            <div className="media-body">
                              <div>
                                <h5 className={extrato.cor}>{extrato.valor}{extrato.validade}</h5>
                                <p className="text-muted">
                                  {extrato.descricao}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CashbackExtrato;
