import React, { useEffect, useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../../../services/api";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import columnsAtendimento from "./datatableColumns";

const EmpresaAtendimentoListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [atendimentos, setAtendimentos] = useState([]);

  const excluirAtendimento = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(
        `/web/horarioatendimentoempresa/padrao/${atendimentoSelecionado}`
      );
      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const auxAtendimentos = atendimentos.filter(
        (atendimento) =>
          atendimento.horarioAtendimentoEmpresaId !== atendimentoSelecionado
      );

      setAtendimentos(auxAtendimentos);

      await setMessageBox({
        show: true,
        title: "Sucesso",
        description: "Horário de Atendimento excluído com sucesso.",
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [atendimentoSelecionado, atendimentos, setAguarde, setMessageBox]);

  useEffect(() => {
    async function getAtendimentoEmpresa() {
      try {
        setAguarde(true);
        const response = await api.get("/web/horarioatendimentoempresa/padrao");
        setAguarde(false);
        if (response.data.success) {
          const listaAtendimento = response.data.data.map((atendimento) => {
            return {
              ...atendimento,
              action: (
                <>
                  <Link
                    to={`/cadastros/empresa/atendimento/exibir?id=${atendimento.horarioAtendimentoEmpresaId}`}
                    className="mr-1 text-info"
                  >
                    <i
                      className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                      id="exibirTooltip"
                    ></i>
                  </Link>

                  <Link
                    to={`/cadastros/empresa/atendimento/alterar?id=${atendimento.horarioAtendimentoEmpresaId}`}
                    className="mr-1 text-warning"
                  >
                    <i
                      className="bx bx-edit-alt font-size-18 align-middle mr-1"
                      id="alterarTooltip"
                    ></i>
                  </Link>

                  <Link
                    onClick={() => {
                      setAtendimentoSelecionado(
                        atendimento.horarioAtendimentoEmpresaId
                      );
                      setModalExcluir(true);
                    }}
                    to={() => {}}
                    className="mr-1 text-danger"
                  >
                    <i
                      className="bx bx-trash font-size-18 align-middle mr-1"
                      id="excluirTooltip"
                    ></i>
                  </Link>
                </>
              ),
            };
          });
          setAtendimentos(listaAtendimento);
        } else {
          setAtendimentos([]);
        }
      } catch (err) {
        setAtendimentos([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getAtendimentoEmpresa();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Lista de Horários de Atendimento"
            breadcrumbItem="Horário de Atendimento"
          />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir este horário de atendimento?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirAtendimento}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/empresa/atendimento/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsAtendimento(atendimentos)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmpresaAtendimentoListarPage;
