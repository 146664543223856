export default function formatPhone(phone) {
  if (phone.length === 10) {
    return `(${phone.substring(0, 2)})${phone.substring(
      2,
      6,
    )}-${phone.substring(6, 10)}`;
  }

  if (phone.length === 11) {
    return `(${phone.substring(0, 2)})${phone.substring(
      2,
      7,
    )}-${phone.substring(7, 11)}`;
  }

  return phone;
}
