import React, { useEffect, useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../../../services/api";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import columnsTipoAtendimento from "./datatableColumns";

const EmpresaTipoAtendimentoListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [tipoAtendimentoSelecionado, setTipoAtendimentoSelecionado] = useState(
    ""
  );
  const [modalExcluir, setModalExcluir] = useState(false);
  const [tipoAtendimentos, setTipoAtendimentos] = useState([]);

  const excluirTipoAtendimento = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(
        `/web/tipoatendimento/${tipoAtendimentoSelecionado}`
      );

      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const auxTipoAtendimentos = tipoAtendimentos.filter(
        (tipoAtendimento) =>
          tipoAtendimento.tipoAtendimentoId !== tipoAtendimentoSelecionado
      );

      setTipoAtendimentos(auxTipoAtendimentos);

      await setMessageBox({
        show: true,
        title: "Sucesso",
        description: "Tipo de Atendimento excluído com sucesso.",
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [setAguarde, setMessageBox, tipoAtendimentoSelecionado, tipoAtendimentos]);

  useEffect(() => {
    async function getTipoAtendimento() {
      try {
        setAguarde(true);
        const response = await api.get("/web/tipoatendimento");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const listaTipoAtendimento = response.data.data.map(
          (tipoAtendimento) => {
            return {
              ...tipoAtendimento,
              action: (
                <>
                  <Link
                    to={`/cadastros/empresa/tipoatendimento/exibir?id=${tipoAtendimento.tipoAtendimentoId}`}
                    className="mr-1 text-info"
                  >
                    <i
                      className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                      id="exibirTooltip"
                    ></i>
                  </Link>

                  <Link
                    to={`/cadastros/empresa/tipoatendimento/alterar?id=${tipoAtendimento.tipoAtendimentoId}`}
                    className="mr-1 text-warning"
                  >
                    <i
                      className="bx bx-edit-alt font-size-18 align-middle mr-1"
                      id="alterarTooltip"
                    ></i>
                  </Link>

                  <Link
                    onClick={() => {
                      setTipoAtendimentoSelecionado(
                        tipoAtendimento.tipoAtendimentoId
                      );
                      setModalExcluir(true);
                    }}
                    to={() => {}}
                    className="mr-1 text-danger"
                  >
                    <i
                      className="bx bx-trash font-size-18 align-middle mr-1"
                      id="excluirTooltip"
                    ></i>
                  </Link>
                </>
              ),
            };
          }
        );
        setTipoAtendimentos(listaTipoAtendimento);
      } catch (err) {
        setTipoAtendimentos([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getTipoAtendimento();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Lista de Tipo de Atendimento" breadcrumbItem="Tipo de Atendimento" />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir este tipo de atendimento?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirTipoAtendimento}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/empresa/tipoatendimento/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <DataTableDefault
                dataSource={columnsTipoAtendimento(tipoAtendimentos)}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmpresaTipoAtendimentoListarPage;
