import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useGlobal } from "../../contexts/globalContext";

const Footer = (props) => {
  const { global } = useGlobal();
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              {new Date().getFullYear()} © {global.empresa}.
            </Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                Desenvolvido por {global.fabricante}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
