const columnsServico = (servicos) => {
  return {
    columns: [
      {
        label: "#",
        field: "servicoId",
        sort: "disabled",
      },

      {
        label: "Serviço",
        field: "descricao",
        sort: "asc",
      },
      {
        label: "Valor",
        field: "valor",
        sort: "disabled",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: servicos,
  };
};

export default columnsServico;
