import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import IntlNumberInput from "react-intl-number-input";
import Select from "react-select";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { VoltarButton } from "../../../components/ActionButton";
import api from "../../../services/api";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import enumAgendamentoServico from "../../../helpers/enums/enumAgendamentoServico";

const ServicoExibirPage = (props) => {
  const history = useHistory();

  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);

  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState([]);

  const [status, setStatus] = useState([]);
  const [statusSelecionado, setStatusSelecionado] = useState([]);

  const [permitirVenda, setPermitirVenda] = useState([]);
  const [permitirVendaSelecionado, setPermitirVendaSelecionado] = useState([]);

  const [cores, setCores] = useState([]);
  const [corSelecionada, setCorSelecionada] = useState([]);

  const [coresClassName, setCoresClassName] = useState([]);
  const [corClassNameSelecionada, setCorClassNameSelecionada] = useState("");

  const [agendamento, setAgendamento] = useState([]);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState([]);

  const optionCategoria = [
    {
      options: categorias,
    },
  ];

  const optionCor = [
    {
      options: cores,
    },
  ];

  const optionPermitirVenda = [
    {
      options: permitirVenda,
    },
  ];

  const optionStatus = [
    {
      options: status,
    },
  ];

  const optionAgendamento = [
    {
      options: agendamento,
    },
  ];

  const handleChangeCor = useCallback(
    async (cor) => {
      setCorSelecionada(cor);
      const auxCorClassName = coresClassName.filter(
        (corClassName) => corClassName.corId === parseInt(cor.value)
      );
      setCorClassNameSelecionada(auxCorClassName[0].className);
    },
    [coresClassName]
  );

  useEffect(() => {
    async function getCategoria() {
      try {
        const responseCategorias = await api.get("/web/categoria");
        if (!responseCategorias.data.success) {
          throw responseCategorias.data;
        }

        const reponseCores = await api.get("/web/configuracao/view/cores");
        if (!reponseCores.data.success) {
          throw reponseCores.data;
        }

        const listaCategoria = responseCategorias.data.data.map(
          (categorias) => {
            return {
              label: categorias.descricao,
              value: categorias.categoriaId,
            };
          }
        );
        setCategorias(listaCategoria);

        const listaCores = reponseCores.data.data.map((cores) => {
          return {
            label: cores.descricao,
            value: cores.corId,
          };
        });
        setCores(listaCores);

        setCoresClassName(reponseCores.data.data);

        setStatus([
          { label: "Ativo", value: 1 },
          { label: "Inativo", value: 2 },
        ]);

        setPermitirVenda([
          { label: "Sim", value: 1 },
          { label: "Não", value: 0 },
        ]);

        setAgendamento([
          {
            label: "Não Permitido",
            value: enumAgendamentoServico.NaoPermitido,
          },
          {
            label: "Permitido para Todos",
            value: enumAgendamentoServico.PermitidoParaTodos,
          },
          {
            label: "Não Permitido para Clientes",
            value: enumAgendamentoServico.NaoPermitidoParaClientes,
          },
        ]);
      } catch (err) {
        setStatus([]);
        setPermitirVenda([]);
        setCategorias([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCategoria();
  }, [setAguarde, setMessageBox]);

  useEffect(() => {
    async function getServico() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        const responseServico = await api.get(`/web/servico/${values.id}`);
        setAguarde(false);

        if (!responseServico.data.success) {
          throw responseServico.data;
        }
        const servico = responseServico.data.data;

        Object.assign(servico, {
          dataCadastro: format(
            parseISO(servico.dataCadastro),
            "dd/MM/yyyy hh:mm"
          ),
          dataAlteracao: format(
            parseISO(servico.dataAlteracao),
            "dd/MM/yyyy hh:mm"
          ),
        });

        const auxCategoria = categorias.filter(
          (categoria) => categoria.value === servico.categoriaId
        );
        setCategoriaSelecionada(auxCategoria[0]);

        const auxCores = cores.filter((cor) => cor.value === servico.corId);
        setCorSelecionada(auxCores[0]);

        const auxCorClassName = coresClassName.filter(
          (corClassName) => corClassName.corId === parseInt(servico.corId)
        );
        if (auxCorClassName.length > 0) {
          setCorClassNameSelecionada(auxCorClassName[0].className);
        }

        const auxStatus = status.filter(
          (status) => status.value === servico.status
        );
        setStatusSelecionado(auxStatus[0]);

        const auxPermitirVenda = permitirVenda.filter(
          (permitirVenda) => permitirVenda.value === servico.permitirVenda
        );
        setPermitirVendaSelecionado(auxPermitirVenda[0]);

        const auxAgendamento = agendamento.filter(
          (agendamento) => agendamento.value === servico.agendamento
        );
        setAgendamentoSelecionado(auxAgendamento[0]);

        setRegistro(servico);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getServico();
  }, [
    setAguarde,
    setMessageBox,
    optionStatus.options,
    optionPermitirVenda.options,
    optionAgendamento.options,
    optionCor.options,
    props.location.search,
    categorias,
    agendamento,
    status,
    permitirVenda,
    coresClassName,
    cores,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações do Serviço - Visualização"
            breadcrumbItem="Serviço"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm className="form-horizontal">
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="descricao"
                            label="Descrição"
                            value={registro.descricao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Aliquota de Cashback (%)</Label>
                          <IntlNumberInput
                            locale="pt-BR"
                            precision={2}
                            value={registro.aliquotaCashback}
                            className="form-control"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Permitir Venda</Label>
                          <Select
                            placeholder="Selecione uma opção"
                            value={permitirVendaSelecionado}
                            onChange={setPermitirVendaSelecionado}
                            options={optionPermitirVenda}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Agendamento</Label>
                          <Select
                            placeholder="Selecione uma regra"
                            value={agendamentoSelecionado}
                            onChange={setAgendamentoSelecionado}
                            options={optionAgendamento}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Cor de Identificação</Label>
                          <Select
                            placeholder="Selecione uma cor"
                            value={corSelecionada}
                            onChange={handleChangeCor}
                            options={optionCor}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Exemplo da Cor </Label>

                          <div className="text-center">
                            <span
                              className={`badge ${corClassNameSelecionada} font-size-20`}
                            >
                              Compromisso
                            </span>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Status</Label>
                          <Select
                            placeholder="Selecione um Status"
                            value={statusSelecionado}
                            onChange={setStatusSelecionado}
                            options={optionStatus}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label>Categoria</Label>
                          <Select
                            placeholder="Selecione uma categoria"
                            value={categoriaSelecionada}
                            onChange={setCategoriaSelecionada}
                            options={optionCategoria}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data do Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data da Última Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServicoExibirPage;
