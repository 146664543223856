import React from "react";
import { verify } from "jsonwebtoken";
import { Route, Redirect, withRouter } from "react-router-dom";

const Authmiddleware = ({ component: Component, layout: Layout }) => (
  <Route
    render={(props) => {
      let deslogar = false;
      const authUser = localStorage.getItem("@Cashback:authUser");

      if (!authUser) {
        deslogar = true;
      } else {
        try {
          const [, token] = JSON.parse(authUser).accessToken.split(" ");

          verify(token, process.env.REACT_APP_SECRET);
        } catch (err) {
          deslogar = true;
        }
      }

      // here you can apply condition
      //if (!localStorage.getItem("@Cashback:authUser")) {
      if (deslogar) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default withRouter(Authmiddleware);
