const columnsFaixa = (faixas) => {
  return {
    columns: [
      {
        label: "#",
        field: "faixaValorCashbackId",
        sort: "asc",
      },
      {
        label: "Valor Inicial",
        field: "valorInicial",
      },
      {
        label: "Valor Final",
        field: "valorFinal",
      },
      {
        label: "Cashback",
        field: "aliquotaCashback",
      },
      {
        label: "Data da Última Alteração",
        field: "dataAlteracao",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: faixas,
  };
};

export default columnsFaixa;
