import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const EspecializacaoIncluirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
          setAguarde(true);
          const response = await api.post("/web/especializacao", {
            descricao: values.descricao,
          });

          setAguarde(false);

          if (!response.data.success) {
            throw response.data;
          }

          history.push("/cadastros/profissional/especializacao/listar");
        
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [history, setAguarde, setMessageBox]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações da Especialização - Inclusão" breadcrumbItem="Especialização" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <AvField
                            name="descricao"
                            label="Descrição"
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Descrição não informada"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EspecializacaoIncluirPage;
