const columnsRacaAnimal = (racaAnimal) => {
  return {
    columns: [
      {
        label: "#",
        field: "racaAnimalId",
        sort: "asc",
      },
      {
        label: "Descrição",
        field: "descricao",
        sort: "asc",
      },
      {
        label: "Tipo",
        field: "tipoAnimalDescricao",
        sort: "asc",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: racaAnimal,
  };
};

export default columnsRacaAnimal;
