import React from "react";
import { Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const Aguarde = ({ visible }) => {
  return (
    <SweetAlert
      title=""
      showCancel={false}
      showConfirm={false}
      show={visible}
      onConfirm={() => {}}
    >
      <Spinner
        className="mr-2"
        color={
          process.env.REACT_APP_ENVIRONMENT === "production"
            ? "success"
            : "secondary"
        }
      />
      <br />
      <br />
      <b>Aguarde...</b>
    </SweetAlert>
  );
};

export default Aguarde;
