import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const AnimalRacaIncluirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();
  const history = useHistory();

  const [tiposAnimal, setTiposAnimal] = useState([]);
  const [tipoAnimalSelecionado, setTipoAnimalSelecionado] = useState([]);

  const optionTipoAnimal = [
    {
      options: tiposAnimal,
    },
  ];

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        if (tipoAnimalSelecionado.value) {
          setAguarde(true);
          const response = await api.post("/web/racaanimal", {
            descricao: values.descricao,
            tipoAnimalId: tipoAnimalSelecionado.value,
          });

          setAguarde(false);

          if (!response.data.success) {
            throw response.data;
          }

          history.push("/cadastros/animal/raca/listar");
        }
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [history, setAguarde, setMessageBox, tipoAnimalSelecionado.value]
  );

  useEffect(() => {
    async function getTipoAnimal() {
      try {
        const response = await api.get("/web/tipoanimal");

        if (!response.data.success) {
          throw response.data;
        }

        const listaTipoAnimal = response.data.data.map((tipoAnimal) => {
          return {
            label: tipoAnimal.descricao,
            value: tipoAnimal.tipoAnimalId,
          };
        });

        setTiposAnimal(listaTipoAnimal);
      } catch (err) {
        setTiposAnimal([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getTipoAnimal();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações de Raça - Inclusão" breadcrumbItem="Raça" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label>Tipo de Animal</Label>
                          <Select
                            placeholder="Selecione um tipo de animal"
                            value={tipoAnimalSelecionado}
                            onChange={setTipoAnimalSelecionado}
                            options={optionTipoAnimal}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="descricao"
                            label="Descrição"
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Descrição não informada"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AnimalRacaIncluirPage;
