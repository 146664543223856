import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { RESET_PASSWORD } from "./actionTypes";
import { userResetPasswordError } from "./actions";

//Include Both Helper File with needed methods
//import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeResetPwd,
  //postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper";

//const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* resetUser({ payload: { user, history } }) {
  try {
    /*
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email);
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        );
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
        email: user.email,
      });
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        );
      }
    } else { */
    const response = yield call(postFakeResetPwd, "/resetar-senha", {
      modulo: 1,
      senha: user.senha,
      tokenSenha: parseInt(user.token),
      email: user.email,
    });
    if (response) {
      localStorage.setItem("@Cashback:authUser", JSON.stringify(response));
      //yield put(loginSuccess(response));

      history.push("/painel");
    }
    //}
  } catch (error) {
    yield put(userResetPasswordError(error));
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetUser);
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)]);
}

export default resetPasswordSaga;
