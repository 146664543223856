import React, { useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardBody,
} from "reactstrap";

import { AvForm } from "availity-reactstrap-validation-safe";
import IntlNumberInput from "react-intl-number-input";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../components/ActionButton";
import api from "../../../services/api";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const FaixaRetornoIncluirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const history = useHistory();

  const [valorInicial, setValorInicial] = useState(0);
  const [valorFinal, setValorFinal] = useState(0);
  const [aliquotaCashback, setAliquotaCashback] = useState(0);

  const inputValorInicial = useRef(null);
  const inputValorFinal = useRef(null);
  const inputAliquotaCashback = useRef(null);

  const handleValorInicial = useCallback(async (event, value, maskedValue) => {
    await event.preventDefault();
    setValorInicial(parseFloat(value));
  }, []);

  const handleValorFinal = useCallback(async (event, value, maskedValue) => {
    await event.preventDefault();
    setValorFinal(parseFloat(value));
  }, []);

  const handleAliquotaCashback = useCallback(
    async (event, value, maskedValue) => {
      await event.preventDefault();
      setAliquotaCashback(parseFloat(value));
    },
    []
  );

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        setAguarde(true);
        const response = await api.post("/web/faixavalorcashback/", {
          valorInicial: inputValorInicial.current.state.value,
          valorFinal: inputValorFinal.current.state.value,
          aliquotaCashback: inputAliquotaCashback.current.state.value,
        });

        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        history.push("/cadastros/faixaretorno/listar");
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [history, setAguarde, setMessageBox]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações de Faixa - Inclusão" breadcrumbItem="Faixa" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Valor Inicial (R$)</Label>
                          <IntlNumberInput
                            ref={inputValorInicial}
                            prefix="R$ "
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(valorInicial)}
                            onChange={handleValorInicial}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Valor Final (R$)</Label>
                          <IntlNumberInput
                            ref={inputValorFinal}
                            prefix="R$ "
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(valorFinal)}
                            onChange={handleValorFinal}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="4">
                        <FormGroup>
                          <Label>Aliquota de Cashback (%)</Label>
                          <IntlNumberInput
                            ref={inputAliquotaCashback}
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(aliquotaCashback)}
                            onChange={handleAliquotaCashback}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FaixaRetornoIncluirPage;
