import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Container, Row, Col, FormGroup, Card, CardBody } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const EmpresaTipoAtendimentoAlterarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const history = useHistory();

  const [registro, setRegistro] = useState([]);
  const [tipoAtendimento, setTipoAtendimento] = useState("");

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        setAguarde(true);
        const response = await api.patch(
          `/web/tipoatendimento/${tipoAtendimento}`,
          {
            descricao: values.descricao,
          }
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        history.push("/cadastros/empresa/tipoatendimento/listar");
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [history, setAguarde, setMessageBox, tipoAtendimento]
  );

  useEffect(() => {
    async function getTipoAtendimento() {
      try {
        const values = queryString.parse(props.location.search);
        setTipoAtendimento(values.id);
        setAguarde(true);

        const response = await api.get(`/web/tipoatendimento/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        setRegistro(response.data.data);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getTipoAtendimento();
  }, [props.location.search, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Tipo de Atendimento da Empresa - Edição"
            breadcrumbItem="Empresa"
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <AvField
                            name="descricao"
                            label="Descrição"
                            value={registro.descricao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Descrição não informada"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmpresaTipoAtendimentoAlterarPage;
