import React, { useEffect, useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import api from "../../../../services/api";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import columnsRacaAnimal from "./datatableColumns";

const AnimalRacaListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [racaSelecionada, setRacaSelecionada] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [racaAnimal, setRacaAnimal] = useState([]);

  const excluirRaca = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(`/web/racaanimal/${racaSelecionada}`);
      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const auxRacaAnimal = racaAnimal.filter(
        (racaAnimal) => racaAnimal.racaAnimalId !== racaSelecionada
      );

      setRacaAnimal(auxRacaAnimal);

      await setMessageBox({
        show: true,
        title: "Sucesso",
        description: "Raça excluída com sucesso.",
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [setAguarde, racaAnimal, setMessageBox, racaSelecionada]);

  useEffect(() => {
    async function getRacaAnimal() {
      try {
        setAguarde(true);
        const response = await api.get("/web/racaanimal");
        setAguarde(false);
        if (response.data.success) {
          const listaRacaAnimal = response.data.data.map((racaAnimal) => {
            return {
              ...racaAnimal,
              action: (
                <>
                  <Link
                    to={`/cadastros/animal/raca/exibir?id=${racaAnimal.racaAnimalId}`}
                    className="mr-3 text-info"
                  >
                    <i
                      className="mdi mdi-binoculars font-size-18 align-middle mr-2"
                      id="exibirTooltip"
                    ></i>
                  </Link>

                  <Link
                    to={`/cadastros/animal/raca/alterar?id=${racaAnimal.racaAnimalId}`}
                    className="mr-3 text-warning"
                  >
                    <i
                      className="bx bx-edit-alt font-size-18 align-middle mr-2"
                      id="alterarTooltip"
                    ></i>
                  </Link>

                  <Link
                    onClick={() => {
                      setRacaSelecionada(racaAnimal.racaAnimalId);
                      setModalExcluir(true);
                    }}
                    to={() => {}}
                    className="mr-3 text-danger"
                  >
                    <i
                      className="bx bx-trash font-size-18 align-middle mr-2"
                      id="excluirTooltip"
                    ></i>
                  </Link>
                </>
              ),
            };
          });
          setRacaAnimal(listaRacaAnimal);
        } else {
          setRacaAnimal([]);
        }
      } catch (err) {
        setRacaAnimal([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getRacaAnimal();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Lista de Raças" breadcrumbItem="Raça" />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir esta raça?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirRaca}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/animal/raca/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsRacaAnimal(racaAnimal)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AnimalRacaListarPage;
