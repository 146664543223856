import React, { useEffect, useState } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

const SidebarContent = (props) => {
  const [perfil, setPerfil] = useState('');
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    if (localStorage.getItem("@Cashback:authUser")) {
      const obj = JSON.parse(localStorage.getItem("@Cashback:authUser"));
      setPerfil(obj.role);
    }

    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  }

  function downloadAppVenda() {
    if (window.confirm("Você deseja baixar o aplicativo de vendas?")) {
      window.location = "https://catec.inf.br/app/caoecia-cashback-venda.apk";
    }
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        {perfil === 'vendedor' ? (
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-bar-chart-alt-2"></i>
                <span>{props.t("Vendas")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/vendas/registro-manual">
                    {props.t("Registro Manual")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        ) : (perfil === 'profissional' ? (
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")} </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Home")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/clientes">{props.t("Painel de Controle")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Cadastros")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Animal")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/cadastros/animal/listar">
                          {props.t("Animal")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cadastros/animal/raca/listar">
                          {props.t("Raça")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cadastros/animal/tipoanimal/listar">
                          {props.t("Tipo")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-message-rounded-minus"></i>
                  <span>{props.t("Notificações")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/notificacao/envio-manual">
                      {props.t("Envio Manual")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Suporte")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/suporte/contato">{props.t("Contato")}</Link>
                  </li>
                  <li>
                    <Link to="/suporte/sobre">{props.t("Sobre")}</Link>
                  </li>
                </ul>
              </li>
            </ul>
          ) : (
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")} </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Home")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/clientes">{props.t("Painel de Controle")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Cadastros")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span>{props.t("Empresa")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/cadastros/empresa/perfil">
                          {props.t("Perfil")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cadastros/empresa/tipoatendimento/listar">
                          {props.t("Tipos de Atendimento")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cadastros/empresa/atendimento/listar">
                          {props.t("Atendimento")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cadastros/empresa/redesocial/listar">
                          {props.t("Rede Social")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/cadastros/usuario/listar">
                      {props.t("Usuário")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cadastros/cliente/listar">
                      {props.t("Cliente")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span>{props.t("Animal")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/cadastros/animal/listar">
                          {props.t("Animal")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cadastros/animal/raca/listar">
                          {props.t("Raça")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cadastros/animal/tipoanimal/listar">
                          {props.t("Tipo")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span>{props.t("Profissional")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/cadastros/profissional/listar">
                          {props.t("Profissional")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cadastros/profissional/especializacao/listar">
                          {props.t("Especialização")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/cadastros/faixaretorno/listar">
                      {props.t("Faixa")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cadastros/categoria/listar">
                      {props.t("Categoria")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cadastros/servico/listar">
                      {props.t("Serviço")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cadastros/configuracao">
                      {props.t("Configuração")}
                    </Link>
                  </li>
                </ul>
              </li>
              
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-calendar-week"></i>
                  <span>{props.t("Agendamento")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/agendamento/agenda">{props.t("Agenda")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-bar-chart-alt-2"></i>
                  <span>{props.t("Vendas")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/vendas/historico">{props.t("Histórico")}</Link>
                  </li>
                  <li>
                    <Link to="/vendas/registro-manual">
                      {props.t("Registro Manual")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-bitcoin"></i>
                  <span>{props.t("Cashback")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/cashback/extrato">{props.t("Extrato")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-gift"></i>
                  <span>{props.t("Sorteios")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/sorteios/sorteio-manual">
                      {props.t("Sorteio Manual")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-message-rounded-minus"></i>
                  <span>{props.t("Notificações")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/notificacao/envio-manual">
                      {props.t("Envio Manual")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Suporte")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/suporte/contato">{props.t("Contato")}</Link>
                  </li>
                  <li>
                    <Link to="/suporte/sobre">{props.t("Sobre")}</Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span>{props.t("AppVenda")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/#" onClick={downloadAppVenda}>
                          {props.t("Download")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          )
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(SidebarContent));
