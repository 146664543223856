const columnsServico = (servicos) => {
  return {
    columns: [
      {
        label: "#",
        field: "servicoId",
        sort: "asc",
      },
      {
        label: "Descrição",
        field: "descricao",
        sort: "asc",
      },
      {
        label: "Cashback",
        field: "aliquotaCashback",
      },
      {
        label: "Cor",
        field: "corDescricao",
      },
      {
        label: "Categoria",
        field: "categoriaDescricao",
      },
      {
        label: "Venda",
        field: "permitirVenda",
      },
      {
        label: "Agenda",
        field: "agendamento",
      },
      {
        label: "Data  Última Alteração",
        field: "dataAlteracao",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: servicos,
  };
};

export default columnsServico;
