import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  Button,
  CardBody,
} from "reactstrap";

import Select from "react-select";

import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";

import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import api from "../../../../services/api";
import extractNumber from "../../../../utils/extractNumber";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const CadastroEmpresa = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);
  const [activeTab, setactiveTab] = useState("1");

  const [estados, setEstados] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState([]);

  const inputNome = useRef(null);
  const inputTelefone = useRef(null);
  const inputCelular = useRef(null);
  const inputEmail = useRef(null);
  const inputSite = useRef(null);
  const inputEndereco = useRef(null);
  const inputNumeroEndereco = useRef(null);
  const inputComplementoEndereco = useRef(null);
  const inputCep = useRef(null);
  const inputBairro = useRef(null);
  const inputCidade = useRef(null);

  const optionEstado = [
    {
      options: estados,
    },
  ];

  useEffect(() => {
    setEstados([
      { label: "Acre", value: "AC" },
      { label: "Alagoas", value: "AL" },
      { label: "Amapá", value: "AP" },
      { label: "Amazonas", value: "AM" },
      { label: "Bahia", value: "BA" },
      { label: "Ceará", value: "CE" },
      { label: "Distrito Federal", value: "DF" },
      { label: "Espírito Santo", value: "ES" },
      { label: "Goiás", value: "GO" },
      { label: "Maranhão", value: "MA" },
      { label: "Mato Grosso", value: "MT" },
      { label: "Mato Grosso do Sul", value: "MS" },
      { label: "Minas Gerais", value: "MG" },
      { label: "Pará", value: "PA" },
      { label: "Paraíba", value: "PB" },
      { label: "Paraná", value: "PR" },
      { label: "Pernambuco", value: "PE" },
      { label: "Piauí", value: "Pi" },
      { label: "Rio de Janeiro", value: "RJ" },
      { label: "Rio Grande do Norte", value: "RN" },
      { label: "Rio Grande do Sul", value: "RS" },
      { label: "Rondônia", value: "RO" },
      { label: "Roraima", value: "RR" },
      { label: "Santa Catarina", value: "SC" },
      { label: "São Paulo", value: "SP" },
      { label: "Sergipe", value: "SE" },
      { label: "Tocantins", value: "TO" },
    ]);
  }, []);

  useEffect(() => {
    async function getEmpresa() {
      try {
        setAguarde(true);
        const response = await api.get("/web/empresa/padrao");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        setRegistro(response.data.data);

        const auxEstado = estados.filter(
          (estado) => estado.value === response.data.data.enderecoUf
        );

        setEstadoSelecionado(auxEstado[0]);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getEmpresa();
  }, [estados, setAguarde, setMessageBox]);

  const Telefone = (props) => (
    <InputMask
      ref={inputTelefone}
      mask="(99) 99999-9999"
      value={props.value}
      className="form-control"
      onChange={props.onChange}
      style={{ fontSize: 13, color: "#495057", fontWeight: 400 }}
      defaultValue={registro.empresaTelefone}
    >
      {(inputProps) => (
        <MaterialInput {...inputProps} type="tel" disableUnderline />
      )}
    </InputMask>
  );

  const Celular = (props) => (
    <InputMask
      ref={inputCelular}
      mask="(99) 99999-9999"
      value={props.value}
      className="form-control"
      onChange={props.onChange}
      style={{ fontSize: 13, color: "#495057", fontWeight: 400 }}
      defaultValue={registro.empresaCelular}
    >
      {(inputProps) => (
        <MaterialInput {...inputProps} type="tel" disableUnderline />
      )}
    </InputMask>
  );

  const CEP = (props) => (
    <InputMask
      ref={inputCep}
      mask="99999-999"
      value={props.value}
      className="form-control"
      style={{ fontSize: 13, color: "#495057", fontWeight: 400 }}
      onChange={props.onChange}
      defaultValue={registro.enderecoCep}
    >
      {(inputProps) => (
        <MaterialInput {...inputProps} type="tel" disableUnderline />
      )}
    </InputMask>
  );

  /*
  // ***************** Isso não funcionaou *******************************
  const handleConfirm = useCallback(() => {
    console.log("entrei na page");
    setMessageBox({
      description: "Sobrepos o comportamento padrão.. O Pai tá ON",
    });
  }, [setMessageBox]);
  // ***************** Isso não funcionaou *******************************
  */

  const salvarEmpresa = useCallback(async () => {
    let campoVazio = false;
    let mensagemErro = "";

    const auxCelular =
      inputCelular.current.value === ""
        ? null
        : extractNumber(inputCelular.current.value);
    const auxComplemento =
      inputComplementoEndereco.current.value === ""
        ? null
        : inputComplementoEndereco.current.value;

    Object.assign(registro, {
      empresaTelefone: extractNumber(inputTelefone.current.value),
      empresaCelular: auxCelular,
    });

    if (inputNome.current.value === "") {
      campoVazio = true;
      mensagemErro = "Nome não informado";
    } else {
      if (inputTelefone.current.value === "") {
        campoVazio = true;
        mensagemErro = "Telefone não informado";
      } else {
        if (inputEmail.current.value === "") {
          campoVazio = true;
          mensagemErro = "Email não informado";
        } else {
          if (inputSite.current.value === "") {
            campoVazio = true;
            mensagemErro = "Site não informado";
          } else {
            if (inputCep.current.value === "") {
              campoVazio = true;
              mensagemErro = "CEP não informado";
            } else {
              if (inputEndereco.current.value === 0) {
                campoVazio = true;
                mensagemErro = "Endereço não informado";
              } else {
                if (inputNumeroEndereco.current.value === 0) {
                  campoVazio = true;
                  mensagemErro = "Número não informado";
                } else {
                  if (inputBairro.current.value === 0) {
                    campoVazio = true;
                    mensagemErro = "Bairro não informado";
                  } else {
                    if (inputCidade.current.value === 0) {
                      campoVazio = true;
                      mensagemErro = "Cidade não informada";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (campoVazio) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: mensagemErro,
        error: true,
      });
    } else {
      setAguarde(true);

      const data = {
        empresaNome: inputNome.current.value,
        empresaSite: inputSite.current.value,
        empresaEmail: inputEmail.current.value,
        empresaTelefone: extractNumber(inputTelefone.current.value),
        empresaCelular: auxCelular,
        enderecoCep: extractNumber(inputCep.current.value),
        enderecoLogradouro: inputEndereco.current.value,
        enderecoNumero: inputNumeroEndereco.current.value,
        enderecoComplemento: auxComplemento,
        enderecoBairro: inputBairro.current.value,
        enderecoMunicipio: inputCidade.current.value,
        enderecoUf: estadoSelecionado.value,
      };

      const response = await api.patch("/web/empresa/padrao", data);
      setAguarde(false);

      if (response.data.success) {
        await setMessageBox({
          show: true,
          title: "Sucesso",
          description: response.data.message,
          success: true,
        });
      } else {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: response.data.message,
          error: true,
        });
      }
    }
  }, [registro, setMessageBox, setAguarde, estadoSelecionado]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações da Empresa"
            breadcrumbItem="Gerenciamento"
          />

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1");
                      }}
                    >
                      <i className="bx bxs-home d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">Dados</p>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2");
                      }}
                    >
                      <i className="bx bxs-map-pin d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">Endereço</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div>
                          <Form>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="nome"
                                md="2"
                                className="col-form-label"
                              >
                                Nome
                              </Label>
                              <Col md="10">
                                <input
                                  ref={inputNome}
                                  type="text"
                                  className="form-control"
                                  id="nome"
                                  defaultValue={registro.empresaNome}
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="telefone"
                                md="2"
                                className="col-form-label"
                              >
                                Telefone
                              </Label>
                              <Col md="10">
                                <Telefone />
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="celular"
                                md="2"
                                className="col-form-label"
                              >
                                Celular
                              </Label>
                              <Col md={10}>
                                <Celular />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="email"
                                md="2"
                                className="col-form-label"
                              >
                                Email
                              </Label>
                              <Col md="10">
                                <input
                                  ref={inputEmail}
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  defaultValue={registro.empresaEmail}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="site"
                                md="2"
                                className="col-form-label"
                              >
                                Site
                              </Label>
                              <Col md={10}>
                                <input
                                  ref={inputSite}
                                  type="text"
                                  className="form-control"
                                  id="site"
                                  defaultValue={registro.empresaSite}
                                />
                              </Col>
                            </FormGroup>
                          </Form>
                        </div>
                      </TabPane>

                      <TabPane tabId="2">
                        <div>
                          <Form>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="cep"
                                md="2"
                                className="col-form-label"
                              >
                                CEP
                              </Label>
                              <Col md="10">
                                <CEP />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="estado"
                                md="2"
                                className="col-form-label"
                              >
                                Estado
                              </Label>
                              <Col md={10}>
                                <Select
                                  placeholder="Selecione um estado"
                                  value={estadoSelecionado}
                                  onChange={setEstadoSelecionado}
                                  options={optionEstado}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="endereco"
                                md="2"
                                className="col-form-label"
                              >
                                Endereço
                              </Label>
                              <Col md="10">
                                <input
                                  ref={inputEndereco}
                                  type="text"
                                  className="form-control"
                                  id="endereco"
                                  defaultValue={registro.enderecoLogradouro}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="numeroEndereco"
                                md="2"
                                className="col-form-label"
                              >
                                Número
                              </Label>
                              <Col md="2">
                                <input
                                  ref={inputNumeroEndereco}
                                  type="text"
                                  className="form-control"
                                  id="numeroEndereco"
                                  defaultValue={registro.enderecoNumero}
                                />
                              </Col>
                              <Label
                                htmlFor="complementoEndereco"
                                md="3"
                                className="col-form-label"
                              >
                                Complemento
                              </Label>
                              <Col md="5">
                                <input
                                  ref={inputComplementoEndereco}
                                  type="text"
                                  className="form-control"
                                  id="complementoEndereco"
                                  defaultValue={registro.enderecoComplemento}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="bairro"
                                md="2"
                                className="col-form-label"
                              >
                                Bairro
                              </Label>
                              <Col md={10}>
                                <input
                                  ref={inputBairro}
                                  type="text"
                                  className="form-control"
                                  id="bairro"
                                  defaultValue={registro.enderecoBairro}
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="cidade"
                                md="2"
                                className="col-form-label"
                              >
                                Cidade
                              </Label>
                              <Col md={10}>
                                <input
                                  ref={inputCidade}
                                  type="text"
                                  className="form-control"
                                  id="cidade"
                                  defaultValue={registro.enderecoMunicipio}
                                />
                              </Col>
                            </FormGroup>
                          </Form>
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="my-4">
              <Col sm="12">
                <div className="text-sm-right">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mr-1 waves-effect waves-light"
                    onClick={salvarEmpresa}
                  >
                    <i class="mdi mdi-content-save-all-outline align-middle mr-1"></i>
                    Salvar
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CadastroEmpresa;
