import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { VoltarButton } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const EspecializacaoExibirPage = (props) => {
  const history = useHistory();

  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);

  useEffect(() => {
    async function getEspecializacao() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        const response = await api.get(`/web/especializacao/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const especializacao = response.data.data;

        Object.assign(especializacao, {
          dataCadastro: format(
            parseISO(especializacao.dataCadastro),
            "dd/MM/yyyy hh:mm"
          ),
          dataAlteracao: format(
            parseISO(especializacao.dataAlteracao),
            "dd/MM/yyyy hh:mm"
          ),
        });

        setRegistro(especializacao);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getEspecializacao();
  }, [props.location.search, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações da Especialização - Visualização" breadcrumbItem="Especialização" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm className="form-horizontal">
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="descericao"
                            label="Descrição"
                            value={registro.descricao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data do Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data da Última Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EspecializacaoExibirPage;
