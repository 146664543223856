import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import IntlNumberInput from "react-intl-number-input";
import Select from "react-select";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { VoltarButton } from "../../../components/ActionButton";
import api from "../../../services/api";

import formatValue from "../../../utils/formatValue";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const FaixaRetornoExibirPage = (props) => {
  const history = useHistory();

  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);

  const [status] = useState([
    { label: "Ativo", value: 1 },
    { label: "Inativo", value: 2 },
  ]);

  const [statusSelecionado, setStatusSelecionado] = useState([]);

  const optionStatus = [
    {
      options: status,
    },
  ];

  useEffect(() => {
    async function getFaixa() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        const response = await api.get(`/web/faixavalorcashback/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const faixa = response.data.data;

        Object.assign(faixa, {
          valorInicial: formatValue(faixa.valorInicial, "currency"),
          valorFinal: formatValue(faixa.valorFinal, "currency"),
          aliquotaCashback: formatValue(faixa.aliquotaCashback, "percent"),
          dataCadastro: format(
            parseISO(faixa.dataCadastro),
            "dd/MM/yyyy hh:mm"
          ),
          dataAlteracao: format(
            parseISO(faixa.dataAlteracao),
            "dd/MM/yyyy hh:mm"
          ),
        });

        const auxStatus = status.filter(
          (status) => status.value === faixa.status
        );

        setStatusSelecionado(auxStatus);

        setRegistro(faixa);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getFaixa();
  }, [
    optionStatus.options,
    props.location.search,
    setAguarde,
    setMessageBox,
    status,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações de Faixa - Visualização" breadcrumbItem="Faixa" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm className="form-horizontal">
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Valor Inicial (R$)</Label>
                          <IntlNumberInput
                            prefix="R$ "
                            locale="pt-BR"
                            precision={2}
                            value={registro.valorInicial}
                            className="form-control"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Valor Final (R$)</Label>
                          <IntlNumberInput
                            prefix="R$ "
                            locale="pt-BR"
                            precision={2}
                            value={registro.valorFinal}
                            className="form-control"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Aliquota de Cashback (%)</Label>
                          <IntlNumberInput
                            locale="pt-BR"
                            precision={2}
                            value={registro.aliquotaCashback}
                            className="form-control"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Status</Label>
                          <Select
                            placeholder="Selecione um Status"
                            value={statusSelecionado}
                            onChange={setStatusSelecionado}
                            options={optionStatus}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data do Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data da Última Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FaixaRetornoExibirPage;
