import React, { useEffect, useState, useCallback } from "react";
import { format, parseISO } from "date-fns";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import api from "../../../../services/api";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import formatCpf from "../../../../utils/formatCpf";
import formatValue from "../../../../utils/formatValue";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import { columnsProfissional } from "./datatableColumns";
import {
  EmailButton,
  WhatsAppButton,
} from "../../../../components/ContactButton";

const ProfissionalListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();
  const [profissionalSelecionado] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [profissionais, setProfissionais] = useState([]);
  const [quantidadeHomens, setQuantidadeHomens] = useState("0");
  const [percentualHomens, setPercentualHomens] = useState("0 %");
  const [quantidadeMulheres, setQuantidadeMulheres] = useState("0");
  const [percentualMulheres, setPercentualMulheres] = useState("0 %");
  const [quantidadeOutros, setQuantidadeOutros] = useState("0");
  const [percentualOutros, setPercentualOutros] = useState(" 0%");

  /*
  const [quantidadeAndroid, setQuantidadeAndroid] = useState('0');
  const [percentualAndroid, setPercentualAndroid] = useState('0 %');
  const [quantidadeIOS, setQuantidadeIOS] = useState('0');
  const [percentualIOS, setPercentualIOS] = useState('0 %');
  const [quantidadeSemDispositivo, setQuantidadeSemDispositivo] = useState('0');
  const [percentualSemDispositivo, setPercentualSemDispositivo] = useState(' 0%');
  

  const handleOpenWhatsApp = useCallback((fone) => {
    console.log(fone);
    window.open(
      `https://api.whatsapp.com/send/?phone=55${fone}&text&app_absent=0`,
      "_blank"
    );
  }, []);
*/

  const excluirProfissional = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(
        `/web/profissional/${profissionalSelecionado}`
      );

      setAguarde(false);

      if (response.data.success) {
        const auxProfissional = profissionais.filter(
          (profissional) => profissional.usuarioId !== profissionalSelecionado
        );

        setProfissionais(auxProfissional);
        await setMessageBox({
          show: true,
          title: "Sucesso",
          description: "Profissional excluído com sucesso.",
          success: true,
        });
      } else {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: response.data.message,
          error: true,
        });
      }
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [setAguarde, profissionalSelecionado, profissionais, setMessageBox]);

  useEffect(() => {
    async function getProfissional() {
      try {
        setAguarde(true);

        const response = await api.get("/web/profissional");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        /*
        const { quantidadeHomen, quantidadeMulher, quantidadeOutro, 
          quantidadeCao, quantidadeGato, auxQuantidadeSemDispositivo,
          auxQuantidadeAndroid, auxQuantidadeIOS } = response.data.data.reduce( */

        const { quantidadeHomen, quantidadeMulher, quantidadeOutro } =
          response.data.data.reduce(
            (accumulator, profissional) => {
              if (profissional.genero.toUpperCase() === "M") {
                accumulator.quantidadeHomen += 1;
              } else {
                if (profissional.genero.toUpperCase() === "F") {
                  accumulator.quantidadeMulher += 1;
                } else {
                  accumulator.quantidadeOutro += 1;
                }
              }

              /*
            if (profissional.usuarioPlataforma === 1) {
              accumulator.auxQuantidadeAndroid += 1;
            } else {
              if (profissional.usuarioPlataforma === 2) {
                accumulator.auxQuantidadeIOS += 1;
              } else {
                accumulator.auxQuantidadeSemDispositivo += 1;
              }
            } */

              accumulator.quantidadeCao += profissional.quantidadeCao;
              accumulator.quantidadeGato += profissional.quantidadeGato;
              return accumulator;
            },
            {
              quantidadeHomen: 0,
              quantidadeMulher: 0,
              quantidadeOutro: 0,
              quantidadeCao: 0,
              quantidadeGato: 0,
              //auxQuantidadeSemDispositivo: 0,
              //auxQuantidadeAndroid: 0,
              //auxQuantidadeIOS: 0,
            }
          );

        const listaProfissional = response.data.data.map((profissional) => {
          return {
            ...profissional,
            cpf: formatCpf(profissional.cpf),
            quantidadeCao: (
              <div className="text-center">{profissional.quantidadeCao}</div>
            ),
            quantidadeGato: (
              <div className="text-center">{profissional.quantidadeGato}</div>
            ),
            celular: (
              <WhatsAppButton
                fone={profissional.celular}
                formated
                visibleText
              />
            ),
            genero:
              profissional.genero === "o" ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-warning font-size-11">
                    Outros
                  </span>
                </div>
              ) : profissional.genero === "m" ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-info font-size-11">
                    Homem
                  </span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-danger font-size-11">
                    Mulher
                  </span>
                </div>
              ),
            usuarioStatus:
              profissional.usuarioStatus === 1 ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-success font-size-11">
                    Ativo
                  </span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-danger font-size-11">
                    Inativo
                  </span>
                </div>
              ),
            dataNascimento: profissional.dataNascimento
              ? format(parseISO(profissional.dataNascimento), "dd/MM/yyyy")
              : "",
            usuarioDataUltimoAcesso: profissional.usuarioDataUltimoAcesso
              ? format(
                  parseISO(profissional.usuarioDataUltimoAcesso),
                  "dd/MM/yyyy hh:mm"
                )
              : "",
            action: (
              <>
                <Link
                  to={`/cadastros/profissional/exibir?id=${profissional.profissionalId}`}
                  className="mr-1 text-info"
                >
                  <i
                    className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                    id="exibirTooltip"
                  ></i>
                </Link>

                <Link
                  to={`/cadastros/profissional/alterar?id=${profissional.profissionalId}`}
                  className="mr-1 text-warning"
                >
                  <i
                    className="bx bx-edit-alt font-size-18 align-middle mr-1"
                    id="alterarTooltip"
                  ></i>
                </Link>

                <EmailButton email={profissional.usuarioEmail} />
              </>
            ),
          };
        });
        setProfissionais(listaProfissional);

        setQuantidadeHomens(quantidadeHomen);
        setQuantidadeMulheres(quantidadeMulher);
        setQuantidadeOutros(quantidadeOutro);

        /*
        setQuantidadeAndroid(auxQuantidadeAndroid);
        setQuantidadeIOS(auxQuantidadeIOS);
        setQuantidadeSemDispositivo(auxQuantidadeSemDispositivo);
        */

        if (listaProfissional.length > 0) {
          setPercentualHomens(
            formatValue(
              (quantidadeHomen / listaProfissional.length) * 100,
              "percent"
            )
          );
          setPercentualMulheres(
            formatValue(
              (quantidadeMulher / listaProfissional.length) * 100,
              "percent"
            )
          );
          setPercentualOutros(
            formatValue(
              (quantidadeOutro / listaProfissional.length) * 100,
              "percent"
            )
          );

          /*
          setPercentualAndroid(formatValue(auxQuantidadeAndroid / listaProfissional.length * 100, 'percent'));
          setPercentualIOS(formatValue(auxQuantidadeIOS / listaProfissional.length * 100, 'percent'));
          setPercentualSemDispositivo(formatValue(auxQuantidadeSemDispositivo / listaProfissional.length * 100, 'percent'));
          */
        } else {
          setPercentualHomens("0,00 %");
          setPercentualMulheres("0,00 %");
          setPercentualOutros("0,00 %");

          /*
          setPercentualAndroid('0,00 %');
          setPercentualIOS('0,00 %');
          setPercentualSemDispositivo('0,00 %');
          */
        }
      } catch (err) {
        setProfissionais([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getProfissional();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Lista de Profissionais"
            breadcrumbItem="Profissional"
          />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir este profissional?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirProfissional}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          {/*
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/profissional/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          */}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/profissional/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-dark text-dark font-size-18">
                        <i className="mdi mdi-account-group-outline"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">
                      Total de Profissionais
                    </h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{profissionais.length}</h4>
                    <span className="badge badge-soft-dark font-size-12">
                      100%
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-info text-info font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Homens</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeHomens}</h4>
                    <span className="badge badge-soft-info font-size-12">
                      {percentualHomens}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-danger text-danger font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Mulheres</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeMulheres}</h4>
                    <span className="badge badge-soft-danger font-size-12">
                      {percentualMulheres}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-warning text-warning font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Outros</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeOutros}</h4>
                    <span className="badge badge-soft-warning font-size-12">
                      {percentualOutros}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/*
          <Row>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-dark text-dark font-size-18">
                        <i className="bx bxl-apple"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">iOS</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeIOS}</h4>
                    <span className="badge badge-soft-dark font-size-12"> 
                      {percentualIOS}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-success text-success font-size-18">
                        <i className="bx bxl-android"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Android</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeAndroid}</h4>
                    <span className="badge badge-soft-success font-size-12"> 
                      {percentualAndroid}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-danger text-danger font-size-18">
                        <i className="bx bx-window-close"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Sem Dispositivo</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeSemDispositivo}</h4>
                    <span className="badge badge-soft-danger font-size-12"> 
                      {percentualSemDispositivo}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-warning text-warning font-size-18">
                        <i className="mdi mdi-dog"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Pets</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>{quantidadeMaior}</h5>
                    <h6>{quantidadeMenor}</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          */}
          <Row>
            <Col className="col-12">
              <DataTableDefault
                dataSource={columnsProfissional(profissionais)}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfissionalListarPage;
