import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import accessToken from "../jwt-token-access/accessToken";
import { verify } from "jsonwebtoken";

import api from "../../services/api";

let users = [
  {
    uid: 9000,
    username: "Melissa",
    role: "admin",
    password: "123456",
    email: "melissa.cavalca@caoecia.com.br",
  },
];

const fakeBackend = () => {
  // This sets the mock adapter on the default instance
  var mock = new MockAdapter(axios);

  mock.onPost("/auth").reply(async function (config) {
    const user = JSON.parse(config["data"]);

    const response = await api.post("auth", user);

    return new Promise(function (resolve, reject) {
      if (response.data.success) {
        const token = response.data.data.token;
        const decoded = verify(token, process.env.REACT_APP_SECRET);

        let role;
        if (decoded.perfilId === 4)
          role = 'vendedor';
        else 
          if (decoded.perfilId === 5)
            role = 'profissional';
          else
            role = 'admin';

        const userAuth = {
          uid: Number(decoded.sub),
          username: response.data.data.nome,
          role,
          password: user.password,
          email: user.email,
        };

        const tokenObj = { accessToken: `Bearer ${token}` }; // Token Obj
        
        api.defaults.headers = { Authorization: `Bearer ${token}` };

        const validUserObj = { ...userAuth, ...tokenObj }; // validUser Obj

        resolve([200, validUserObj]);
      } else {
        reject([400, response.data.message]);
      }
    });
  });
  
  mock.onPost("/recuperar-senha").reply(async function (config) {
    const user = JSON.parse(config["data"]);

    const response = await api.post("auth/recuperarsenha", user);

    return new Promise(function (resolve, reject) {
      if (response.data.success) {
        resolve([200, "Verifique sua caixa de email e redefina sua senha."]);
      }
      else {
        reject([400, response.data.message]);
      }
    });
  });
  
  mock.onPost("/resetar-senha").reply(async function (config) {
    const user = JSON.parse(config["data"]);

    const response = await api.post("auth/definirsenha", user);

    return new Promise(function (resolve, reject) {
      if (response.data.success) {
        const token = response.data.data.token;
        const decoded = verify(token, process.env.REACT_APP_SECRET);

        let role;
        if (decoded.perfilId === 4)
          role = 'vendedor';
        else 
          if (decoded.perfilId === 5)
            role = 'profissional';
          else
            role = 'admin';

        const userAuth = {
          uid: Number(decoded.sub),
          username: response.data.data.nome,
          role,
          password: user.password,
          email: user.email,
        };

        const tokenObj = { accessToken: `Bearer ${token}` }; // Token Obj
        
        api.defaults.headers = { Authorization: `Bearer ${token}` };
        
        const validUserObj = { ...userAuth, ...tokenObj }; // validUser Obj

        resolve([200, validUserObj]);
      } else {
        reject([400, response.data.message]);
      }
    });
  });

  mock.onPost("/post-fake-register").reply(function (config) {
    const user = JSON.parse(config["data"]);
    users.push(user);

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve([200, user]);
      });
    });
  });

  mock.onPost("/post-fake-login").reply(function (config) {
    const user = JSON.parse(config["data"]);
    const validUser = users.filter(
      (usr) => usr.email === user.email && usr.password === user.password
    );

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        if (validUser["length"] === 1) {
          resolve([200, validUser[0]]);
        } else {
          reject([400, "Email ou senha inválida. Por favor, tente novamente!"]);
        }
      });
    });
  });

  mock.onPost("/fake-forget-pwd").reply(function (config) {
    // User needs to check that user is eXist or not and send mail for Reset New password

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve([200, "Verifique sua caixa de email e redefina sua senha."]);
      });
    });
  });

  mock.onPost("/post-jwt-register").reply(function (config) {
    const user = JSON.parse(config["data"]);
    users.push(user);

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve([200, user]);
      });
    });
  });

  mock.onPost("/post-jwt-login").reply(function (config) {
    const user = JSON.parse(config["data"]);
    const validUser = users.filter(
      (usr) => usr.email === user.email && usr.password === user.password
    );

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        if (validUser["length"] === 1) {
          // You have to generate AccessToken by jwt. but this is fakeBackend so, right now its dummy
          var token = accessToken;

          // JWT AccessToken
          const tokenObj = { accessToken: token }; // Token Obj
          const validUserObj = { ...validUser[0], ...tokenObj }; // validUser Obj

          resolve([200, validUserObj]);
        } else {
          reject([400, "Email ou senha inválida. Por favor, tente novamente!"]);
        }
      });
    });
  });

  mock.onPost("/post-jwt-profile").reply(function (config) {
    const user = JSON.parse(config["data"]);

    const one = config.headers;

    let finalToken = one.Authorization;

    const validUser = users.filter((usr) => usr.uid === user.idx);

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        // Verify Jwt token from header.Authorization
        if (finalToken === accessToken) {
          if (validUser["length"] === 1) {
            let objIndex;

            //Find index of specific object using findIndex method.
            objIndex = users.findIndex((obj) => obj.uid === user.idx);

            //Update object's name property.
            users[objIndex].username = user.username;

            // Assign a value to locastorage
            localStorage.removeItem("@Cashback:authUser");
            localStorage.setItem("@Cashback:authUser", JSON.stringify(users[objIndex]));

            resolve([200, "Perfil do usuário alterado com sucesso!"]);
          } else {
            reject([
              400,
              "Ocorreu uma falha ao tentar alterar o perfil do usuário!!",
            ]);
          }
        } else {
          reject([400, "Token de verificação inválido!!"]);
        }
      });
    });
  });

  mock.onPost("/post-fake-profile").reply(function (config) {
    const user = JSON.parse(config["data"]);

    const validUser = users.filter((usr) => usr.uid === user.idx);

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        if (validUser["length"] === 1) {
          let objIndex;

          //Find index of specific object using findIndex method.
          objIndex = users.findIndex((obj) => obj.uid === user.idx);

          //Update object's name property.
          users[objIndex].username = user.username;

          // Assign a value to locastorage
          // console.log("fakebackend2");
          localStorage.removeItem("@Cashback:authUser");
          localStorage.setItem("@Cashback:authUser", JSON.stringify(users[objIndex]));

          // console.log(localStorage.getItem("@Cashback:authUser"));

          resolve([200, "Perfil do usuário alterado com sucesso!"]);
        } else {
          reject([
            400,
            "Ocorreu uma falha ao tentar alterar o perfil do usuário!!",
          ]);
        }
      });
    });
  });

  mock.onPost("/jwt-forget-pwd").reply(function (config) {
    // User needs to check that user is eXist or not and send mail for Reset New password

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve([200, "Verifique sua caixa de email e redefina sua senha."]);
      });
    });
  });
};

export default fakeBackend;
