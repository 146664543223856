import React, { useEffect, useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import api from "../../../../services/api";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import columnsEspecializacao from "./datatableColumns";

const EspecializacaoListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [especializacaoSelecionada, setEspecializacaoSelecionada] =
    useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [especializacao, setEspecializacao] = useState([]);

  const excluirEspecializacao = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(
        `/web/especializacao/${especializacaoSelecionada}`
      );
      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const auxEspecializacao = especializacao.filter(
        (especializacao) =>
          especializacao.especializacaoId !== especializacaoSelecionada
      );

      setEspecializacao(auxEspecializacao);

      await setMessageBox({
        show: true,
        title: "Sucesso",
        description: "Especialização excluída com sucesso.",
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [setAguarde, especializacao, setMessageBox, especializacaoSelecionada]);

  useEffect(() => {
    async function getEspecializacao() {
      try {
        setAguarde(true);
        const response = await api.get("/web/especializacao");
        setAguarde(false);
        if (response.data.success) {
          const listaEspecializacao = response.data.data.map(
            (especializacao) => {
              return {
                ...especializacao,
                dataCadastro: especializacao.dataCadastro
                  ? format(parseISO(especializacao.dataCadastro), "dd/MM/yyyy hh:mm")
                  : "",
                dataUltimoAcesso: especializacao.dataUltimoAcesso
                  ? format(
                      parseISO(especializacao.usuarioDataUltimoAcesso),
                      "dd/MM/yyyy hh:mm"
                    )
                  : "",
                action: (
                  <>
                    <Link
                      to={`/cadastros/profissional/especializacao/exibir?id=${especializacao.especializacaoId}`}
                      className="mr-1 text-info"
                    >
                      <i
                        className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                        id="exibirTooltip"
                      ></i>
                    </Link>

                    <Link
                      to={`/cadastros/profissional/especializacao/alterar?id=${especializacao.especializacaoId}`}
                      className="mr-1 text-warning"
                    >
                      <i
                        className="bx bx-edit-alt font-size-18 align-middle mr-1"
                        id="alterarTooltip"
                      ></i>
                    </Link>

                    <Link
                      onClick={() => {
                        setEspecializacaoSelecionada(
                          especializacao.especializacaoId
                        );
                        setModalExcluir(true);
                      }}
                      to={() => {}}
                      className="mr-1 text-danger"
                    >
                      <i
                        className="bx bx-trash font-size-18 align-middle mr-1"
                        id="excluirTooltip"
                      ></i>
                    </Link>
                  </>
                ),
              };
            }
          );
          setEspecializacao(listaEspecializacao);
        } else {
          setEspecializacao([]);
        }
      } catch (err) {
        setEspecializacao([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getEspecializacao();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Lista de Especializações"
            breadcrumbItem="Especialização"
          />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir esta especialização?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirEspecializacao}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/profissional/especializacao/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <DataTableDefault
                dataSource={columnsEspecializacao(especializacao)}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EspecializacaoListarPage;
