import React, { useState, useCallback, useEffect, useRef } from 'react';
import { format, addMinutes } from "date-fns";
import { 
  Row, 
  Col, 
  Card, 
  CardBody,
  Form, 
  FormGroup, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  Container, 
  Label 
} from 'reactstrap';

import Select from "react-select";
import { AvForm, AvField } from 'availity-reactstrap-validation-safe';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';
import ptbrLocale from '@fullcalendar/core/locales/pt-br'

import api from "../../../services/api";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteBox from "../../../components/Common/DeleteBox";

import { enumAgendamentoServico } from "../../../helpers/enums";

const event1= { 
  id: 0, 
  title: "", 
  start: "",
  end: "",
  className: "", 
  category: "", 
  title_category: "" 
};

const Agenda = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [eventEdit, setEventEdit] = useState(false);
  const [modalEvent, setModalEvent] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [event, setEvent] = useState(event1);

  const [dataSelecionada, setDataSelecionada] = useState("");
  const [horaInicialSelecionada, setHoraInicialSelecionada] = useState("");
  const [horaFinalSelecionada, setHoraFinalSelecionada] = useState("");

  const [mesAtual, setMesAtual] = useState(1);
  const [anoAtual, setAnoAtual] = useState(2021);

  const [cores, setCores] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [profissionalSelecionado, setProfissionalSelecionado] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);

  const [animais, setAnimais] = useState([]);
  const [animalSelecionado, setAnimalSelecionado] = useState([]);

  const [tiposAgendamento, setTiposAgendamento] = useState([]);
  const [tipoAgendamentoSelecionado, setTipoAgendamentoSelecionado] = useState([]);

  const dataAgendamento = useRef(null);
  const horaInicial = useRef(null);
  const horaFinal = useRef(null);
  const calendarComponentRef = React.createRef();

  const [textareabadge, settextareabadge] = useState(0);
  const [textcount, settextcount] = useState(0);

  function textareachange(event) {
    var count = event.target.value.length;
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(event.target.value.length);
  }

  const optionProfissional = [
    {
      options: profissionais,
    },
  ];

  const optionTipoAgendamento = [
    {
      options: tiposAgendamento,
    },
  ];

  const optionCliente = [
    {
      options: clientes,
    },
  ];

  const optionAnimal = [
    {
      options: animais,
    },
  ];

  useEffect(() => {
    async function getDados() {
      try {
        setAguarde(true);

        setDataSelecionada(format(new Date(), "yyyy-MM-dd"));
        setHoraInicialSelecionada(format(new Date(), "HH:mm"));
        setHoraFinalSelecionada(format(new Date(), "HH:mm"));

        const responseProfissional = await api.get("/web/profissional");
        const responseCliente = await api.get("/web/cliente");
        const responseServico = await api.get("/web/servico");
        const reponseCores = await api.get("/web/configuracao/view/cores");

        setAguarde(false);

        if (!responseProfissional.data.success) {
          throw responseProfissional.data;
        }

        if (!responseCliente.data.success) {
          throw responseCliente.data;
        }

        if (!responseServico.data.success) {
          throw responseServico.data;
        }

        if (!reponseCores.data.success) {
          throw reponseCores.data;
        }

        const listaProfissional = responseProfissional.data.data.map((profissional) => {
          return {
            label: profissional.nome,
            value: profissional.profissionalId,
          };
        });

        const listaCliente = responseCliente.data.data.map((cliente) => {
          return {
            label: cliente.nome,
            value: cliente.clienteId,
          };
        });

        const listaServicoAgendamento = responseServico.data.data.filter(
          (servico) => servico.status === 1 && servico.agendamento !== enumAgendamentoServico.NaoPermitido
        );

        const listaServico = listaServicoAgendamento.map((servico) => {
          return {
            label: servico.descricao,
            value: servico.servicoId,
          };
        });

        setCores(reponseCores.data.data);
        setProfissionais(listaProfissional);
        setClientes(listaCliente);
        setTiposAgendamento(listaServico);

      } catch (err) {
        setProfissionais([]);
        setClientes([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getDados();
  }, [setAguarde, setMessageBox]);

  const handleChangeProfissional = useCallback(
    async (profissional) => {
      try {

        const responseAgenda = await api.get(`/web/agenda/mes/${anoAtual}${mesAtual}/ate/${anoAtual}${mesAtual}/profissional/${profissional.value}`);

        if (!responseAgenda.data.success) {
          throw responseAgenda.data;
        }

        setProfissionalSelecionado(profissional);

        const registrosAgenda = responseAgenda.data.data.map((agenda) => {
          return {
            id: agenda.agendaId, 
            title: `${agenda.animalNome} (${agenda.clienteNome})`,
            start: new Date (`${agenda.data.substring(0,10)} ${agenda.horaInicial}:00`),
            end: new Date (`${agenda.data.substring(0,10)} ${agenda.horaFinal}:00`),
            className: agenda.corClassName, 
            category: `${agenda.clienteId}|${agenda.animalId}|${agenda.servicoId}`,
            title_category: agenda.observacao,
          };
        });

        setCalendarEvents(registrosAgenda);

      } catch (err) {
          setProfissionais([]);
          setClientes([]);
          setAguarde(false);
          await setMessageBox({
            show: true,
            title: "Erro",
            description: err.message,
            error: true,
          });
        }
    },
    [anoAtual, mesAtual, setAguarde, setMessageBox]
  );

  const handleChangeCliente = useCallback(
    async (cliente) => {
      try {

        const responseAnimal = await api.get(`/web/cliente/${cliente.value}/animal`);

        if (!responseAnimal.data.success) {
          throw responseAnimal.data;
        }

        setClienteSelecionado(cliente);

        const registrosAnimal = responseAnimal.data.data.map((animal) => {
          return {
            label: animal.nome,
            value: animal.animalId, 
          };
        });

        setAnimais(registrosAnimal);

      } catch (err) {
          setAnimais([]);
          setAguarde(false);
          await setMessageBox({
            show: true,
            title: "Erro",
            description: err.message,
            error: true,
          });
        }
    },
    [setAguarde, setMessageBox]
  );

  const handleAtualizaEvento = async (arg) => {
    if (profissionalSelecionado.value === undefined) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Profissional não informado",
        error: true,
      });
      return;
    }

    try {
      const event = arg.event;

      const event_tmp = { 
        id: event.id, 
        title: event.title, 
        start: event.start, 
        end: event.end, 
        className: event.classNames, 
        category: event.extendedProps.category, 
        title_category: event.extendedProps.title_category, 
      };

      const indicadores = event_tmp.category.split("|");

      // setAguarde(true);

      const response = await api.patch(`/web/agenda/${event.id}`,
        {
          profissionalId: profissionalSelecionado.value,
          data: format(event_tmp.start, "yyyy-MM-dd"),
          horaInicial: format(event_tmp.start, "HH:mm"),
          horaFinal: format(event_tmp.end, "HH:mm"),
          status: 0,
          servicoId: parseInt(indicadores[2]),
          clienteId: parseInt(indicadores[0]),
          animalId: parseInt(indicadores[1]),
          observacao: event_tmp.title_category
        }
      );

      // setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }

  const handleSelecionarData = async (arg) => {
    if (profissionalSelecionado.value === undefined) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Profissional não informado",
        error: true,
      });
      return;
    }
    
    setEventEdit(false);
    setEvent(event1);

    setDataSelecionada(format(arg.date, "yyyy-MM-dd"));
    setHoraInicialSelecionada(format(arg.date, "HH:mm"));
    setHoraFinalSelecionada(format(addMinutes(arg.date,15), "HH:mm"));

    setClienteSelecionado([]);
    setAnimais([]);
    setAnimalSelecionado([]);
    setTipoAgendamentoSelecionado([]);

    settextareabadge(false);
    settextcount(0);

    setModalEvent(true);
  }

  const handleSelecionarEvento = async (arg) => {
    const eventNew = arg.event;
    
    const event_tmp = { 
      id: eventNew.id, 
      title: eventNew.title, 
      start: eventNew.start, 
      end: eventNew.end, 
      className: eventNew.classNames, 
      category: eventNew.extendedProps.category, 
      title_category: eventNew.extendedProps.title_category === null ? "" : eventNew.extendedProps.title_category, 
    };

    if (event_tmp.title_category.length > 0) {
      settextareabadge(true);
      settextcount(event_tmp.title_category.length);
    } else {
      settextareabadge(false);
      settextcount(0);
    }

    const indicadores = event_tmp.category.split("|");

    const auxCliente = clientes.filter(
      (cliente) => cliente.value === parseInt(indicadores[0])
    );

    if (auxCliente[0]) {
      const responseAnimal = await api.get(`/web/cliente/${indicadores[0]}/animal`);

      if (!responseAnimal.data.success) {
        throw responseAnimal.data;
      }

      setClienteSelecionado(auxCliente[0]);

      const registrosAnimal = responseAnimal.data.data.map((animal) => {
        return {
          label: animal.nome,
          value: animal.animalId, 
        };
      });

      setAnimais(registrosAnimal);

      const auxAnimal = registrosAnimal.filter(
        (animal) => animal.value === parseInt(indicadores[1])
      );
  
      if (auxAnimal[0]) {
        setAnimalSelecionado(auxAnimal[0]);
      }
    }

    const auxTipoAgendamento = tiposAgendamento.filter(
      (tipoAgendamento) => tipoAgendamento.value === parseInt(indicadores[2])
    );

    if (auxTipoAgendamento[0]) {
      setTipoAgendamentoSelecionado(auxTipoAgendamento[0]);
    }

    setDataSelecionada(format(eventNew.start, "yyyy-MM-dd"));
    setHoraInicialSelecionada(format(eventNew.start, "HH:mm"));
    setHoraFinalSelecionada(format(eventNew.end, "HH:mm"));
        
    setEventEdit(true);
    setEvent(event_tmp);
    setModalEvent(true)
  }

  const handleExcluirEvento = useCallback(async () => {
    setDeleteModal(false);
    setModalEvent(false);

    try {

      setAguarde(true);

      const response = await api.delete(`/web/agenda/${event.id}`);
      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const filteredArray = calendarEvents.filter(item => item.id + "" !== event.id + "");
    
      setCalendarEvents(filteredArray);

    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  },[event.id, calendarEvents, setMessageBox, setAguarde]);

  const handleSubmit = useCallback(async (e, values) => {
    if (profissionalSelecionado.value === undefined) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Profissional não informado",
        error: true,
      });
      return;
    }

    if (clienteSelecionado.value === undefined) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Cliente não informado",
        error: true,
      });
      return;
    }

    if (animalSelecionado.value === undefined) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Animal não informado",
        error: true,
      });
      return;
    }

    if (dataAgendamento.current.value === "") {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Data não informada",
        error: true,
      });
      return;
    }

    if (horaInicial.current.value === "") {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Hora Inicial não informada",
        error: true,
      });
      return;
    }

    if (horaFinal.current.value === "") {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Hora Final não informada",
        error: true,
      });
      return;
    }
    
    if (tipoAgendamentoSelecionado.value === undefined) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: "Tipo de Agendamento não informado",
        error: true,
      });
      return;
    }

    const corEvento = cores.filter(
      (cor) => cor.corId === parseInt(tipoAgendamentoSelecionado.value)
    ); 

    try { 
      let newEvent;

      if (eventEdit) {

        setAguarde(true);

        const response = await api.patch(`/web/agenda/${event.id}`,
          {
            profissionalId: profissionalSelecionado.value,
            data: dataAgendamento.current.value,
            horaInicial: horaInicial.current.value,
            horaFinal: horaFinal.current.value,
            status: 0,
            servicoId: tipoAgendamentoSelecionado.value,
            clienteId: clienteSelecionado.value,
            animalId: animalSelecionado.value,
            observacao: values.observacao === '' ? null : values.observacao
          }
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        newEvent = { 
          id: event.id, 
          title: `${animalSelecionado.label} (${clienteSelecionado.label})`,
          start: new Date (`${dataAgendamento.current.value} ${horaInicial.current.value}:00`),
          end: new Date (`${dataAgendamento.current.value} ${horaFinal.current.value}:00`),
          className: corEvento[0].className,
          category: `${clienteSelecionado.value}|${animalSelecionado.value}|${tipoAgendamentoSelecionado.value}`, 
          title_category: values.observacao,
        };

        let filteredArray = calendarEvents.filter(item => item.id + "" !== event.id + "");
    
        let NewArray = filteredArray.concat(newEvent);
        setCalendarEvents(NewArray);
      }
      else {

        setAguarde(true);

        const response = await api.post(`/web/agenda`,
          {
            profissionalId: profissionalSelecionado.value,
            data: dataAgendamento.current.value,
            horaInicial: horaInicial.current.value,
            horaFinal: horaFinal.current.value,
            status: 0,
            servicoId: tipoAgendamentoSelecionado.value,
            clienteId: clienteSelecionado.value,
            animalId: animalSelecionado.value,
            observacao: values.observacao === '' ? null : values.observacao
          }
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        newEvent = {};

        newEvent = {
          id: response.data.data.agendaId,
          title: `${clienteSelecionado.label} (${animalSelecionado.label})`,
          start: new Date (`${dataAgendamento.current.value} ${horaInicial.current.value}:00`),
          end: new Date (`${dataAgendamento.current.value} ${horaFinal.current.value}:00`),
          className: corEvento[0].className,
          category: `${clienteSelecionado.value}|${animalSelecionado.value}|${tipoAgendamentoSelecionado.value}`, 
          title_category: values.observacao,
        };

        // save new event
        setCalendarEvents(calendarEvents.concat(newEvent));
      }

      setClienteSelecionado([]);
      setAnimais([]);
      setAnimalSelecionado([]);
      setTipoAgendamentoSelecionado([]);
      
      setModalEvent(false);
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  },[calendarEvents, profissionalSelecionado.value, clienteSelecionado.label, clienteSelecionado.value, animalSelecionado.label, animalSelecionado.value, cores, event.id, eventEdit, setAguarde, setMessageBox, tipoAgendamentoSelecionado.value]);

  return (
    <React.Fragment>
      <DeleteBox
        title="Desenja excluir esse agendamento?"
        show={deleteModal}
        onDeleteClick={handleExcluirEvento}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Agenda" breadcrumbItem="Agendamento"/>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>Profissional</Label>
                          <Select
                            placeholder="Selecione um profissional"
                            value={profissionalSelecionado}
                            onChange={(value) => handleChangeProfissional(value)}
                            options={optionProfissional}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-lg-12">         
                      <FullCalendar 
                        ref={calendarComponentRef} 
                        plugins={[BootstrapTheme, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        slotDuration={'00:15:00'}
                        slotLabelInterval={'00:15:00'}
                        slotLabelFormat={{
                          hour: '2-digit',
                          minute: '2-digit',
                        }}
                        hiddenDays={[0,6]}
                        slotMinTime={'08:00:00'}
                        slotMaxTime={'20:00:00'}
                        handleWindowResize={true}
                        eventDisplay="block"
                        themeSystem="bootstrap"
                        headerToolbar={{
                            start: 'prev,next today',
                            center: 'title',
                            end: 'timeGridDay,dayGridMonth,timeGridWeek'
                        }}
                        datesSet={(arg) => {
                          if (arg.view.activeStart.getMonth() !== arg.view.activeEnd.getMonth()) {
                            arg.view.activeStart.getMonth()
                            setMesAtual(arg.view.activeStart.getMonth() + 2);
                          } else {
                            setMesAtual(arg.view.activeStart.getMonth() + 1);
                          }
                          setAnoAtual(arg.view.activeStart.getFullYear());
                        }}
                        events={calendarEvents}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        eventDrop={handleAtualizaEvento}
                        eventResize={handleAtualizaEvento}
                        dateClick={handleSelecionarData}
                        eventClick={handleSelecionarEvento}
                        locale={ptbrLocale}
                        allDaySlot={false} />
              
                      <Modal 
                        isOpen={modalEvent} 
                        toggle={() => setModalEvent(!modalEvent)} className="">
                        <ModalHeader toggle={() => setModalEvent(!modalEvent)} tag="h4">
                          Registrar Horário
                        </ModalHeader>
                        <ModalBody>
                          <AvForm onValidSubmit={handleSubmit}>
                            <Row className="mb-3" form>
                              <Col className="col-12">
                                <Label>Cliente:</Label>
                                <Select
                                  placeholder="Selecione um cliente"
                                  value={clienteSelecionado}
                                  onChange={handleChangeCliente}
                                  options={optionCliente}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3" form>
                              <Col className="col-12">
                                <Label>Animais:</Label>
                                <Select
                                  placeholder="Selecione um Animal"
                                  value={animalSelecionado}
                                  onChange={setAnimalSelecionado}
                                  options={optionAnimal}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col className="col-6">
                                <Label>Data:</Label>
                                <input
                                  ref={dataAgendamento}
                                  className="form-control"
                                  type="date"
                                  defaultValue={dataSelecionada}
                                  id="dataAgendamento"
                                />
                              </Col>
                              <Col className="col-3">
                                <Label>Hora Inicial:</Label>
                                <input
                                  ref={horaInicial}
                                  className="form-control"
                                  type="time"
                                  step="900"
                                  defaultValue={horaInicialSelecionada}
                                  id="horaInicial"
                                />
                              </Col>
                              <Col className="col-3">
                                <Label>Hora Final:</Label>
                                <input
                                  ref={horaFinal}
                                  className="form-control"
                                  type="time"
                                  step="900"
                                  defaultValue={horaFinalSelecionada}
                                  id="horaFinal"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col className="col-12">
                                <Label>Tipo de Agendamento:</Label>
                                <Select
                                  placeholder="Selecione um tipo de agendamento"
                                  value={tipoAgendamentoSelecionado}
                                  onChange={setTipoAgendamentoSelecionado}
                                  options={optionTipoAgendamento}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col className="col-12">
                                <AvField
                                  name="observacao"
                                  label="Observação"
                                  className="form-control"
                                  placeholder=""
                                  type="textarea"
                                  value={event.title_category}
                                  onChange={(e) => {
                                    textareachange(e);
                                  }}
                                  maxLength="225"
                                  rows="3"
                                  errorMessage="Mensagem não informada"
                                />
                                {textareabadge ? (
                                  <span className="badgecount badge badge-success">
                                    {" "}
                                    {textcount} / 225{" "}
                                  </span>
                                ) : null}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-right">
                                  <button type="button" className="btn btn-light mr-2" onClick={() => setModalEvent(!modalEvent)}>Cancelar</button>
                                  {eventEdit && (
                                    <button type="button" className="btn btn-danger mr-2" onClick={() => setDeleteModal(true)}>Excluir</button>
                                  )}
                                  <button type="submit" className="btn btn-success save-event">Salvar</button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
            
export default Agenda;