import React, { useEffect, useState, useCallback, useRef } from "react";
import queryString from "query-string";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import IntlCurrencyInput from "react-intl-currency-input";
import api from "../../../services/api";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import formatValue from "../../../utils/formatValue";
import getMaskValue from "../../../utils/getMaskValue";
import currencyConfig from "../../../helpers/currency_helper";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import columnsServico from "./datatableColumns";
import "../../../shared/datatables.scss";

const VendaRegistroManual = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [servicos, setServicos] = useState([]);
  const [servicoSelecionado, setServicoSelecionado] = useState("");
  const [servicoDescricao, setServicoDescricao] = useState("");
  const [servicoValor, setServicoValor] = useState(0);

  const [modalExcluir, setModalExcluir] = useState(false);
  const [modalValor, setModalValor] = useState(false);
  const [modalVendaSemCliente, setModalVendaSemCliente] = useState(false);

  const [qrCode, setQrCode] = useState("");
  const [cliente, setCliente] = useState("");
  const [cashbackClienteDisplay, setCashbackClienteDisplay] = useState(
    "R$ 0,00"
  );
  const [cashbackCliente, setCashbackCliente] = useState(0);
  const [cashbackVenda, setCashbackVenda] = useState(0);
  const [valorServico, setValorServico] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);

  const inputQrCode = useRef(null);

  const calculaValorTotal = useCallback(async () => {
    const { total } = servicos.reduce(
      (accumulator, servico) => {
        accumulator.total += parseFloat(getMaskValue(servico.valor));
        return accumulator;
      },
      {
        total: 0,
      }
    );

    setValorServico(total);
    setValorTotal(total - cashbackVenda);
  }, [servicos, cashbackVenda]);

  const handleChangeServicoValor = (event, value, maskedValue) => {
    event.preventDefault();
    setServicoValor(parseFloat(value));
  };

  const handleChangeCashback = useCallback(
    async (event, value, maskedValue) => {
      await event.preventDefault();
      setCashbackVenda(parseFloat(value));

      const { total } = servicos.reduce(
        (accumulator, servico) => {
          accumulator.total += parseFloat(getMaskValue(servico.valor));
          return accumulator;
        },
        {
          total: 0,
        }
      );

      setValorServico(total);
      setValorTotal(total - parseFloat(value));
    },
    [servicos]
  );

  const handleExcluirServico = useCallback(async () => {
    try {
      setModalExcluir(false);

      const auxServicos = servicos.map((servico) => {
        if (servico.servicoId === servicoSelecionado) {
          Object.assign(servico, {
            valor: formatValue(0, "currency"),
            action: (
              <>
                <Button
                  type="button"
                  color="success"
                  className="mr-1 waves-effect waves-light btn-sm"
                  onClick={() => {
                    setServicoSelecionado(servico.servicoId);
                    setServicoDescricao(servico.descricao);
                    setServicoValor(0);
                    setModalValor(true);
                  }}
                >
                  <i
                    className="bx bx-dollar font-size-8"
                    id="alterarTooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="alterarTooltip">
                    Alterar Valor
                  </UncontrolledTooltip>
                </Button>

                <Button
                  type="button"
                  color="danger"
                  className="mr-1 waves-effect waves-light btn-sm"
                  onClick={() => {
                    setServicoSelecionado(servico.servicoId);
                    setServicoDescricao(servico.descricao);
                    setModalExcluir(true);
                  }}
                >
                  <i
                    className="bx bx-eraser font-size-8"
                    id="excluirTooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="excluirTooltip">
                    Zerar Valor
                  </UncontrolledTooltip>
                </Button>
              </>
            ),
          });
          return servico;
        }
        return servico;
      });

      setServicos(auxServicos);
      calculaValorTotal();
    } catch (err) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [servicoSelecionado, servicos, setMessageBox, calculaValorTotal]);

  const handleConfirmarValor = useCallback(async () => {
    try {
      setModalValor(false);

      let auxValor;
      if (servicoValor === "") {
        auxValor = 0;
      } else {
        auxValor = servicoValor;
      }

      const auxServicos = servicos.map((servico) => {
        if (servico.servicoId === servicoSelecionado) {
          Object.assign(servico, {
            valor: formatValue(auxValor, "currency"),
            action: (
              <>
                <Button
                  type="button"
                  color="success"
                  className="mr-1 waves-effect waves-light btn-sm"
                  onClick={() => {
                    setServicoSelecionado(servico.servicoId);
                    setServicoDescricao(servico.descricao);
                    setServicoValor(auxValor);
                    setModalValor(true);
                  }}
                >
                  <i
                    className="bx bx-dollar font-size-8"
                    id="alterarTooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="alterarTooltip">
                    Alterar Valor
                  </UncontrolledTooltip>
                </Button>

                <Button
                  type="button"
                  color="danger"
                  className="mr-1 waves-effect waves-light btn-sm"
                  onClick={() => {
                    setServicoSelecionado(servico.servicoId);
                    setServicoDescricao(servico.descricao);
                    setModalExcluir(true);
                  }}
                >
                  <i
                    className="bx bx-eraser font-size-8"
                    id="excluirTooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="excluirTooltip">
                    Zerar Valor
                  </UncontrolledTooltip>
                </Button>
              </>
            ),
          });
          return servico;
        }
        return servico;
      });

      setServicos(auxServicos);
      calculaValorTotal();
    } catch (err) {
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [
    servicoSelecionado,
    servicoValor,
    servicos,
    setMessageBox,
    calculaValorTotal,
  ]);

  const registrarVenda = useCallback(
    async (valorResgate) => {
      if (valorResgate > 0) {
        if (valorResgate > cashbackCliente) {
          await setMessageBox({
            show: true,
            title: "Venda Inválida",
            description: "Valor de Cashback maior que o saldo disponível!",
            error: true,
          });
          return;
        }
      }

      setAguarde(true);

      const auxItens = servicos.filter((servico) => {
        return parseFloat(getMaskValue(servico.valor)) > 0;
      });

      const itens = auxItens.map((servico) => {
        return {
          servicoId: servico.servicoId,
          quantidade: 1,
          valorUnitario: parseFloat(getMaskValue(servico.valor)),
        };
      });

      let regVenda;

      try {
        if (qrCode === "") {
          regVenda = { itens, valorResgate: 0 };
        } else {
          regVenda = { itens, qrCode, valorResgate };
        }

        const response = await api.post("web/venda/registrar", regVenda);

        if (!response.data.success) {
          throw response.data;
        }

        const newValue = servicos.map((servico) => {
          Object.assign(servico, {
            valor: "R$ 0,00",
          });

          return servico;
        });

        setServicos(newValue);

        setCliente("");
        setCashbackClienteDisplay("R$ 0,00");
        setCashbackCliente(0);
        setValorServico(0);
        setValorTotal(0);
        setCashbackVenda(0);

        setAguarde(false);

        await setMessageBox({
          show: true,
          title: "Sucesso",
          description: response.data.message,
          success: true,
        });
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Venda Inválida",
          description: err.message,
          error: true,
        });
      }
    },
    [setAguarde, servicos, cashbackCliente, setMessageBox, qrCode]
  );

  const handleRegistrarVenda = useCallback(async () => {
    if (valorServico > 0) {
      if (valorTotal > 0) {
        if (qrCode === "") {
          setModalVendaSemCliente(true);
          return;
        } else {
          registrarVenda(cashbackVenda);
        }
      } else {
        await setMessageBox({
          show: true,
          title: "Venda Inválida",
          description: "Valor de Cashback maior que o total de serviços!",
          error: true,
        });
      }
    } else {
      await setMessageBox({
        show: true,
        title: "Venda Inválida",
        description: "Nenhum serviço informado!",
        error: true,
      });
    }
  }, [
    valorServico,
    valorTotal,
    qrCode,
    registrarVenda,
    cashbackVenda,
    setMessageBox,
  ]);

  useEffect(() => {
    async function getServicos() {
      try {
        setAguarde(true);
        const values = queryString.parse(props.location.search);

        if (values.cpf) {
          const cliente = await api.get(
            `web/carteiradigital/cpf/${values.cpf}`
          );

          if (!cliente.data.success) {
            throw cliente.data;
          }

          const { saldoDisponivel, clienteNome, qrCode } = cliente.data.data;
          setCashbackClienteDisplay(formatValue(saldoDisponivel, "currency"));
          setCashbackCliente(parseFloat(saldoDisponivel));
          //setCashbackVenda(parseFloat(saldoDisponivel));
          setCashbackVenda(0);

          if (qrCode) {
            setQrCode(qrCode);
          }

          setCliente(clienteNome);
        }

        const response = await api.get("/web/servico/ativos");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const responseServico = response.data.data.map((servico) => {
          return {
            ...servico,
            valor: formatValue(0, "currency"),
            action: (
              <>
                <Button
                  type="button"
                  color="success"
                  className="mr-1 waves-effect waves-light btn-sm"
                  onClick={() => {
                    setServicoSelecionado(servico.servicoId);
                    setServicoDescricao(servico.descricao);
                    setServicoValor(0);
                    setModalValor(true);
                  }}
                >
                  <i
                    className="bx bx-dollar font-size-8"
                    id="alterarTooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="alterarTooltip">
                    Alterar Valor
                  </UncontrolledTooltip>
                </Button>

                <Button
                  type="button"
                  color="danger"
                  className="mr-1 waves-effect waves-light btn-sm"
                  onClick={() => {
                    setServicoSelecionado(servico.servicoId);
                    setServicoDescricao(servico.descricao);
                    setModalExcluir(true);
                  }}
                >
                  <i
                    className="bx bx-eraser font-size-8"
                    id="excluirTooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="excluirTooltip">
                    Zerar Valor
                  </UncontrolledTooltip>
                </Button>
              </>
            ),
          };
        });
        setServicos(responseServico);
        setValorServico(0);
        setValorTotal(0);
      } catch (err) {
        setServicos([]);
        setValorServico(0);
        setValorTotal(0);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getServicos();
  }, [props.location.search, servicos.servicoId, setAguarde, setMessageBox]);

  const consultarCliente = useCallback(async () => {
    try {
      if (inputQrCode.current.value === "") {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: "QrCode ou CPF não informado",
          error: true,
        });
        return;
      }

      setAguarde(true);

      let response;
      if (inputQrCode.current.value.length === 11) {
        response = await api.get(
          `/web/carteiradigital/cpf/${inputQrCode.current.value}`
        );
      } else {
        response = await api.patch("/web/carteiradigital/qrcode", {
          qrCode: inputQrCode.current.value,
        });
      }

      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const { saldoDisponivel, clienteNome, qrCode } = response.data.data;
      setCashbackClienteDisplay(formatValue(saldoDisponivel, "currency"));
      setCashbackCliente(parseFloat(saldoDisponivel));
      //setCashbackVenda(parseFloat(saldoDisponivel));
      setCashbackVenda(0);

      if (qrCode) {
        setQrCode(qrCode);
      } else {
        setQrCode(inputQrCode.current.value);
      }

      setCliente(clienteNome);
    } catch (err) {
      setAguarde(false);
      setCashbackClienteDisplay("R$ 0,00");
      setCashbackCliente(0);
      setCashbackVenda(0);
      setQrCode("");
      setCliente("");

      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    } finally {
      calculaValorTotal();
    }
  }, [setAguarde, setMessageBox, calculaValorTotal]);

  const limparCliente = useCallback(async () => {
    try {
      inputQrCode.current.value = "";
      setCashbackClienteDisplay("R$ 0,00");
      setCashbackCliente(0);
      setCashbackVenda(0);
      setQrCode("");
      setCliente("");
    } catch (err) {
      return;
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Registrar Vendas" breadcrumbItem="Venda" />

          {modalExcluir && (
            <SweetAlert
              title="Deseja zerar o valor deste serviço?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Sim"
              cancelBtnBsStyle="danger"
              cancelBtnText="Não"
              onConfirm={handleExcluirServico}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          )}

          {modalVendaSemCliente && (
            <SweetAlert
              title="Cliente não Identificado."
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Sim"
              cancelBtnBsStyle="danger"
              cancelBtnText="Não"
              onConfirm={() => {
                setModalVendaSemCliente(false);
                registrarVenda(cashbackVenda);
              }}
              onCancel={() => {
                setModalVendaSemCliente(false);
              }}
            >
              Deseja realmente registrar a venda?
            </SweetAlert>
          )}

          {modalValor && (
            <SweetAlert
              showCancel
              title={servicoDescricao}
              cancelBtnBsStyle="danger"
              confirmBtnBsStyle="success"
              confirmBtnText="Confirmar"
              cancelBtnText="Cancelar"
              onConfirm={handleConfirmarValor}
              onCancel={() => {
                setModalValor(false);
              }}
            >
              <IntlCurrencyInput
                currency="BRL"
                config={currencyConfig}
                value={parseFloat(servicoValor)}
                max={9999.99}
                onChange={handleChangeServicoValor}
                className="form-control"
              />
            </SweetAlert>
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <Label>QrCode ou CPF do Cliente</Label>
                          <div className="input-group">
                            <div className="input-group-prepend"></div>
                            <input
                              ref={inputQrCode}
                              className="form-control"
                              type="text"
                              id="qrCodeCliente"
                            />
                            <span
                              onClick={consultarCliente}
                              className="input-group-text bx bx-search"
                              style={{ cursor: "pointer" }}
                            ></span>
                            <span
                              onClick={limparCliente}
                              className="input-group-text bx bx-eraser"
                              style={{ cursor: "pointer" }}
                            ></span>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="9">
                        <FormGroup>
                          <Label>Nome do Cliente</Label>
                          <input
                            className="form-control"
                            value={cliente}
                            type="text"
                            id="nomeCliente"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Saldo Cashback</Label>
                          <input
                            className="form-control"
                            value={cashbackClienteDisplay}
                            type="text"
                            id="cashbackCliente"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    fixed
                    hover
                    striped
                    bordered
                    responsive
                    responsiveSm
                    responsiveMd
                    responsiveLg
                    responsiveXl
                    small
                    displayEntries={false}
                    searching={false}
                    paging={false}
                    noBottomColumns={true}
                    data={columnsServico(servicos)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Cashback Utilizado</Label>
                          <IntlCurrencyInput
                            currency="BRL"
                            value={parseFloat(cashbackVenda)}
                            config={currencyConfig}
                            max={9999.99}
                            onChange={handleChangeCashback}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Valor Total de Serviços</Label>
                          <IntlCurrencyInput
                            currency="BRL"
                            value={valorServico}
                            config={currencyConfig}
                            max={9999.99}
                            className="form-control"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Valor da Venda</Label>
                          <IntlCurrencyInput
                            currency="BRL"
                            value={valorTotal}
                            config={currencyConfig}
                            max={9999.99}
                            className="form-control"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label></Label>
                          <div className="text-sm-right">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded mr-1 waves-effect waves-light"
                              onClick={handleRegistrarVenda}
                            >
                              Registrar Venda
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VendaRegistroManual;
