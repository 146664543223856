import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import IntlNumberInput from "react-intl-number-input";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../components/ActionButton";
import api from "../../../services/api";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const CategoriaAlterarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const inputAliquota = useRef(null);

  const [aliquotaCashback, setAliquotaCashback] = useState(0);

  const [registro, setRegistro] = useState([]);
  const [categoriaId, setCategoriaId] = useState("");
  const history = useHistory();

  const handleAliquotaCashback = useCallback(
    async (event, value, maskedValue) => {
      await event.preventDefault();
      setAliquotaCashback(parseFloat(value));
    },
    []
  );

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        setAguarde(true);
        const response = await api.patch(`/web/categoria/${categoriaId}`, {
          descricao: values.descricao,
          aliquotaCashback,
        });
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        history.push("/cadastros/categoria/listar");
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [aliquotaCashback, categoriaId, history, setAguarde, setMessageBox]
  );

  useEffect(() => {
    async function getCategoria() {
      try {
        const values = queryString.parse(props.location.search);
        setCategoriaId(values.id);

        setAguarde(true);
        const response = await api.get(`/web/categoria/${values.id}`);
        setAguarde(false);

        if (response.data.success) {
          const categoria = response.data.data;

          Object.assign(categoria, {
            dataCadastro: format(
              parseISO(categoria.dataCadastro),
              "dd/MM/yyyy hh:mm"
            ),
            dataAlteracao: format(
              parseISO(categoria.dataAlteracao),
              "dd/MM/yyyy hh:mm"
            ),
          });

          setAliquotaCashback(categoria.aliquotaCashback);

          setRegistro(categoria);
        } else {
          setRegistro([]);
        }
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCategoria();
  }, [props.location.search, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações de Categoria - Edição" breadcrumbItem="Categoria" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="descricao"
                            label="Descrição"
                            value={registro.descricao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Descrição não informada"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup>
                          <Label>Aliquota de Cashback (%)</Label>
                          <IntlNumberInput
                            ref={inputAliquota}
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(aliquotaCashback)}
                            onChange={handleAliquotaCashback}
                            className="form-control"
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data do Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data da Última Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategoriaAlterarPage;
