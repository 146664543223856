import React, { useEffect, useState, useCallback } from "react";
import { format, parseISO } from "date-fns";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import formatValue from "../../../utils/formatValue";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import DataTableDefault from "../../../components/DataTable/DataTableDefault";
import DeleteBox from "../../../components/Common/DeleteBox";
import columnsCategoria from "./datatableColumns";

const CategoriaListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [categorias, setCategorias] = useState([]);

  const excluirCategoria = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(
        `/web/categoria/${categoriaSelecionada}`
      );

      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const auxCategorias = categorias.filter(
        (categoria) => categoria.categoriaId !== categoriaSelecionada
      );

      setCategorias(auxCategorias);

      await setMessageBox({
        show: true,
        title: "Sucesso",
        description: "Categoria excluída com sucesso.",
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [categoriaSelecionada, categorias, setAguarde, setMessageBox]);

  useEffect(() => {
    async function getCategoria() {
      try {
        setAguarde(true);
        const response = await api.get("/web/categoria");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const listaCategoria = response.data.data.map((categorias) => {
          return {
            ...categorias,
            aliquotaCashback: formatValue(
              categorias.aliquotaCashback,
              "percent"
            ),
            dataAlteracao: format(
              parseISO(categorias.dataAlteracao),
              "dd/MM/yyyy hh:mm"
            ),
            action: (
              <>
                <Link
                  to={`/cadastros/categoria/exibir?id=${categorias.categoriaId}`}
                  className="mr-1 text-info"
                >
                  <i
                    className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                    id="exibirTooltip"
                  ></i>
                </Link>

                <Link
                  to={`/cadastros/categoria/alterar?id=${categorias.categoriaId}`}
                  className="mr-1 text-warning"
                >
                  <i
                    className="bx bx-edit-alt font-size-18 align-middle mr-1"
                    id="alterarTooltip"
                  ></i>
                </Link>

                <Link
                  onClick={() => {
                    setCategoriaSelecionada(categorias.categoriaId);
                    setModalExcluir(true);
                  }}
                  to={() => {}}
                  className="mr-1 text-danger"
                >
                  <i
                    className="bx bx-trash font-size-18 align-middle mr-1"
                    id="excluirTooltip"
                  ></i>
                </Link>
              </>
            ),
          };
        });
        setCategorias(listaCategoria);
      } catch (err) {
        setCategorias([]);
        setAguarde(false);

        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCategoria();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Lista de Categorias" breadcrumbItem="Categoria" />

          <DeleteBox
            title="Deseja realmente excluir esta categoria?"
            show={modalExcluir}
            onDeleteClick={excluirCategoria}
            onCloseClick={() => setModalExcluir(false)}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/categoria/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsCategoria(categorias)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CategoriaListarPage;
