import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ShowToast = (message, success = true, title = "Atenção") => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 0,
    closeButton: true,
    debug: false,
    progressBar: true,
    preventDuplicates: false,
    newestOnTop: false,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 800,
  };

  if (success) {
    toastr.success(message, title);
  } else {
    title = title !== "Atenção" ? title : "Falhou";
    toastr.error(message, title);
  }
};

export default ShowToast;
