const columnsEspecializacao = (especializacao) => {
  return {
    columns: [
      {
        label: "#",
        field: "especializacaoId",
        sort: "asc",
      },
      {
        label: "Descrição",
        field: "descricao",
        sort: "asc",
      },
      {
        label: "Data do Cadastro",
        field: "dataCadastro",
        sort: "asc",
      },
      {
        label: "Última Alteração",
        field: "dataUltimaAlteracao",
        sort: "asc",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: especializacao,
  };
};

export default columnsEspecializacao;
