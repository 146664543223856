import React, { useEffect, useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { format, parseISO } from "date-fns";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import formatValue from "../../../utils/formatValue";
import DataTableDefault from "../../../components/DataTable/DataTableDefault";
import columnsServico from "./datatableColumns";
import enumAgendamentoServico from "../../../helpers/enums/enumAgendamentoServico";

const ServicoListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [servicoSelecionado, setServicoSelecionado] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [servicos, setServicos] = useState([]);
  /*
      rows: [
      {
          firstName: 'value column',
          lastName: 'value column 2',
          clickEvent: () => this.handleClick(params)
      }
  */
  const excluirServico = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(`/web/servico/${servicoSelecionado}`);
      setAguarde(false);

      if (!response.data.success) {
        throw response.data;
      }

      const auxServicos = servicos.filter(
        (servico) => servico.servicoId !== servicoSelecionado
      );

      setServicos(auxServicos);

      await setMessageBox({
        show: true,
        title: "Sucesso",
        description: "Serviço excluído com sucesso.",
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [servicoSelecionado, servicos, setAguarde, setMessageBox]);

  useEffect(() => {
    async function getServico() {
      try {
        setAguarde(true);
        const response = await api.get("/web/servico");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const listaServico = response.data.data.map((servicos) => {
          return {
            ...servicos,
            corDescricao: (
              <div className="text-center">
                <span className={`badge ${servicos.corClassName} font-size-11`}>
                  {servicos.corDescricao}
                </span>
              </div>
            ),
            aliquotaCashback: formatValue(servicos.aliquotaCashback, "percent"),
            dataAlteracao: format(
              parseISO(servicos.dataAlteracao),
              "dd/MM/yyyy hh:mm"
            ),
            permitirVenda: servicos.permitirVenda ? (
              <div className="text-center">
                <span className="badge badge-pill badge-soft-success font-size-11">
                  Sim
                </span>
              </div>
            ) : (
              <div className="text-center">
                <span className="badge badge-pill badge-soft-danger font-size-11">
                  Não
                </span>
              </div>
            ),
            agendamento:
              servicos.agendamento ===
              enumAgendamentoServico.PermitidoParaTodos ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-success font-size-11">
                    Todos
                  </span>
                </div>
              ) : servicos.agendamento ===
                enumAgendamentoServico.NaoPermitidoParaClientes ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-warning font-size-11">
                    Exceto Cliente
                  </span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-danger font-size-11">
                    Não
                  </span>
                </div>
              ),
            status:
              servicos.status === 1 ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-success font-size-11">
                    Ativo
                  </span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-danger font-size-11">
                    Inativo
                  </span>
                </div>
              ),
            action: (
              <>
                <Link
                  to={`/cadastros/servico/exibir?id=${servicos.servicoId}`}
                  className="mr-1 text-info"
                >
                  <i
                    className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                    id="exibirTooltip"
                  ></i>
                </Link>

                <Link
                  to={`/cadastros/servico/alterar?id=${servicos.servicoId}`}
                  className="mr-1 text-warning"
                >
                  <i
                    className="bx bx-edit-alt font-size-18 align-middle mr-1"
                    id="alterarTooltip"
                  ></i>
                </Link>

                <Link
                  onClick={() => {
                    setServicoSelecionado(servicos.servicoId);
                    setModalExcluir(true);
                  }}
                  to={() => {}}
                  className="mr-1 text-danger"
                >
                  <i
                    className="bx bx-trash font-size-18 align-middle mr-1"
                    id="excluirTooltip"
                  ></i>
                </Link>
              </>
            ),
          };
        });
        setServicos(listaServico);
      } catch (err) {
        setServicos([]);
        setAguarde(false);

        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getServico();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Lista de Serviços" breadcrumbItem="Serviço" />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir este serviço?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirServico}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/servico/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsServico(servicos)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServicoListarPage;
