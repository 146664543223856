import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";

import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  Label,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../components/ActionButton";
import api from "../../../services/api";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const UsuarioExibirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const history = useHistory();

  const [usuarioId, setUsuarioId] = useState("");
  const [registro, setRegistro] = useState([]);

  const [comboPerfil] = useState([
    { label: "Administrador", value: 1 },
    { label: "Sistema", value: 2 },
    { label: "Cliente", value: 3 },
    { label: "Vendedor", value: 4 },
    { label: "Profissional", value: 5 },
  ]);
  const [perfilSelecionado, setPerfilSelecionado] = useState([]);

  const [comboStatus] = useState([
    { label: "Ativo", value: 1 },
    { label: "Inativo", value: 2 },
  ]);

  const [statusSelecionado, setStatusSelecionado] = useState([]);

  const optionPerfil = [
    {
      options: comboPerfil,
    },
  ];

  const optionStatus = [
    {
      options: comboStatus,
    },
  ];

  useEffect(() => {
    async function getUsuario() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        setUsuarioId(values.id);

        const response = await api.get(`/web/usuario/${values.id}`);
        if (!response.data.success) {
          throw response.data;
        }

        const usuario = response.data.data;
        Object.assign(usuario, {
          dataCadastro: format(
            parseISO(usuario.dataCadastro),
            "dd/MM/yyyy HH:mm"
          ),
          dataAlteracao: format(
            parseISO(usuario.dataAlteracao),
            "dd/MM/yyyy HH:mm"
          ),
          dataUltimoAcesso: usuario.dataUltimoAcesso
            ? format(parseISO(usuario.dataUltimoAcesso), "dd/MM/yyyy HH:mm")
            : "",
        });

        const auxPerfil = comboPerfil.filter(
          (perfil) => perfil.value === usuario.perfilId
        );
        setPerfilSelecionado(auxPerfil[0]);

        const auxStatus = comboStatus.filter(
          (status) => status.value === usuario.status
        );
        setStatusSelecionado(auxStatus[0]);
        setRegistro(usuario);

        setAguarde(false);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getUsuario();
  }, [
    optionStatus.options,
    props.location.search,
    setAguarde,
    comboPerfil,
    comboStatus,
    setMessageBox,
  ]);

  const handleSubmit = useCallback(
    async (_event, values) => {
      try {
        setAguarde(true);
        const response = await api.patch(`/web/usuario/${usuarioId}`, {
          nome: values.nome,
          email: values.email,
          perfilId: perfilSelecionado.value,
          status: statusSelecionado.value,
        });

        if (!response.data.success) {
          throw response.data;
        }
        setAguarde(false);

        history.push("/cadastros/usuario/listar");
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [
      setAguarde,
      usuarioId,
      perfilSelecionado.value,
      statusSelecionado.value,
      history,
      setMessageBox,
    ]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações do Usuário - Edição" breadcrumbItem="Usuário" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <AvField
                            name="nome"
                            label="Nome"
                            value={registro.nome}
                            className="form-control"
                            placeholder=""
                            type="text"
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Nome não informado"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="4">
                        <FormGroup>
                          <AvField
                            name="email"
                            label="Email"
                            value={registro.email}
                            className="form-control"
                            placeholder=""
                            type="text"
                            autoComplete="off"
                            errorMessage="Email inválido"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="2">
                        <FormGroup>
                          <Label>Perfil</Label>
                          <Select
                            placeholder="Perfil"
                            value={perfilSelecionado}
                            onChange={setPerfilSelecionado}
                            options={optionPerfil}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="2">
                        <FormGroup>
                          <Label>Status</Label>
                          <Select
                            placeholder="Status"
                            value={statusSelecionado}
                            onChange={setStatusSelecionado}
                            options={optionStatus}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <AvField
                            name="dataUltimoAcesso"
                            label="Data do Último Acesso"
                            value={registro.dataUltimoAcesso}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data do Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="4">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data da Última Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsuarioExibirPage;
