import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import api from "../../../../services/api";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import columnsTipoAnimal from "./datatableColumns";

const AnimalTipoAnimalListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [tipoAnimal, setTipoAnimal] = useState([]);

  useEffect(() => {
    async function getTipoAnimal() {
      try {
        setAguarde(true);
        const response = await api.get("/web/tipoanimal");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const listaTipoAnimal = response.data.data.map((tipoAnimal) => {
          return {
            ...tipoAnimal,
            action: (
              <>
                <Link
                  to={`/cadastros/animal/tipoanimal/exibir?id=${tipoAnimal.tipoAnimalId}`}
                  className="mr-3 text-info"
                >
                  <i
                    className="mdi mdi-binoculars font-size-18 align-middle mr-2"
                    id="exibirTooltip"
                  ></i>
                </Link>
              </>
            ),
          };
        });
        setTipoAnimal(listaTipoAnimal);
      } catch (err) {
        setTipoAnimal([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getTipoAnimal();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Lista de Tipo de Animal" breadcrumbItem="Tipo de Animal" />
          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsTipoAnimal(tipoAnimal)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AnimalTipoAnimalListarPage;
