import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  container,
} from "reactstrap";

import Editable from "react-bootstrap-editable";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import api from "../../../services/api";

import { useAguarde } from "../../../contexts/globalContext";

const FormXeditable = (props) => {
  const { setAguarde } = useAguarde();
  const [configuracoes, setConfiguracoes] = useState(null);

  useEffect(() => {
    setAguarde(true);
    api
      .get("/web/configuracao")
      .then((response) => {
        setAguarde(false);
        if (response.data.success) {
          setConfiguracoes(response.data.data);
        } else {
          setConfiguracoes(null);
        }
      });
  }, [setAguarde]);


  return (
    <React.Fragment>
      <div className="page-content">
        <container fluid={true}>
          <Breadcrumbs
            title="Configurações do Sistema"
            breadcrumbItem="Personalizações"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Definição de Regras de Negócio</CardTitle>
                  <div className="table-responsive">
                    <Table responsive striped className="mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: "50%" }}>Seção</th>
                          <th>Chave</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {configuracoes && configuracoes.map((configuracao) => {
                          return(
                            <tr>
                              <td>{configuracao.secao}</td>
                              <td>{configuracao.chave}</td>
                              <td>
                                <Editable
                                  initialValue={configuracao.valor }
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  id={null}
                                  isValueClickable={true}
                                  label={null}
                                  mode="inline"
                                  onSubmit={null}
                                  onValidated={null}
                                  placement="top"
                                  showText
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>
                          )}
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </container>
      </div>
    </React.Fragment>
  );
};

export default FormXeditable;
