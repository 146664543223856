import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  CardBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../../components/ActionButton";
import api from "../../../../services/api";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import { maskCPF, maskPhone } from "../../../../utils/mask";
import extractNumber from "../../../../utils/extractNumber";

const ProfissionalIncluirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();
  
  const dataNascimento = useRef(null);
  
  const [cpf, setCpf] = useState('');
  const [celular, setCelular] = useState('');

  const [generos, setGeneros] = useState([]);
  const [generoSelecionado, setGeneroSelecionado] = useState([]);

  const [especializacoesProfissional, setEspecializacoesProfissional] = useState([]);
  const [especializacoes, setEspecializacoes] = useState([]);
  const [textareabadge, settextareabadge] = useState(0);
  const [textcount, settextcount] = useState(0);

  const history = useHistory();

  const optionEspecializacao = [
    {
      options: especializacoes,
    },
  ];
  
  const optionGenero = [
    {
      options: generos,
    },
  ];

  function textareachange(event) {
    var count = event.target.value.length;
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(event.target.value.length);
  }

  function handleSelecionarEspecializacao(selectedMulti) { setEspecializacoesProfissional(selectedMulti); }

  useEffect(() => {
    async function getEspecializacao() {
      try {
        setAguarde(true);

        const responseEspecializacao = await api.get("/web/especializacao");
        if (responseEspecializacao.data.success) {
          const listaEspecializacao = responseEspecializacao.data.data.map((especializacao) => {
            return {
              label: especializacao.descricao,
              value: especializacao.especializacaoId,
            };
          });

          setEspecializacoes(listaEspecializacao);
        } else {
          setEspecializacoes([]);
        }

        setAguarde(false);

      } catch (err) {
        setEspecializacoes([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getEspecializacao();

    setGeneros([
      {
        label: "Homem",
        value: "m",
      },
      {
        label: "Mulher",
        value: "f",
      },
      {
        label: "Outros",
        value: "o",
      },
    ]);
  }, [setAguarde, setMessageBox]);

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        if (!generoSelecionado.value) {
          await setMessageBox({
            show: true,
            title: "Atenção",
            description: "Gênero não informado",
            warning: true,
          });
          return;
        }

        if (dataNascimento.current.value === "") {
          await setMessageBox({
            show: true,
            title: "Erro",
            description: "Data Nascimento não informada",
            error: true,
          });
          return;
        }

        let especializacaoProfissionalLista = [];
        if (especializacoesProfissional !== null) {
          especializacaoProfissionalLista = especializacoesProfissional.map((especializacao) => {
            return especializacao.value
          })
        } 

        setAguarde(true);
        const response = await api.post("/web/profissional", {
          profissionalNome: values.nome,
          profissionalCpf: extractNumber(values.cpf),
          profissionalGenero: generoSelecionado.value,
          profissionalCelular: extractNumber(values.celular),
          profissionalDataNascimento: dataNascimento.current.value,	  
          profissionalTipoProfissional: 2,
          profissionalBiografia: values.biografia,
          usuarioNome: values.nome,
          usuarioEmail: values.email,
          especializacaoProfissional: especializacaoProfissionalLista
        });

        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        history.push("/cadastros/profissional/listar");
        
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [especializacoesProfissional, generoSelecionado.value, history, setAguarde, setMessageBox]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações do Profissional - Inclusão"
            breadcrumbItem="Profissional"
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <FormGroup className="mb-2" row>
                      <Label
                        htmlFor="nome"
                        md="2"
                        className="col-form-label"
                      >
                        Nome
                      </Label>
                      <Col md="10">
                        <AvField
                          name="nome"
                          className="form-control"
                          placeholder=""
                          type="text"
                          required
                          autoFocus={true}
                          autoComplete="off"
                          errorMessage="Nome não informado"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="genero"
                        md="2"
                        className="col-form-label"
                      >
                        Gênero
                      </Label>
                      <Col md="10">
                        <Select
                          placeholder="Selecione um Gênero"
                          value={generoSelecionado}
                          onChange={setGeneroSelecionado}
                          options={optionGenero}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-2" row>
                      <Label
                        htmlFor="cpf"
                        md="2"
                        className="col-form-label"
                      >
                        CPF
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="cpf"
                          className="form-control"
                          placeholder=""
                          type="text"
                          required
                          value={cpf}
                          onChange={(text) => setCpf(maskCPF(text.target.value))}
                          autoComplete="off"
                          errorMessage="CPF não informado"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-2" row>
                      <Label
                        htmlFor="celular"
                        md="2"
                        className="col-form-label"
                      >
                        Celular
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="celular"
                          className="form-control"
                          placeholder=""
                          type="text"
                          required
                          value={celular}
                          onChange={(text) => {
                              if(text.target.value.length < 15) 
                                setCelular(maskPhone(text.target.value));
                            }
                          }
                          autoComplete="off"
                          errorMessage="Celular não informado"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-2" row>
                      <Label
                        htmlFor="email"
                        md="2"
                        className="col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="email"
                          className="form-control"
                          placeholder=""
                          type="text"
                          required
                          autoComplete="off"
                          errorMessage="Email não informado"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="dataNascimento"
                        md="2"
                        className="col-form-label"
                      >
                        Nascimento
                      </Label>
                      <Col md={10}>
                        <input
                          ref={dataNascimento}
                          className="form-control"
                          id="dataNascimento"
                          type="date"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="especializacao"
                        md="2"
                        className="col-form-label"
                      >
                        Especializações
                      </Label>
                      <Col md={10}>
                        <Select
                          value={especializacoesProfissional}
                          isMulti={true}
                          onChange={handleSelecionarEspecializacao}
                          options={optionEspecializacao}
                          classNamePrefix="select2-selection"
                          placeholder="Nenhuma Especialização"
                          noOptionsMessage={() => "Nenhuma Especialização"} 
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="biografia"
                        md="2"
                        className="col-form-label"
                      >
                        Biografia
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="biografia"
                          className="form-control"
                          placeholder=""
                          type="textarea"
                          onChange={(e) => {
                            textareachange(e);
                          }}
                          maxLength="225"
                          rows="3"
                        />
                        {textareabadge ? (
                          <span className="badgecount badge badge-success">
                            {" "}
                            {textcount} / 225{" "}
                          </span>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProfissionalIncluirPage;
