import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import IntlNumberInput from "react-intl-number-input";
import Select from "react-select";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../components/ActionButton";
import api from "../../../services/api";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const FaixaRetornoAlterarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const history = useHistory();

  const [valorInicial, setValorInicial] = useState(0);
  const [valorFinal, setValorFinal] = useState(0);
  const [aliquotaCashback, setAliquotaCashback] = useState(0);

  const inputValorInicial = useRef(null);
  const inputValorFinal = useRef(null);
  const inputAliquotaCashback = useRef(null);

  const handleValorInicial = useCallback(async (event, value, maskedValue) => {
    await event.preventDefault();
    setValorInicial(parseFloat(value));
  }, []);

  const handleValorFinal = useCallback(async (event, value, maskedValue) => {
    await event.preventDefault();
    setValorFinal(parseFloat(value));
  }, []);

  const handleAliquotaCashback = useCallback(
    async (event, value, maskedValue) => {
      await event.preventDefault();
      setAliquotaCashback(parseFloat(value));
    },
    []
  );

  const [registro, setRegistro] = useState([]);
  const [servicoId, setServicoId] = useState("");

  const [status] = useState([
    { label: "Ativo", value: 1 },
    { label: "Inativo", value: 2 },
  ]);
  const [statusSelecionado, setStatusSelecionado] = useState([]);

  const optionStatus = [
    {
      options: status,
    },
  ];

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        if (statusSelecionado.value) {
          setAguarde(true);
          const response = await api.patch(
            `/web/faixavalorcashback/${servicoId}`,
            {
              valorInicial: inputValorInicial.current.state.value,
              valorFinal: inputValorFinal.current.state.value,
              aliquotaCashback: inputAliquotaCashback.current.state.value,
              status: statusSelecionado.value,
            }
          );
          setAguarde(false);

          if (!response.data.success) {
            throw response.data;
          }

          history.push("/cadastros/faixaretorno/listar");
        }
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [history, servicoId, setAguarde, setMessageBox, statusSelecionado.value]
  );

  useEffect(() => {
    async function getFaixa() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        setServicoId(values.id);

        const response = await api.get(`/web/faixavalorcashback/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const faixa = response.data.data;

        Object.assign(faixa, {
          dataCadastro: format(
            parseISO(faixa.dataCadastro),
            "dd/MM/yyyy hh:mm"
          ),
          dataAlteracao: format(
            parseISO(faixa.dataAlteracao),
            "dd/MM/yyyy hh:mm"
          ),
        });

        const auxStatus = status.filter(
          (status) => status.value === faixa.status
        );

        setValorInicial(faixa.valorInicial);
        setValorFinal(faixa.valorFinal);
        setAliquotaCashback(faixa.aliquotaCashback);
        setStatusSelecionado(auxStatus[0]);

        setRegistro(faixa);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getFaixa();
  }, [
    optionStatus.options,
    props.location.search,
    setAguarde,
    setMessageBox,
    status,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações de Faixa - Edição" breadcrumbItem="Faixa" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Valor Inicial (R$)</Label>
                          <IntlNumberInput
                            ref={inputValorInicial}
                            prefix="R$ "
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(valorInicial)}
                            onChange={handleValorInicial}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Valor Final (R$)</Label>
                          <IntlNumberInput
                            ref={inputValorFinal}
                            prefix="R$ "
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(valorFinal)}
                            onChange={handleValorFinal}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Aliquota de Cashback (%)</Label>
                          <IntlNumberInput
                            ref={inputAliquotaCashback}
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(aliquotaCashback)}
                            onChange={handleAliquotaCashback}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Status</Label>
                          <Select
                            placeholder="Selecione um Status"
                            value={statusSelecionado}
                            onChange={setStatusSelecionado}
                            options={optionStatus}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data do Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data da Última Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FaixaRetornoAlterarPage;
