import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { VoltarButton } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

const EmpresaRedeSocialAlterarPage = (props) => {
  const history = useHistory();

  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);

  const [tiposAnimal, setTiposAnimal] = useState([]);
  const [tipoAnimalSelecionado, setTipoAnimalSelecionado] = useState([]);

  const optionTipoAnimal = [
    {
      options: tiposAnimal,
    },
  ];

  useEffect(() => {
    async function getTipoAnimal() {
      try {
        const response = await api.get("/web/tipoanimal");
        if (response.data.success) {
          const listaTipoAnimal = response.data.data.map((tipoAnimal) => {
            return {
              label: tipoAnimal.descricao,
              value: tipoAnimal.tipoAnimalId,
            };
          });

          setTiposAnimal(listaTipoAnimal);
        } else {
          setTiposAnimal([]);
        }
      } catch (err) {
        setTiposAnimal([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getTipoAnimal();
  }, [setAguarde, setMessageBox]);

  useEffect(() => {
    async function getRacaAnimal() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        const response = await api.get(`/web/racaanimal/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const listaRacaAnimal = response.data.data;

        const auxTipoAnimal = tiposAnimal.filter(
          (racaAnimal) => racaAnimal.value === listaRacaAnimal.tipoAnimalId
        );

        if (auxTipoAnimal[0]) {
          setTipoAnimalSelecionado(auxTipoAnimal[0]);
        }

        setRegistro(listaRacaAnimal);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getRacaAnimal();
  }, [props.location.search, setAguarde, setMessageBox, tiposAnimal]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações de Raça - Visualização" breadcrumbItem="Raça" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm className="form-horizontal">
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label>Tipo de Animal</Label>
                          <Select
                            placeholder="Selecione uma tipo de animal"
                            value={tipoAnimalSelecionado}
                            onChange={setTipoAnimalSelecionado}
                            options={optionTipoAnimal}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="descericao"
                            label="Descrição"
                            value={registro.descricao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmpresaRedeSocialAlterarPage;
