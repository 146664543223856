import React from "react";
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation-safe";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logo from "../../assets/images/logo.png";

import { useGlobal } from "../../contexts/globalContext";

const RecuperarSenha = (props) => {
  const { global } = useGlobal();

  function handleValidSubmit(event, values) {
    props.userForgetPassword(values, props.history);
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages my-5">
        <Container>
          <div className="text-center">
            <h2>{global.ambiente}</h2>
          </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4"></div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="profile-user-wid mb-40">
                        <span className="avatar-title rounded-circle bg-transparent">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="120"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal mt-40"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Informe seu email"
                          type="email"
                          required
                          errorMessage="Email inválido"
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Recuperar Senha
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-50 text-center">
                <p>
                  Voltar para o{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  <Link
                    to={`/`}
                    onClick={(event) => {
                      event.preventDefault();
                      window.open('https://catec.inf.br/', '_blank')
                    }}
                    style={{ color: '#495057' }}
                  >
                    © {new Date().getFullYear()} {global.fabricante}
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(RecuperarSenha)
);
