const columnsUsuario = (usuarios) => {
  return {
    columns: [
      {
        label: "#",
        field: "usuarioId",
        sort: "asc",
      },

      {
        label: "Nome",
        field: "nome",
        sort: "asc",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "Perfil",
        field: "perfilDescricao",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Último Acesso",
        field: "dataUltimoAcesso",
      },
      {
        label: "Último Login",
        field: "dataUltimoLogin",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: usuarios,
  };
};

export { columnsUsuario };
