import React from "react";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// import { useAuth } from '../../hooks/auth';
// import { useToast } from '../../hooks/toast';

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation-safe";

import { loginUser, apiError } from "../../store/actions";

// import images
import logo from "../../assets/images/logo.png";
import logoAppStore from "../../assets/images/logo-appstore.png";
import logoGooglePlay from "../../assets/images/logo-googleplay.png";
import { useGlobal } from "../../contexts/globalContext";

const Login = (props) => {
  const { global } = useGlobal();
  // const formRef = useRef(null);

  // const { signIn } = useAuth();
  // const { addToast } = useToast();

  // const history = useHistory();

  async function handleValidSubmit(event, values) {
    try {
      props.loginUser(values, props.history);
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages my-5">
        <Container>
          <div className="text-center">
            <h2>{global.ambiente}</h2>
          </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4"></div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className=" profile-user-wid mb-10">
                        <span className="avatar-title rounded-circle bg-transparent">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="120"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Informe seu email"
                          type="email"
                          required
                          errorMessage="Email inválido"
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Senha"
                          value=""
                          type="password"
                          required
                          placeholder="Informe sua senha"
                          errorMessage="Senha não informada"
                        />
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Entrar
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/recuperar-senha" className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i> Esqueceu sua
                          senha?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-50 text-center">
                <p>
                  <Link
                    to={`/`}
                    onClick={(event) => {
                      event.preventDefault();
                      window.open('https://catec.inf.br/', '_blank')
                    }}
                    style={{ color: '#495057' }}
                  >
                    © {new Date().getFullYear()} {global.fabricante}
                  </Link>
                </p>
              </div>
              
              <div className="mt-50 text-center">
                <Link
                    to={`/`}
                    onClick={(event) => {
                      event.preventDefault();
                      window.open('https://play.google.com/store/apps/details?id=br.inf.catec.cliente.cashback.caoecia', '_blank')
                    }}
                >
                  <img
                    src={logoGooglePlay}
                    alt=""
                    height="30"
                    style={{maringRight: '10px'}}
                  />
                </Link>

                <Link
                  to={`/`}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open('https://apps.apple.com/br/app/c%C3%A3o-cia/id1538746145', '_blank')
                  }}
                >
                  <img
                    src={logoAppStore}
                    alt=""
                    height="30"
                    style={{marginLeft: '10px'}}
                  />
                </Link>
                
                
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
);
