import React, { createContext, useContext, useState } from "react";
import enumTypeMessageBox from "../components/Common/MessageBox/enumTypeMessageBox";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [aguarde, setAguarde] = useState(false);

  const [messageBox, setMessageBox] = useState({
    show: false,
    title: "",
    description: "",
    type: enumTypeMessageBox.SUCCESS,
    handleConfirm: undefined,
  });

  let ambiente = "";
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      ambiente = "DESENVOLVIMENTO";
      break;
    case "staging":
      ambiente = "HOMOLOGAÇÃO";
      break;
    default:
  }

  //if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  //  ambiente = "AMBIENTE DE TESTES";
  //}

  const [global, setGlobal] = useState({
    fabricante: "C@tec Soluções em T.I",
    empresa: "Cão & Cia",
    ambiente,
  });

  return (
    <GlobalContext.Provider
      value={{
        global,
        setGlobal,
        aguarde,
        setAguarde,
        messageBox,
        setMessageBox,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  return useContext(GlobalContext);
};

export const useAguarde = () => {
  return useContext(GlobalContext);
};

export const useMessageBox = () => {
  return useContext(GlobalContext);
};
