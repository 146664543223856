import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import IntlNumberInput from "react-intl-number-input";
import Select from "react-select";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../components/ActionButton";
import api from "../../../services/api";

import { enumSimNao, enumAgendamentoServico } from "../../../helpers/enums";

import { useAguarde, useMessageBox } from "../../../contexts/globalContext";

const ServicoAlterarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const history = useHistory();

  const [aliquotaCashback, setAliquotaCashback] = useState(0);

  const inputAliquotaCashback = useRef(null);

  const [registro, setRegistro] = useState([]);
  const [servicoId, setServicoId] = useState("");

  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState([]);

  const [status, setStatus] = useState([]);
  const [statusSelecionado, setStatusSelecionado] = useState([]);

  const [permitirVenda, setPermitirVenda] = useState([]);
  const [permitirVendaSelecionado, setPermitirVendaSelecionado] = useState([]);

  const [cores, setCores] = useState([]);
  const [corSelecionada, setCorSelecionada] = useState([]);

  const [coresClassName, setCoresClassName] = useState([]);
  const [corClassNameSelecionada, setCorClassNameSelecionada] = useState("");

  const [agendamento, setAgendamento] = useState([]);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState([]);

  const selectStyles = {
    menuList: styles => {
      return {
        ...styles,
        maxHeight: 132
      };
    }
  };

  const optionCategoria = [
    {
      options: categorias,
    },
  ];

  const optionCor = [
    {
      options: cores,
    },
  ];

  const optionPermitirVenda = [
    {
      options: permitirVenda,
    },
  ];

  const optionStatus = [
    {
      options: status,
    },
  ];

  const optionAgendamento = [
    {
      options: agendamento,
    },
  ];

  const handleChangeCor = useCallback(
    async (cor) => {
      setCorSelecionada(cor);

      /*
      if (cor.value === 0) {
        setCorClassNameSelecionada("text-white");
        return;
      }
      */

      const auxCorClassName = coresClassName.filter(
        (corClassName) => corClassName.corId === parseInt(cor.value)
      );
      setCorClassNameSelecionada(auxCorClassName[0].className);
    },
    [coresClassName]
  );

  const handleAliquotaCashback = useCallback(
    async (event, value, maskedValue) => {
      await event.preventDefault();
      setAliquotaCashback(parseFloat(value));
    },
    []
  );

  const handleSubmit = useCallback(
    async (event, values) => {
      try {

        if (permitirVendaSelecionado.value === undefined) {
          await setMessageBox({
            show: true,
            title: "Erro",
            description: "Permitir Venda não selecionado!",
            error: true,
          });
          return;
        }

        if (agendamentoSelecionado.value === undefined) {
          await setMessageBox({
            show: true,
            title: "Erro",
            description: "Regra de Agendamento não selecionada!",
            error: true,
          });
          return;
        }

        if (corSelecionada.value === undefined) {
          await setMessageBox({
            show: true,
            title: "Erro",
            description: "Cor de Identificação não selecionada!",
            error: true,
          });
          return;
        }
        
        if (statusSelecionado.value === undefined) {
          await setMessageBox({
            show: true,
            title: "Erro",
            description: "Status não selecionado!",
            error: true,
          });
          return;
        }

        if (categoriaSelecionada.value === undefined) {
          await setMessageBox({
            show: true,
            title: "Erro",
            description: "Categoria não selecionada!",
            error: true,
          });
          return;
        }
        
        setAguarde(true);
        const response = await api.patch(`/web/servico/${servicoId}`, {
          descricao: values.descricao,
          categoriaId: categoriaSelecionada.value,
          valor: 0,
          permitirAlterarValor: true,
          aliquotaCashback,
          // corId: corSelecionada.value === 0 ? null : corSelecionada.value,
          corId: corSelecionada.value,
          permitirVenda: permitirVendaSelecionado.value === enumSimNao.sim,
          agendamento: agendamentoSelecionado.value,
          status: statusSelecionado.value,
        });
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        history.push("/cadastros/servico/listar");
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [agendamentoSelecionado, aliquotaCashback, categoriaSelecionada, corSelecionada, history, permitirVendaSelecionado, servicoId, setAguarde, setMessageBox, statusSelecionado]
  );

  useEffect(() => {
    async function getCategoria() {
      try {
        const response = await api.get("/web/categoria");

        if (!response.data.success) {
          throw response.data;
        }

        const reponseCores = await api.get("/web/configuracao/view/cores");
        if (!reponseCores.data.success) {
          throw reponseCores.data;
        }

        const listaCategoria = response.data.data.map((categorias) => {
          return {
            label: categorias.descricao,
            value: categorias.categoriaId,
          };
        });

        setCategorias(listaCategoria);

        const listaCores = reponseCores.data.data.map((cores) => {
          return {
            label: cores.descricao,
            value: cores.corId,
          };
        });
        //setCores([ {label: 'Sem Cor', value: 0} ,...listaCores]);
        setCores(listaCores);

        setCoresClassName(reponseCores.data.data);

        setStatus([
          { label: "Ativo", value: 1 },
          { label: "Inativo", value: 2 },
        ]);


        setPermitirVenda([
          { label: "Sim", value: 1 },
          { label: "Não", value: 0 },
        ]);

        setAgendamento([
          {
            label: "Não Permitido",
            value: enumAgendamentoServico.NaoPermitido,
          },
          {
            label: "Permitido para Todos",
            value: enumAgendamentoServico.PermitidoParaTodos,
          },
          {
            label: "Não Permitido para Clientes",
            value: enumAgendamentoServico.NaoPermitidoParaClientes,
          },
        ]);

      } catch (err) {
        setCores([]);
        setStatus([]);
        setCategorias([]);
        setAgendamento([]);
        setPermitirVenda([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCategoria();
  }, [history, setAguarde, setMessageBox]);

  useEffect(() => {
    async function getServico() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        setServicoId(values.id);
        const response = await api.get(`/web/servico/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const servico = response.data.data;

        Object.assign(servico, {
          dataCadastro: format(
            parseISO(servico.dataCadastro),
            "dd/MM/yyyy hh:mm"
          ),
          dataAlteracao: format(
            parseISO(servico.dataAlteracao),
            "dd/MM/yyyy hh:mm"
          ),
        });

        const auxCategoria = categorias.filter(
          (categoria) => categoria.value === servico.categoriaId
        );

        setCategoriaSelecionada(auxCategoria[0]);

        const auxCores = cores.filter((cor) => cor.value === servico.corId);
        setCorSelecionada(auxCores[0]);

        const auxCorClassName = coresClassName.filter(
          (corClassName) => corClassName.corId === parseInt(servico.corId)
        );
        if (auxCorClassName.length > 0) {
          setCorClassNameSelecionada(auxCorClassName[0].className);
        }

        const auxStatus = status.filter(
          (status) => status.value === servico.status
        );

        setAliquotaCashback(servico.aliquotaCashback);
        setStatusSelecionado(auxStatus[0]);

        const auxPermitirVenda = permitirVenda.filter(
          (permitirVenda) => permitirVenda.value === servico.permitirVenda
        );
        setPermitirVendaSelecionado(auxPermitirVenda[0]);

        const auxAgendamento = agendamento.filter(
          (agendamento) => agendamento.value === servico.agendamento
        );
        setAgendamentoSelecionado(auxAgendamento[0]);

        setRegistro(servico);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getServico();
  }, [
    setAguarde,
    setMessageBox,
    optionStatus.options,
    optionPermitirVenda.options,
    optionAgendamento.options,
    optionCor.options,
    props.location.search,
    categorias,
    agendamento,
    status,
    permitirVenda,
    coresClassName,
    cores,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Informações do Serviço - Edição" breadcrumbItem="Serviço" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="descricao"
                            label="Descrição"
                            value={registro.descricao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                            autoFocus={true}
                            autoComplete="off"
                            errorMessage="Descrição não informada"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup>
                          <Label>Aliquota de Cashback (%)</Label>
                          <IntlNumberInput
                            ref={inputAliquotaCashback}
                            locale="pt-BR"
                            precision={2}
                            value={parseFloat(aliquotaCashback)}
                            onChange={handleAliquotaCashback}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Permitir Venda</Label>
                          <Select
                            placeholder="Selecione uma opção"
                            value={permitirVendaSelecionado}
                            onChange={setPermitirVendaSelecionado}
                            options={optionPermitirVenda}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Agendamento</Label>
                          <Select
                            placeholder="Selecione uma regra"
                            value={agendamentoSelecionado}
                            onChange={setAgendamentoSelecionado}
                            options={optionAgendamento}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Cor de Identificação</Label>
                          <Select
                            placeholder="Selecione uma cor"
                            value={corSelecionada}
                            onChange={handleChangeCor}
                            options={optionCor}
                            classNamePrefix="select2-selection"
                            styles={selectStyles}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Exemplo da Cor </Label>

                          <div className="text-center">
                            <span
                              className={`badge ${corClassNameSelecionada} font-size-20`}
                            >
                              Compromisso
                            </span>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Status</Label>
                          <Select
                            placeholder="Selecione um Status"
                            value={statusSelecionado}
                            onChange={setStatusSelecionado}
                            options={optionStatus}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label>Categoria</Label>
                          <Select
                            placeholder="Selecione uma categoria"
                            value={categoriaSelecionada}
                            onChange={setCategoriaSelecionada}
                            options={optionCategoria}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data do Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data da Última Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServicoAlterarPage;
