import React from "react";
import { Button } from "reactstrap";

const SalvarButton = (props) => {
  return (
    <>
      <Button
        type="submit"
        color="success"
        className="btn-rounded mr-1 waves-effect waves-light"
      >
        <i className="mdi mdi-content-save-all-outline align-middle mr-1"></i>
        Salvar
      </Button>
    </>
  );
};

const VoltarButton = (props) => {
  return (
    <>
      <Button
        type="button"
        color="info"
        className="btn-rounded mr-1 waves-effect waves-light"
        onClick={() => {
          props.goBack();
        }}
      >
        <i className="bx bx-share align-middle mr-1"></i>
        Voltar
      </Button>
    </>
  );
};

const SalvarVoltarButtons = (props) => {
  return (
    <>
      <div className="text-sm-right">
        <SalvarButton />
        <VoltarButton
          goBack={() => {
            props.goBack();
          }}
        />
      </div>
    </>
  );
};

export { SalvarButton, VoltarButton, SalvarVoltarButtons };
