const columnsVenda = (vendas) => {
  return {
    columns: [
      {
        label: "#",
        field: "vendaId",
        sort: "asc",
      },

      {
        label: "Cliente",
        field: "clienteNome",
      },
      {
        label: "CPF",
        field: "clienteCpf",
      },
      {
        label: "Data/Hora Compra",
        field: "dataHora",
      },
      {
        label: "Valor Total",
        field: "valorTotal",
      },
      {
        label: "Cashback Utilizado",
        field: "valorCreditoResgatado",
      },
      {
        label: "Valor Pago",
        field: "valorPago",
      },
      {
        label: "Cashback Recebido",
        field: "valorCashback",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: vendas,
  };
};

const columnsItemVenda = (itensVenda) => {
  return {
    columns: [
      {
        label: "#",
        field: "sequencia",
      },
      {
        label: "Descrição",
        field: "descricao",
      },
      {
        label: "Quantidade",
        field: "quantidade",
      },
      {
        label: "Valor Unitário",
        field: "valorUnitario",
      },
      {
        label: "Valor Total",
        field: "valorTotal",
      },
      {
        label: "Cashback Utilizado",
        field: "valorCreditoResgatado",
      },
      {
        label: "Valor Pago",
        field: "valorPago",
      },
      {
        label: "Alíquota Cashback",
        field: "aliquotaCashback",
      },
      {
        label: "Cashback Recebido",
        field: "valorCashback",
      },
    ],
    rows: itensVenda,
  };
};

export { columnsVenda, columnsItemVenda };
