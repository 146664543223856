import numbro from 'numbro';

export default function formatValue(value, style) {
  numbro.registerLanguage({
    languageTag: 'pt-BR',
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: () => {
      return '';
    },
    currency: {
      symbol: 'R$ ',
      position: 'prefix',
      code: 'BRL',
    },
    formats: {
      fourDigits: {
        totalLength: 4,
        spaceSeparated: true,
        average: true,
      },
      fullWithTwoDecimals: {
        output: 'currency',
        mantissa: 2,
        spaceSeparated: true,
        thousandSeparated: true,
      },
      fullWithTwoDecimalsNoCurrency: {
        mantissa: 2,
        thousandSeparated: true,
      },
      fullWithNoDecimals: {
        output: 'currency',
        spaceSeparated: true,
        thousandSeparated: true,
        mantissa: 0,
      },
    },
  });

  numbro.setLanguage('pt-BR');

  switch (style) {
    case 'percent':
      return numbro(value).format({
        mantissa: 2,
      }) + ' %';
      
    case 'currency':
      return numbro(value).formatCurrency({ mantissa: 2 })
    
    default:
      return numbro(value).format({
        mantissa: 2,
      });
  }
}
