const columnsCao = (caes) => {
  return {
    columns: [
      {
        label: "",
        field: "avatar",
        sort: "disabled",
      },
      {
        label: "#",
        field: "animalId",
        sort: "asc",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
      },
      {
        label: "Raça",
        field: "racaAnimalDescricao",
      },
      {
        label: "Nascimento",
        field: "dataNascimento",
      },
      {
        label: "Nome do Cliente",
        field: "clienteNome",
      },
      {
        label: "Data Cadastro",
        field: "dataCadastro",
      },
      {
        label: "Última Alteração",
        field: "dataAlteracao",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: caes,
  };
};

const columnsGato = (gatos) => {
  return {
    columns: [
      {
        label: "",
        field: "avatar",
        sort: "disabled",
      },
      {
        label: "#",
        field: "animalId",
        sort: "asc",
      },

      {
        label: "Nome",
        field: "nome",
        sort: "asc",
      },
      {
        label: "Raça",
        field: "racaAnimalDescricao",
      },
      {
        label: "Nascimento",
        field: "dataNascimento",
      },
      {
        label: "Nome do Cliente",
        field: "clienteNome",
      },
      {
        label: "Data Cadastro",
        field: "dataCadastro",
      },
      {
        label: "Última Alteração",
        field: "dataAlteracao",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: gatos,
  };
};

export { columnsCao, columnsGato };
