import React from "react";
import { Provider } from "react-redux";
import { GlobalProvider } from "../contexts/globalContext";
import store from "../store";

const Providers = ({ children }) => {
  return (
    <GlobalProvider>
      <Provider store={store}>{children}</Provider>
    </GlobalProvider>
  );
};
export default Providers;
