const columnsCategoria = (categorias) => {
  return {
    columns: [
      {
        label: "#",
        field: "categoriaId",
        sort: "asc",
      },

      {
        label: "Descrição",
        field: "descricao",
        sort: "asc",
      },
      {
        label: "Cashback",
        field: "aliquotaCashback",
      },
      {
        label: "Data Última Alteração",
        field: "dataAlteracao",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: categorias,
  };
};

export default columnsCategoria;
