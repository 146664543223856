import React from "react";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const SuporteContato = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contato" breadcrumbItem="Suporte" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SuporteContato;
