import React, { useState, useEffect, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  Media,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import ShowToast from "../../../helpers/toast_helper";
import { Link, useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import classnames from "classnames";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { SalvarButton, VoltarButton } from "../../../components/ActionButton";
import { EmailButton, WhatsAppButton } from "../../../components/ContactButton";
import api from "../../../services/api";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import formatCpf from "../../../utils/formatCpf";
import formatPhone from "../../../utils/formatPhone";
import formatValue from "../../../utils/formatValue";
import ImgPetDefault from "../../../assets/images/pet-default.png";
import avatarPadrao from "../../../assets/images/users/avatar-padrao.jpg";
import DataTableDefault from "../../../components/DataTable/DataTableDefault";
import {
  columnsCao,
  columnsGato,
  columnsDispositivo,
} from "./datatableColumns";

const ClienteExibirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [perfil, setPerfil] = useState('');

  const [registro, setRegistro] = useState([]);
  const [dispositivos, setDispositivos] = useState([]); 
  const [caes, setCaes] = useState([]);
  const [gatos, setGatos] = useState([]);

  const [recebeNotificacao, setRecebeNotificacao] = useState(false);
  const [modalReiniciarSenha, setModalReiniciarSenha] = useState(false);
  const [modalUsuario, setModalUsuario] = useState(false);
  const [statusUsuario, setStatusUsuario] = useState(false);
  const [idUsuarioCliente, setIdUsuarioCliente] = useState(0);

  const [activeTabCliente, setActiveTabCliente] = useState("1");
  const [activeTabAnimal, setActiveTabAnimal] = useState("1");
  const history = useHistory();

  const reiniciarSenhaUsuario = useCallback(async () => {
    try {
      setModalReiniciarSenha(false);
      setAguarde(true);

      const response = await api.patch(`/web/usuario/senha`, {
        usuarioId: idUsuarioCliente,
      });
      if (!response.data.success) {
        throw response.data;
      }

      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Senha reiniciada com sucesso!",
        description: `Nova senha: ${response.data.data.novaSenha}`,
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      ShowToast(err.message, false);
    }
  }, [idUsuarioCliente, setAguarde, setMessageBox]);

  const handleAtualizarUsuario = useCallback(
    async (_event, values) => {
      try {
        setModalUsuario(false);
        setAguarde(true);

        const response = await api.patch(`/web/usuario/${idUsuarioCliente}`, {
          nome: values.nomeUsuario,
          email: values.emailUsuario,
          status: statusUsuario ? 1 : 2,
          perfilId: 3,
        });

        if (!response.data.success) {
          throw response.data;
        }

        Object.assign(registro, {
          usuarioNome: values.nomeUsuario,
          usuarioStatus: statusUsuario ? 1 : 2,
          usuarioEmail: values.emailUsuario,
        });

        setAguarde(false);
        ShowToast(response.data.message);
      } catch (err) {
        setAguarde(false);
        ShowToast(err.message, false);
      }
    },
    [setAguarde, idUsuarioCliente, registro, statusUsuario]
  );

  const handleAtualizarCarteiraDigital = useCallback(
    async () => {
      setAguarde(true);
      try {
        const response = await api.get(
          `/web/carteiradigital/usuario/${idUsuarioCliente}`
        );
        if (!response.data.success) {
          throw response.data;
        }
        
        const {
          saldoDisponivel,
          dataUltimoResgate,
          valorVencendo } = response.data.data;

        Object.assign(registro, {
          carteiraDigitalSaldoDisponivel:  formatValue(saldoDisponivel),
          carteiraDigitalDataUltimoResgate: dataUltimoResgate ? format(parseISO(dataUltimoResgate), "dd/MM/yyyy") : "",
          carteiraDigitalValorVencendo:  formatValue(valorVencendo),
        });

        setAguarde(false);
      } catch (err) {
        setAguarde(false);
        ShowToast(err.message, false);
      }
    },
    [setAguarde, idUsuarioCliente, registro]
  );

  useEffect(() => {
    if (localStorage.getItem("@Cashback:authUser")) {
      const obj = JSON.parse(localStorage.getItem("@Cashback:authUser"));
      setPerfil(obj.role);
    }

    async function getCliente() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);

        const response = await api.get(`/web/cliente/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const cliente = response.data.data;

        setStatusUsuario(cliente.usuarioStatus === 1);

        Object.assign(cliente, {
          clienteCpf: formatCpf(cliente.clienteCpf),
          clienteCelular: formatPhone(cliente.clienteCelular),
          clienteDataNascimento: format(
            parseISO(cliente.clienteDataNascimento),
            "dd/MM/yyyy"
          ),
          clienteDataCadastro: format(
            parseISO(cliente.clienteDataCadastro),
            "dd/MM/yyyy hh:mm"
          ),
          clienteDataAlteracao: cliente.clienteDataAlteracao
            ? format(parseISO(cliente.clienteDataAlteracao), "dd/MM/yyyy hh:mm")
            : "",
          usuarioDataUltimoLogin: cliente.usuarioDataUltimoLogin
            ? format(
                parseISO(cliente.usuarioDataUltimoLogin),
                "dd/MM/yyyy hh:mm"
              )
            : "",
          usuarioDataUltimoAcesso: cliente.usuarioDataUltimoAcesso
            ? format(
                parseISO(cliente.usuarioDataUltimoAcesso),
                "dd/MM/yyyy hh:mm"
              )
            : "",
          usuarioAvatar: cliente.usuarioAvatar ? (
              <img
                src={cliente.usuarioAvatar}
                alt=""
                className="avatar-md rounded-circle img-thumbnail"
              />
            ) : (
              <img
                src={avatarPadrao}
                alt=""
                className="avatar-md rounded-circle img-thumbnail"
              />
            ),
          carteiraDigitalSaldoDisponivel: formatValue(cliente.carteiraDigitalSaldoDisponivel),
          carteiraDigitalDataUltimoResgate: cliente.carteiraDigitalDataUltimoResgate ? format(parseISO(cliente.carteiraDigitalDataUltimoResgate), "dd/MM/yyyy") : "",
          carteiraDigitalValorVencendo: formatValue(cliente.carteiraDigitalValorVencendo),     
        });

        const listaDispositivo = response.data.data.dispositivos.map(
          (dispositivo) => {
            return {
              ...dispositivo,
              plataforma:
                dispositivo.plataforma === 1 ? (
                  <div className="text-center">
                    <span className="font-size-18 text-success">
                      <i className="bx bxl-android"></i>
                    </span>
                  </div>
                ) : (
                  <div className="text-center">
                    <span className="font-size-18">
                      <i className="bx bxl-apple"></i>
                    </span>
                  </div>
                ),
              padrao:
                dispositivo.padrao === 1 ? (
                  <div className="text-center">
                    <span className="badge-pill badge-soft-success font-size-11">
                      SIM
                    </span>
                  </div>
                ) : (
                  <div className="text-center">
                    <span className="badge-pill badge-soft-danger font-size-11">
                      NÃO
                    </span>
                  </div>
                ),
              dataUltimoAcesso: dispositivo.dataUltimoAcesso
                ? format(
                    parseISO(dispositivo.dataUltimoAcesso),
                    "dd/MM/yyyy HH:mm"
                  )
                : "",
              dataCadastro: dispositivo.dataCadastro
                ? format(parseISO(dispositivo.dataCadastro), "dd/MM/yyyy HH:mm")
                : "",
            };
          }
        );

        setDispositivos(listaDispositivo);

        const listaAnimais = response.data.data.animais.map((animal) => {
          return {
            ...animal,
            avatar: animal.avatar ? (
              <img
                src={animal.avatar}
                style={{ borderRadius: 30 }}
                alt=""
                height="30"
              />
            ) : (
              <img
                src={ImgPetDefault}
                style={{ borderRadius: 30 }}
                alt=""
                height="30"
              />
            ),
            dataNascimento: animal.dataNascimento
              ? format(parseISO(animal.dataNascimento), "dd/MM/yyyy HH:mm")
              : "",
            dataAlteracao: animal.dataAlteracao
              ? format(parseISO(animal.dataAlteracao), "dd/MM/yyyy HH:mm")
              : "",
            dataCadastro: animal.dataCadastro
              ? format(parseISO(animal.dataCadastro), "dd/MM/yyyy HH:mm")
              : "",
            action: (
              <>
                <Link
                  to={`/cadastros/animal/exibir?clienteId=${values.id}&animalId=${animal.animalId}`}
                  className="mr-1 text-info"
                >
                  <i
                    className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                    id="exibirTooltip"
                  ></i>
                </Link>

                <Link
                  to={`/cadastros/animal/alterar?clienteId=${values.id}&animalId=${animal.animalId}`}
                  className="mr-3 text-warning"
                >
                  <i
                    className="bx bx-edit-alt font-size-18 align-middle mr-2"
                    id="alterarTooltip"
                  ></i>
                </Link>
              </>
            ),
          };
        });

        const listaCaes = listaAnimais.filter(
          (animal) => animal.tipoAnimalId === 1
        );

        const listaGatos = listaAnimais.filter(
          (animal) => animal.tipoAnimalId === 2
        );

        setCaes(listaCaes);
        setGatos(listaGatos);

        setIdUsuarioCliente(cliente.usuarioId);

        setRecebeNotificacao(cliente.usuarioReceberNotificacao === 1);
        setRegistro(cliente);
      } catch (err) {
        setRegistro([]);
        setCaes([]);
        setGatos([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCliente();
  }, [props.location.search, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações de Cliente - Visualização"
            breadcrumbItem="Cliente"
          />

          {modalReiniciarSenha ? (
            <SweetAlert
              title="Deseja realmente reiniciar a senha deste usuário?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Reiniciar Senha"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={reiniciarSenhaUsuario}
              onCancel={() => {
                setModalReiniciarSenha(false);
              }}
            ></SweetAlert>
          ) : null}

          <Modal
            isOpen={modalUsuario}
            toggle={() => {
              setModalUsuario(false);
            }}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Dados do Usuário
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUsuario(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleAtualizarUsuario(e, v);
                }}
              >
                <FormGroup className="mb-3" row>
                  <Label
                    htmlFor="nomeUsuario"
                    md="3"
                    className="col-form-label"
                  >
                    Nome
                  </Label>
                  <Col md={9}>
                    <AvField
                      name="nomeUsuario"
                      className="form-control"
                      placeholder="Informe o nome do usuário"
                      type="text"
                      value={registro.usuarioNome}
                      required
                      errorMessage="Nome não informado"
                      autoComplete="off"
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="mb-3" row>
                  <Label
                    htmlFor="emailUsuario"
                    md="3"
                    className="col-form-label"
                  >
                    Email
                  </Label>
                  <Col md={9}>
                    <AvField
                      name="emailUsuario"
                      className="form-control"
                      placeholder="Informe o email do usuário"
                      type="email"
                      value={registro.usuarioEmail}
                      required
                      errorMessage="Email inválido"
                      autoComplete="off"
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="mb-3" row>
                  <Label htmlFor="status" md="3" className="col-form-label">
                    Ativo
                  </Label>
                  <Col md={2}>
                    <input
                      type="checkbox"
                      id="statusUsuario"
                      switch="bool"
                      checked={statusUsuario}
                      onChange={() => {
                        setStatusUsuario(!statusUsuario);
                      }}
                    />
                    <label
                      htmlFor="statusUsuario"
                      data-on-label=""
                      data-off-label=""
                    ></label>
                  </Col>
                </FormGroup>
                <div className="text-sm-right">
                  <SalvarButton />
                </div>
              </AvForm>
            </div>
          </Modal>

          {registro.clienteNome && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="5">
                        <Media>
                          <div className="mr-3">
                            {registro.usuarioAvatar}
                          </div>
                          <Media body className="align-self-center">
                            <div className="text-muted">
                              <h5>
                                {perfil === 'admin' && (
                                  <Link
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setModalUsuario(true);
                                    }}
                                    to={() => {}}
                                    className="mr-1 text-warning"
                                  >
                                    <i
                                      className="bx bx-pencil font-size-18 align-middle mr-1"
                                      id="editarTooltip"
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="editarTooltip"
                                    >
                                      Editar dados do usuário
                                    </UncontrolledTooltip>
                                  </Link>
                                )}
                                {registro.usuarioNome}&ensp;
                                {registro.usuarioStatus === 1 ? (
                                  <span className="badge badge-pill badge-soft-success font-size-11">
                                    Ativo
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-soft-danger font-size-11">
                                    Inativo
                                  </span>
                                )}
                              </h5>
                              <p className="mb-1">
                                <EmailButton
                                  email={registro.usuarioEmail}
                                  tooltip
                                  visibleText
                                />
                              </p>
                              <p className="mb-0">
                                <WhatsAppButton
                                  fone={registro.clienteCelular}
                                  tooltip
                                  visibleText
                                />
                              </p>
                            </div>
                          </Media>
                        </Media>
                      </Col>
                      {perfil === 'admin' && (
                        <Col lg="7" className="align-self-center">
                          <div className="text-lg-center mt-4 mt-lg-0">
                            <Row>
                              <Col xs="3">
                                <div>
                                  <i className="fas fa-wallet font-size-24"></i>
                                  <p className="text-muted text-truncate mb-2">
                                    Saldo Atual
                                  </p>
                                  <h5 className="mb-0">
                                    R$ {registro.carteiraDigitalSaldoDisponivel}
                                  </h5>
                                </div>
                              </Col>

                              <Col xs="3">
                                <div>
                                  <i className="fas fa-calendar-check font-size-24"></i>
                                  <p className="text-muted text-truncate mb-2">
                                    Último Resgate
                                  </p>
                                  <h5 className="mb-0">
                                    {registro.carteiraDigitalDataUltimoResgate}
                                  </h5>
                                </div>
                              </Col>

                              <Col xs="3">
                                <div>
                                  <i className="fas fa-eraser font-size-24"></i>
                                  <p className="text-muted text-truncate mb-2">
                                    Vencendo
                                  </p>
                                  <h5 className="mb-0">
                                    R$ {registro.carteiraDigitalValorVencendo}
                                  </h5>
                                </div>
                              </Col>

                              <Col lg="3" className="d-none d-lg-block">
                                <div className="clearfix mt-4 mt-lg-0">
                                  <Link
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleAtualizarCarteiraDigital();
                                    }}
                                    to={() => {}}
                                    className="btn btn-rounded btn-success"
                                  >
                                    <i className="fas fa-sync-alt font-size-16 align-middle mr-1"></i>
                                    Atualizar
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTabCliente === "1",
                      })}
                      onClick={() => {
                        setActiveTabCliente("1");
                      }}
                    >
                      <i className="bx bxs-user d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">Dados Pessoais</p>
                    </NavLink>
                    <NavLink
                      className={classnames({
                        active: activeTabCliente === "2",
                      })}
                      onClick={() => {
                        setActiveTabCliente("2");
                      }}
                    >
                      {registro.clienteQuantidadeCao +
                        registro.clienteQuantidadeGato >
                        0 && (
                        <span className="badge-pill badge-danger float-right">
                          {registro.clienteQuantidadeCao +
                            registro.clienteQuantidadeGato}
                        </span>
                      )}
                      <i className="mdi mdi-dog d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">Pets</p>
                    </NavLink>
                    <NavLink
                      className={classnames({
                        active: activeTabCliente === "3",
                      })}
                      onClick={() => {
                        setActiveTabCliente("3");
                      }}
                    >
                      <i className="bx bxs-map-pin d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">Endereço</p>
                    </NavLink>
                    <NavLink
                      className={classnames({
                        active: activeTabCliente === "4",
                      })}
                      onClick={() => {
                        setActiveTabCliente("4");
                      }}
                    >
                      {dispositivos.length > 0 && (
                        <span className="badge-pill badge-danger float-right">
                          {dispositivos.length}
                        </span>
                      )}
                      <i className="mdi mdi-cellphone-iphone d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">Dispositivos</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTabCliente}>
                      <TabPane tabId="1">
                        <div>
                          <Form>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="nome"
                                md="2"
                                className="col-form-label"
                              >
                                Nome
                              </Label>
                              <Col md="10">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nome"
                                  defaultValue={registro.clienteNome}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="cpf"
                                md="2"
                                className="col-form-label"
                              >
                                CPF
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cpf"
                                  defaultValue={registro.clienteCpf}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="celular"
                                md="2"
                                className="col-form-label"
                              >
                                Celular
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="celular"
                                  defaultValue={registro.clienteCelular}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="email"
                                md="2"
                                className="col-form-label"
                              >
                                Email
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  defaultValue={registro.usuarioEmail}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataNascimento"
                                md="2"
                                className="col-form-label"
                              >
                                Nascimento
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataNascimento"
                                  defaultValue={registro.clienteDataNascimento}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataCadastro"
                                md="2"
                                className="col-form-label"
                              >
                                Data do Cadastro
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataCadastro"
                                  defaultValue={registro.clienteDataCadastro}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataAlteracao"
                                md="2"
                                className="col-form-label"
                              >
                                Última Alteração
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataAlteracao"
                                  defaultValue={registro.clienteDataAlteracao}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataUltimoLogin"
                                md="2"
                                className="col-form-label"
                              >
                                Último Login
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataUltimoLogin"
                                  defaultValue={registro.usuarioDataUltimoLogin}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataUltimoAcesso"
                                md="2"
                                className="col-form-label"
                              >
                                Último Acesso
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataUltimoAcesso"
                                  defaultValue={
                                    registro.usuarioDataUltimoAcesso
                                  }
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="recebeNotificacao"
                                md="2"
                                className="col-form-label"
                              >
                                Notificações
                              </Label>
                              <Col md={10}>
                                <input
                                  type="checkbox"
                                  id="recebeNotificacao"
                                  switch="bool"
                                  checked={recebeNotificacao}
                                  onChange={() => {
                                    setRecebeNotificacao(!recebeNotificacao);
                                  }}
                                  disabled
                                />
                                <label
                                  htmlFor="recebeNotificacao"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                                {perfil === 'admin' && (
                                  <div className="text-sm-right">
                                    <Link
                                      to={`/`}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setModalReiniciarSenha(true);
                                      }}
                                      className="btn btn-rounded btn-danger"
                                    >
                                      <i
                                        className="mdi mdi-lock font-size-16 align-middle mr-1"
                                        id="restSenhaTooltip"
                                      ></i>
                                      Reiniciar Senha
                                    </Link>
                                  </div>
                                )}
                              </Col>
                            </FormGroup>
                          </Form>
                        </div>
                      </TabPane>

                      <TabPane tabId="2">
                        <div>
                          <div className="button-items ml-3">
                            <Link
                              className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                              to={`/cadastros/animal/incluir?id=${registro.clienteId}`}
                            >
                              <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                              Incluir
                            </Link>
                          </div>
                          <Card>
                            <CardBody>
                              <Nav tabs>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTabAnimal === "1",
                                    })}
                                    onClick={() => {
                                      setActiveTabAnimal("1");
                                    }}
                                  >
                                    Cães
                                    {registro.clienteQuantidadeCao > 0 && (
                                      <>
                                        &nbsp;
                                        <span className="badge-pill badge-light float-right">
                                          {registro.clienteQuantidadeCao}
                                        </span>
                                      </>
                                    )}
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTabAnimal === "2",
                                    })}
                                    onClick={() => {
                                      setActiveTabAnimal("2");
                                    }}
                                  >
                                    Gatos
                                    {registro.clienteQuantidadeGato > 0 && (
                                      <>
                                        &nbsp;
                                        <span className="badge-pill badge-light float-right">
                                          {registro.clienteQuantidadeGato}
                                        </span>
                                      </>
                                    )}
                                  </NavLink>
                                </NavItem>
                              </Nav>

                              <TabContent activeTab={activeTabAnimal}>
                                <TabPane tabId="1" className="p-3">
                                  <div>
                                    <Row>
                                      <Col className="col-12">
                                        <DataTableDefault
                                          dataSource={columnsCao(caes)}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </TabPane>
                                <TabPane tabId="2" className="p-3">
                                  <div>
                                    <Row>
                                      <Col className="col-12">
                                        <DataTableDefault
                                          dataSource={columnsGato(gatos)}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </CardBody>
                          </Card>
                        </div>
                      </TabPane>

                      <TabPane tabId="3">
                        <div>
                          <Form>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="endereco"
                                md="2"
                                className="col-form-label"
                              >
                                Endereço
                              </Label>
                              <Col md="10">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="endereco"
                                  defaultValue={registro.enderecoLogradouro}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="cep"
                                md="2"
                                className="col-form-label"
                              >
                                CEP
                              </Label>
                              <Col md="10">
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="cep"
                                  defaultValue={registro.enderecoCep}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="bairro"
                                md="2"
                                className="col-form-label"
                              >
                                Bairro
                              </Label>
                              <Col md={10}>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="bairro"
                                  defaultValue={registro.enderecoBairro}
                                  disabled
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="cidade"
                                md="2"
                                className="col-form-label"
                              >
                                Cidade
                              </Label>
                              <Col md={10}>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cidade"
                                  defaultValue={registro.enderecoMunicipio}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="estado"
                                md="2"
                                className="col-form-label"
                              >
                                Estado
                              </Label>
                              <Col md={10}>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="estado"
                                  defaultValue={registro.enderecoUf}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                          </Form>
                        </div>
                      </TabPane>

                      <TabPane tabId="4">
                        <div>
                          <Row>
                            <Col className="col-12">
                              <DataTableDefault
                                dataSource={columnsDispositivo(dispositivos)}
                              />
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                <Row className="my-4">
                  <Col sm="12">
                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClienteExibirPage;
