const columnsTipoAtendimento = (tipoAtendimentos) => {
  return {
    columns: [
      {
        label: "#",
        field: "tipoAtendimentoId",
      },
      {
        label: "Descrição",
        field: "descricao",
        sort: "asc",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: tipoAtendimentos,
  };
};

export default columnsTipoAtendimento;
