import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import { format, parseISO } from "date-fns";
import classnames from "classnames";
import api from "../../../../services/api";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import formatValue from "../../../../utils/formatValue";
import ImgPetDefault from "../../../../assets/images/pet-default.png";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import { columnsCao, columnsGato } from "./datatableColumns";

const AnimalListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();
  const [caes, setCaes] = useState([]);
  const [gatos, setGatos] = useState([]);
  const [quantidadeCaes, setQuantidadeCaes] = useState("0");
  const [percentualCaes, setPercentualCaes] = useState("0 %");
  const [quantidadeGatos, setQuantidadeGatos] = useState("0");
  const [percentualGatos, setPercentualGatos] = useState(" 0%");
  const [quantidadeAnimais, setQuantidadeAnimais] = useState("0");
  const [activeTabAnimal, setActiveTabAnimal] = useState("1");

  useEffect(() => {
    async function getCliente() {
      try {
        setAguarde(true);
        const response = await api.get(`/web/animal`);

        setAguarde(false);
        if (!response.data.success) {
          throw response.data;
        }

        const listaAnimais = response.data.data.map((animal) => {
          return {
            ...animal,
            avatar: animal.avatar ? (
              <img
                src={animal.avatar}
                style={{ borderRadius: 15 }}
                alt=""
                height="30"
                width="30"
              />
            ) : (
              <img
                src={ImgPetDefault}
                style={{ borderRadius: 15 }}
                alt=""
                height="30"
                width="30"
              />
            ),
            dataNascimento: animal.dataNascimento
              ? format(parseISO(animal.dataNascimento), "dd/MM/yyyy")
              : "",
            dataAlteracao: animal.dataAlteracao
              ? format(parseISO(animal.dataAlteracao), "dd/MM/yyyy HH:mm")
              : "",
            dataCadastro: animal.dataCadastro
              ? format(parseISO(animal.dataCadastro), "dd/MM/yyyy HH:mm")
              : "",
            action: (
              <>
                <Link
                  to={`/cadastros/animal/exibir?clienteId=${animal.clienteId}&animalId=${animal.animalId}`}
                  className="mr-1 text-info"
                >
                  <i
                    className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                    id="exibirTooltip"
                  ></i>
                </Link>

                <Link
                  to={`/cadastros/animal/alterar?clienteId=${animal.clienteId}&animalId=${animal.animalId}`}
                  className="mr-3 text-warning"
                >
                  <i
                    className="bx bx-edit-alt font-size-18 align-middle mr-2"
                    id="alterarTooltip"
                  ></i>
                </Link>
              </>
            ),
          };
        });

        const listaCaes = listaAnimais.filter(
          (animal) => animal.tipoAnimalId === 1
        );

        const listaGatos = listaAnimais.filter(
          (animal) => animal.tipoAnimalId === 2
        );

        setCaes(listaCaes);
        setGatos(listaGatos);

        setQuantidadeCaes(listaCaes.length);
        setPercentualCaes(
          formatValue(
            (listaCaes.length / (listaCaes.length + listaGatos.length)) * 100,
            "percent"
          )
        );
        setQuantidadeGatos(listaGatos.length);
        setPercentualGatos(
          formatValue(
            (listaGatos.length / (listaCaes.length + listaGatos.length)) * 100,
            "percent"
          )
        );
        setQuantidadeAnimais(listaCaes.length + listaGatos.length);
      } catch (err) {
        setCaes([]);
        setGatos([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCliente();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Lista de Animais" breadcrumbItem="Animais" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/animal/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-dark text-dark font-size-18">
                        <i className="mdi mdi-pets"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Total de Animais</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeAnimais}</h4>
                    <span className="badge badge-soft-dark font-size-12">
                      100%
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-info text-info font-size-18">
                        <i className="mdi mdi-dog"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Cães</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeCaes}</h4>
                    <span className="badge badge-soft-info font-size-12">
                      {percentualCaes}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-danger text-danger font-size-18">
                        <i className="mdi mdi-cat"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Gatos</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeGatos}</h4>
                    <span className="badge badge-soft-danger font-size-12">
                      {percentualGatos}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabAnimal === "1",
                        })}
                        onClick={() => {
                          setActiveTabAnimal("1");
                        }}
                      >
                        Cães
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabAnimal === "2",
                        })}
                        onClick={() => {
                          setActiveTabAnimal("2");
                        }}
                      >
                        Gatos
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTabAnimal}>
                    <TabPane tabId="1" className="p-3">
                      <div>
                        <Row>
                          <Col className="col-12">
                            <DataTableDefault dataSource={columnsCao(caes)} />
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                      <div>
                        <Row>
                          <Col className="col-12">
                            <DataTableDefault dataSource={columnsGato(gatos)} />
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AnimalListarPage;
