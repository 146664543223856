const columnsRedeSocial = (redeSocial) => {
  return {
    columns: [
      {
        label: "#",
        field: "redeSocialEmpresaId",
        sort: "asc",
      },
      {
        label: "Descrição",
        field: "redeSocialDescricao",
        sort: "asc",
      },
      {
        label: "Conta",
        field: "conta",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: redeSocial,
  };
};

export default columnsRedeSocial;
