import React, { useEffect, useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { format, parseISO } from "date-fns";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import api from "../../../services/api";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import formatValue from "../../../utils/formatValue";
import DataTableDefault from "../../../components/DataTable/DataTableDefault";
import { columnsUsuario } from "./datatableColumns";

const UsuarioListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [usuarioSelecionado, setUsuarioSelecionado] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [modalReiniciarSenha, setModalReiniciarSenha] = useState(false);
  const [usuarios, setUsuarios] = useState([]);

  const [quantidadeAdmin, setQuantidadeAdmin] = useState("0");
  const [percentualAdmin, setPercentualAdmin] = useState("0 %");
  const [quantidadeSistema, setQuantidadeSistema] = useState("0");
  const [percentualSistema, setPercentualSistema] = useState("0 %");
  const [quantidadeVendedor, setQuantidadeVendedor] = useState("0");
  const [percentualVendedor, setPercentualVendedor] = useState("0 %");

  const excluirUsuario = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);

      const response = await api.delete(`/web/usuario/${usuarioSelecionado}`);
      if (!response.data.success) {
        throw response.data;
      }

      const auxUsuario = usuarios.filter(
        (usuario) => usuario.usuarioId !== usuarioSelecionado
      );
      setUsuarios(auxUsuario);

      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Sucesso",
        description: "Usuário excluído com sucesso.",
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [setAguarde, setMessageBox, usuarioSelecionado, usuarios]);

  const reiniciarSenhaUsuario = useCallback(async () => {
    try {
      setModalReiniciarSenha(false);
      setAguarde(true);

      const response = await api.patch(`/web/usuario/senha`, {
        usuarioId: usuarioSelecionado,
      });
      if (!response.data.success) {
        throw response.data;
      }

      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Senha reiniciada com sucesso!",
        description: `Nova senha: ${response.data.data.novaSenha}`,
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [setAguarde, setMessageBox, usuarioSelecionado]);

  useEffect(() => {
    async function getUsuario() {
      try {
        setAguarde(true);

        const response = await api.get("/web/usuario");
        if (!response.data.success) {
          throw response.data;
        }

        const {
          auxQuantidadeSistema,
          auxQuantidadeAdmin,
          auxQuantidadeVendedor,
        } = response.data.data.reduce(
          (accumulator, usuario) => {
            if (usuario.perfilDescricao === "Admin") {
              accumulator.auxQuantidadeAdmin += 1;
            } else {
              if (usuario.perfilDescricao === "Vendedor") {
                accumulator.auxQuantidadeVendedor += 1;
              } else {
                accumulator.auxQuantidadeSistema += 1;
              }
            }
            return accumulator;
          },
          {
            auxQuantidadeSistema: 0,
            auxQuantidadeAdmin: 0,
            auxQuantidadeVendedor: 0,
          }
        );

        const listaUsuario = response.data.data.map((usuario) => {
          return {
            ...usuario,
            email: (
              <Link
                to={`/`}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(`mailto:${usuario.email}`);
                }}
                className="mr-1 text-dark"
              >
                {usuario.email}
              </Link>
            ),
            perfilDescricao:
              usuario.perfilDescricao === "Admin" ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-danger font-size-11">
                    {usuario.perfilDescricao}
                  </span>
                </div>
              ) : usuario.perfilDescricao === "Sistema" ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-info font-size-11">
                    {usuario.perfilDescricao}
                  </span>
                </div>
              ) : usuario.perfilDescricao === "Vendedor" ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-warning font-size-11">
                    {usuario.perfilDescricao}
                  </span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-success font-size-11">
                    {usuario.perfilDescricao}
                  </span>
                </div>
              ),
            status:
              usuario.status === 1 ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-success font-size-11">
                    Ativo
                  </span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-danger font-size-11">
                    Inativo
                  </span>
                </div>
              ),
            dataUltimoAcesso: usuario.dataUltimoAcesso
              ? format(parseISO(usuario.dataUltimoAcesso), "dd/MM/yyyy HH:mm")
              : "",
            dataUltimoLogin: usuario.dataUltimoLogin
              ? format(parseISO(usuario.dataUltimoLogin), "dd/MM/yyyy HH:mm")
              : "",
            dataAlteracao: format(
              parseISO(usuario.dataAlteracao),
              "dd/MM/yyyy HH:mm"
            ),
            action: (
              <>
                <Link
                  to={`/cadastros/usuario/exibir?id=${usuario.usuarioId}`}
                  className="mr-1 text-info"
                >
                  <i
                    className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                    id="exibirTooltip"
                  ></i>
                </Link>

                <Link
                  to={`/cadastros/usuario/alterar?id=${usuario.usuarioId}`}
                  className="mr-1 text-warning"
                >
                  <i
                    className="bx bx-edit-alt font-size-18 align-middle mr-1"
                    id="alterarTooltip"
                  ></i>
                </Link>

                <Link
                  onClick={() => {
                    setUsuarioSelecionado(usuario.usuarioId);
                    setModalExcluir(true);
                  }}
                  to={() => {}}
                  className="mr-1 text-danger"
                >
                  <i
                    className="bx bx-trash font-size-18 align-middle mr-1"
                    id="excluirTooltip"
                  ></i>
                </Link>

                <Link
                  onClick={() => {
                    setUsuarioSelecionado(usuario.usuarioId);
                    setModalReiniciarSenha(true);
                  }}
                  to={() => {}}
                  className="mr-1 text-dark"
                >
                  <i
                    className="mdi mdi-lock font-size-18 align-middle mr-1"
                    id="restSenhaTooltip"
                  ></i>
                </Link>
              </>
            ),
          };
        });
        setUsuarios(listaUsuario);

        setQuantidadeSistema(auxQuantidadeSistema);
        setQuantidadeAdmin(auxQuantidadeAdmin);
        setQuantidadeVendedor(auxQuantidadeVendedor);

        if (listaUsuario.length > 0) {
          setPercentualSistema(
            formatValue(
              (auxQuantidadeSistema / listaUsuario.length) * 100,
              "percent"
            )
          );
          setPercentualAdmin(
            formatValue(
              (auxQuantidadeAdmin / listaUsuario.length) * 100,
              "percent"
            )
          );
          setPercentualVendedor(
            formatValue(
              (auxQuantidadeVendedor / listaUsuario.length) * 100,
              "percent"
            )
          );
        } else {
          setPercentualSistema("0,00 %");
          setPercentualAdmin("0,00 %");
          setPercentualVendedor("0,00 %");
        }

        setAguarde(false);
      } catch (err) {
        setUsuarios([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getUsuario();
  }, [setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Lista de Usuários" breadcrumbItem="Usuário" />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir este usuário?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirUsuario}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          {modalReiniciarSenha ? (
            <SweetAlert
              title="Deseja realmente reinicar a senha deste usuário?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Reiniciar Senha"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={reiniciarSenhaUsuario}
              onCancel={() => {
                setModalReiniciarSenha(false);
              }}
            ></SweetAlert>
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/usuario/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-dark text-dark font-size-18">
                        <i className="mdi mdi-account-group-outline"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Total de Usuários</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{usuarios.length}</h4>
                    <span className="badge badge-soft-dark font-size-12">
                      100%
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-info text-info font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Sistema</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeSistema}</h4>
                    <span className="badge badge-soft-info font-size-12">
                      {percentualSistema}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-danger text-danger font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Admin</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeAdmin}</h4>
                    <span className="badge badge-soft-danger font-size-12">
                      {percentualAdmin}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-warning text-warning font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Vendedor</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeVendedor}</h4>
                    <span className="badge badge-soft-warning font-size-12">
                      {percentualVendedor}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsUsuario(usuarios)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UsuarioListarPage;
