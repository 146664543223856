import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
  Media,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";
import { format, parseISO } from "date-fns";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { VoltarButton } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

import ImgPetDefault from "../../../../assets/images/pet-default.png";

const EmpresaRedeSocialAlterarPage = (props) => {
  const history = useHistory();

  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);

  const [tiposAnimal, setTiposAnimal] = useState([]);
  const [tipoAnimalSelecionado, setTipoAnimalSelecionado] = useState([]);

  const [racasAnimal, setRacasAnimal] = useState([]);
  const [racaAnimalSelecionado, setRacaAnimalSelecionado] = useState([]);

  const [generosAnimal, setGenerosAnimal] = useState([]);
  const [generoAnimalSelecionado, setGeneroAnimalSelecionado] = useState([]);

  const optionCliente = [
    {
      options: clientes,
    },
  ];

  const optionTipoAnimal = [
    {
      options: tiposAnimal,
    },
  ];

  const optionRacaAnimal = [
    {
      options: racasAnimal,
    },
  ];

  const optionGeneroAnimal = [
    {
      options: generosAnimal,
    },
  ];

  useEffect(() => {
    async function getClientes() {
      try {
        const response = await api.get("/web/cliente");
        if (response.data.success) {
          const listaCliente = response.data.data.map((cliente) => {
            return {
              label: cliente.nome,
              value: cliente.clienteId,
            };
          });

          setClientes(listaCliente);
        } else {
          setClientes([]);
        }
      } catch (err) {
        setClientes([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }

    async function getTipoAnimal() {
      try {
        const response = await api.get("/web/tipoanimal");
        if (response.data.success) {
          const listaTipoAnimal = response.data.data.map((tipoAnimal) => {
            return {
              label: tipoAnimal.descricao,
              value: tipoAnimal.tipoAnimalId,
            };
          });

          setTiposAnimal(listaTipoAnimal);
        } else {
          setTiposAnimal([]);
        }
      } catch (err) {
        setTiposAnimal([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }

    async function getRacas() {
      try {
        const response = await api.get("/web/racaanimal");
        if (response.data.success) {
          const listaRacaAnimal = response.data.data.map((racaAnimal) => {
            return {
              label: racaAnimal.descricao,
              value: racaAnimal.racaAnimalId,
            };
          });

          setRacasAnimal(listaRacaAnimal);
        } else {
          setRacasAnimal([]);
        }
      } catch (err) {
        setRacasAnimal([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }

    setGenerosAnimal([
      {
        label: "Macho",
        value: "m",
      },
      {
        label: "Fêmea",
        value: "f",
      },
    ]);

    getClientes();
    getTipoAnimal();
    getRacas();
  }, [setAguarde, setMessageBox]);

  useEffect(() => {
    async function getAnimal() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        const response = await api.get(
          `/web/cliente/${values.clienteId}/animal/${values.animalId}`
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const dadosAnimal = response.data.data;

        Object.assign(dadosAnimal, {
          ...dadosAnimal,
          pelagem: dadosAnimal.pelagem ? dadosAnimal.pelagem : "",
          avatar: dadosAnimal.avatar ? (
            <img
              src={dadosAnimal.avatar}
              alt=""
              className="avatar-md rounded-circle img-thumbnail"
            />
          ) : (
            <img
              src={ImgPetDefault}
              alt=""
              className="avatar-md rounded-circle img-thumbnail"
            />
          ),
          dataNascimento: dadosAnimal.dataNascimento
            ? format(parseISO(dadosAnimal.dataNascimento), "dd/MM/yyyy HH:mm")
            : "",
          dataAlteracao: dadosAnimal.dataAlteracao
            ? format(parseISO(dadosAnimal.dataAlteracao), "dd/MM/yyyy HH:mm")
            : "",
          dataCadastro: dadosAnimal.dataCadastro
            ? format(parseISO(dadosAnimal.dataCadastro), "dd/MM/yyyy HH:mm")
            : "",
        });

        const auxCliente = clientes.filter(
          (cliente) => cliente.value === parseInt(values.clienteId)
        );

        if (auxCliente[0]) {
          setClienteSelecionado(auxCliente[0]);
        }

        const auxRacaAnimal = racasAnimal.filter(
          (racaAnimal) => racaAnimal.value === dadosAnimal.racaAnimalId
        );

        if (auxRacaAnimal[0]) {
          setRacaAnimalSelecionado(auxRacaAnimal[0]);
        }

        const auxTipoAnimal = tiposAnimal.filter(
          (tipoAnimal) => tipoAnimal.value === dadosAnimal.tipoAnimalId
        );

        if (auxTipoAnimal[0]) {
          setTipoAnimalSelecionado(auxTipoAnimal[0]);
        }

        const auxGeneroAnimal = generosAnimal.filter(
          (generoAnimal) => generoAnimal.value === dadosAnimal.genero
        );

        if (auxGeneroAnimal[0]) {
          setGeneroAnimalSelecionado(auxGeneroAnimal[0]);
        }

        setRegistro(dadosAnimal);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getAnimal();
  }, [
    clientes,
    generosAnimal,
    props.location.search,
    racasAnimal,
    setAguarde,
    setMessageBox,
    tiposAnimal,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações do Animal - Visualização"
            breadcrumbItem="Animal"
          />

          {registro.nome && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Media>
                      <div className="mr-3">{registro.avatar}</div>
                      <Media body className="align-self-center">
                        <div className="text-muted">
                          <h5>{registro.nome}</h5>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm className="form-horizontal">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>Cliente</Label>
                          <Select
                            placeholder="Selecione um cliente"
                            value={clienteSelecionado}
                            onChange={setClienteSelecionado}
                            options={optionCliente}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="nome"
                            label="Nome"
                            value={registro.nome}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>Tipo de Animal</Label>
                          <Select
                            placeholder=""
                            value={tipoAnimalSelecionado}
                            onChange={setTipoAnimalSelecionado}
                            options={optionTipoAnimal}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>Raça</Label>
                          <Select
                            placeholder=""
                            value={racaAnimalSelecionado}
                            onChange={setRacaAnimalSelecionado}
                            options={optionRacaAnimal}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>Gênero</Label>
                          <Select
                            placeholder=""
                            value={generoAnimalSelecionado}
                            onChange={setGeneroAnimalSelecionado}
                            options={optionGeneroAnimal}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="pelagem"
                            label="Pelagem"
                            value={registro.pelagem}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataNascimento"
                            label="Data Nascimento"
                            value={registro.dataNascimento}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmpresaRedeSocialAlterarPage;
