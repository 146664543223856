import React, { useEffect, useState, useCallback, useRef } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
  Media,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import Select from "react-select";
import { format, parseISO } from "date-fns";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { SalvarVoltarButtons } from "../../../../components/ActionButton";
import api from "../../../../services/api";

import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";

import ImgPetDefault from "../../../../assets/images/pet-default.png";

const AnimalAlterarPage = (props) => {
  const history = useHistory();

  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);
  const [dataNascimentoAnimal, seDataNascimentoAnimal] = useState("");

  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);

  const [tiposAnimal, setTiposAnimal] = useState([]);
  const [tipoAnimalSelecionado, setTipoAnimalSelecionado] = useState([]);

  const [racasAnimal, setRacasAnimal] = useState([]);
  const [racasTipoAnimal, setRacasTipoAnimal] = useState([]);
  const [racaAnimalSelecionado, setRacaAnimalSelecionado] = useState([]);

  const [generosAnimal, setGenerosAnimal] = useState([]);
  const [generoAnimalSelecionado, setGeneroAnimalSelecionado] = useState([]);

  const dataNascimento = useRef(null);

  const optionCliente = [
    {
      options: clientes,
    },
  ];

  const optionTipoAnimal = [
    {
      options: tiposAnimal,
    },
  ];

  const optionRacaAnimal = [
    {
      options: racasTipoAnimal,
    },
  ];

  const optionGeneroAnimal = [
    {
      options: generosAnimal,
    },
  ];

  useEffect(() => {
    async function getClientes() {
      try {
        const response = await api.get("/web/cliente");
        if (response.data.success) {
          const listaCliente = response.data.data.map((cliente) => {
            return {
              label: cliente.nome,
              value: cliente.clienteId,
            };
          });

          setClientes(listaCliente);
        } else {
          setClientes([]);
        }
      } catch (err) {
        setClientes([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }

    async function getTipoAnimal() {
      try {
        const response = await api.get("/web/tipoanimal");
        if (response.data.success) {
          const listaTipoAnimal = response.data.data.map((tipoAnimal) => {
            return {
              label: tipoAnimal.descricao,
              value: tipoAnimal.tipoAnimalId,
            };
          });

          setTiposAnimal(listaTipoAnimal);
        } else {
          setTiposAnimal([]);
        }
      } catch (err) {
        setTiposAnimal([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }

    async function getRacas() {
      try {
        const response = await api.get("/web/racaanimal");
        if (response.data.success) {
          const listaRacaAnimal = response.data.data.map((racaAnimal) => {
            return {
              label: racaAnimal.descricao,
              value: racaAnimal.racaAnimalId,
              tipoAnimal: racaAnimal.tipoAnimalId,
            };
          });

          setRacasAnimal(listaRacaAnimal);
        } else {
          setRacasAnimal([]);
        }
      } catch (err) {
        setRacasAnimal([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }

    setGenerosAnimal([
      {
        label: "Macho",
        value: "m",
      },
      {
        label: "Fêmea",
        value: "f",
      },
    ]);

    getClientes();
    getTipoAnimal();
    getRacas();
  }, [setAguarde, setMessageBox]);

  useEffect(() => {
    async function getAnimal() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);
        const response = await api.get(
          `/web/cliente/${values.clienteId}/animal/${values.animalId}`
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const dadosAnimal = response.data.data;

        if (dadosAnimal.dataNascimento) {
          seDataNascimentoAnimal(
            format(parseISO(dadosAnimal.dataNascimento), "yyyy-MM-dd")
          );
        }

        Object.assign(dadosAnimal, {
          ...dadosAnimal,
          pelagem: dadosAnimal.pelagem ? dadosAnimal.pelagem : "",
          avatar: dadosAnimal.avatar ? (
            <img
              src={dadosAnimal.avatar}
              alt=""
              className="avatar-md rounded-circle img-thumbnail"
            />
          ) : (
            <img
              src={ImgPetDefault}
              alt=""
              className="avatar-md rounded-circle img-thumbnail"
            />
          ),
          dataNascimento: dadosAnimal.dataNascimento
            ? format(parseISO(dadosAnimal.dataNascimento), "dd/MM/yyyy")
            : "",
          dataAlteracao: dadosAnimal.dataAlteracao
            ? format(parseISO(dadosAnimal.dataAlteracao), "dd/MM/yyyy HH:mm")
            : "",
          dataCadastro: dadosAnimal.dataCadastro
            ? format(parseISO(dadosAnimal.dataCadastro), "dd/MM/yyyy HH:mm")
            : "",
        });

        const auxCliente = clientes.filter(
          (cliente) => cliente.value === parseInt(values.clienteId)
        );

        if (auxCliente[0]) {
          setClienteSelecionado(auxCliente[0]);
        }

        const auxTipoAnimal = tiposAnimal.filter(
          (tipoAnimal) => tipoAnimal.value === dadosAnimal.tipoAnimalId
        );

        if (auxTipoAnimal[0]) {
          setTipoAnimalSelecionado(auxTipoAnimal[0]);

          const auxRacaTipoAnimal = racasAnimal.filter(
            (auxRaca) => auxRaca.tipoAnimal === parseInt(auxTipoAnimal[0].value)
          );

          setRacasTipoAnimal(auxRacaTipoAnimal);
        }

        const auxRacaAnimal = racasAnimal.filter(
          (racaAnimal) => racaAnimal.value === dadosAnimal.racaAnimalId
        );

        if (auxRacaAnimal[0]) {
          setRacaAnimalSelecionado(auxRacaAnimal[0]);
        }

        const auxGeneroAnimal = generosAnimal.filter(
          (generoAnimal) => generoAnimal.value === dadosAnimal.genero
        );

        if (auxGeneroAnimal[0]) {
          setGeneroAnimalSelecionado(auxGeneroAnimal[0]);
        }

        setRegistro(dadosAnimal);
      } catch (err) {
        setRegistro([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getAnimal();
  }, [
    clientes,
    generosAnimal,
    props.location.search,
    racasAnimal,
    setAguarde,
    setMessageBox,
    tiposAnimal,
  ]);

  const handleChangeTipoAnimal = useCallback(
    async (raca) => {
      setRacaAnimalSelecionado([]);
      setTipoAnimalSelecionado(raca);

      const auxRacaTipoAnimal = racasAnimal.filter(
        (auxRaca) => auxRaca.tipoAnimal === parseInt(raca.value)
      );

      setRacasTipoAnimal(auxRacaTipoAnimal);
    },
    [racasAnimal]
  );

  const handleSubmit = useCallback(
    async (event, values) => {
      try {
        if (!clienteSelecionado.value) {
          await setMessageBox({
            show: true,
            title: "Atenção",
            description: "Cliente não informada",
            warning: true,
          });
          return;
        }

        if (!tipoAnimalSelecionado.value) {
          await setMessageBox({
            show: true,
            title: "Atenção",
            description: "Tipo de animal não informado",
            warning: true,
          });
          return;
        }

        if (!racaAnimalSelecionado.value) {
          await setMessageBox({
            show: true,
            title: "Atenção",
            description: "Raça não informada",
            warning: true,
          });
          return;
        }

        if (!generoAnimalSelecionado.value) {
          await setMessageBox({
            show: true,
            title: "Atenção",
            description: "Gênero não informado",
            warning: true,
          });
          return;
        }

        setAguarde(true);
        const response = await api.patch(
          `/web/cliente/${clienteSelecionado.value}/animal/${registro.animalId}`,
          {
            nome: values.nome,
            tipoAnimalId: tipoAnimalSelecionado.value,
            racaAnimalId: racaAnimalSelecionado.value,
            pelagem: values.pelagem === "" ? null : values.pelagem,
            genero: generoAnimalSelecionado.value,
            dataNascimento:
              dataNascimento.current.value === ""
                ? null
                : dataNascimento.current.value,
          }
        );
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        history.goBack();
      } catch (err) {
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    },
    [
      clienteSelecionado.value,
      generoAnimalSelecionado.value,
      history,
      racaAnimalSelecionado.value,
      registro.animalId,
      setAguarde,
      setMessageBox,
      tipoAnimalSelecionado.value,
    ]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações do Animal - Edição"
            breadcrumbItem="Animal"
          />

          {registro.nome && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Media>
                      <div className="mr-3">{registro.avatar}</div>
                      <Media body className="align-self-center">
                        <div className="text-muted">
                          <h5>{registro.nome}</h5>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>Cliente</Label>
                          <Select
                            placeholder="Selecione um cliente"
                            value={clienteSelecionado}
                            onChange={setClienteSelecionado}
                            options={optionCliente}
                            classNamePrefix="select2-selection"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <AvField
                            name="nome"
                            label="Nome do Animal"
                            type="text"
                            value={registro.nome}
                            className="form-control"
                            placeholder=""
                            required
                            errorMessage="Nome não informado"
                            autoFocus={true}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>Tipo de Animal</Label>
                          <Select
                            placeholder=""
                            value={tipoAnimalSelecionado}
                            onChange={(value) => handleChangeTipoAnimal(value)}
                            options={optionTipoAnimal}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>Raça</Label>
                          <Select
                            placeholder=""
                            value={racaAnimalSelecionado}
                            onChange={setRacaAnimalSelecionado}
                            options={optionRacaAnimal}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>Gênero</Label>
                          <Select
                            placeholder=""
                            value={generoAnimalSelecionado}
                            onChange={setGeneroAnimalSelecionado}
                            options={optionGeneroAnimal}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="pelagem"
                            label="Pelagem"
                            value={registro.pelagem}
                            className="form-control"
                            placeholder=""
                            type="text"
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label>Data de Nascimento</Label>
                          <input
                            ref={dataNascimento}
                            className="form-control"
                            type="date"
                            defaultValue={dataNascimentoAnimal}
                            id="dataNascimento"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataCadastro"
                            label="Data do Cadastro"
                            value={registro.dataCadastro}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <AvField
                            name="dataAlteracao"
                            label="Data da Alteração"
                            value={registro.dataAlteracao}
                            className="form-control"
                            placeholder=""
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <SalvarVoltarButtons
                      goBack={() => {
                        history.goBack();
                      }}
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AnimalAlterarPage;
