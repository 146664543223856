import React, { useState, useEffect, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  Media,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation-safe";
import ShowToast from "../../../../helpers/toast_helper";
import { Link, useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import classnames from "classnames";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { SalvarButton, VoltarButton } from "../../../../components/ActionButton";
import { EmailButton, WhatsAppButton } from "../../../../components/ContactButton";
import api from "../../../../services/api";
import { useAguarde, useMessageBox } from "../../../../contexts/globalContext";
import formatCpf from "../../../../utils/formatCpf";
import formatPhone from "../../../../utils/formatPhone";
import formatValue from "../../../../utils/formatValue";
import avatarPadrao from "../../../../assets/images/users/avatar-padrao.jpg";
import DataTableDefault from "../../../../components/DataTable/DataTableDefault";
import {
  columnsDispositivo,
} from "./datatableColumns";

const ProfissionalExibirPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();

  const [registro, setRegistro] = useState([]);
  const [dispositivos, setDispositivos] = useState([]); 
  
  const [generos, setGeneros] = useState([]);
  const [generoSelecionado, setGeneroSelecionado] = useState([]);

  const [recebeNotificacao, setRecebeNotificacao] = useState(false);
  const [modalReiniciarSenha, setModalReiniciarSenha] = useState(false);
  const [modalUsuario, setModalUsuario] = useState(false);
  const [statusUsuario, setStatusUsuario] = useState(false);
  const [idUsuarioProfissional, setIdUsuarioProfissional] = useState(0);
  
  const [especializacoesProficional, setEspecializacoesProficional] = useState(null);
  const [especializacoes, setEspecializacoes] = useState([]);

  const [activeTabProfissional, setActiveTabProfissional] = useState("1");
  const history = useHistory();

  const optionEspecializacao = [
    {
      label: "Especializações",
      options: especializacoes,
    },
  ];
  
  const optionGenero = [
    {
      options: generos,
    },
  ];

  function handleSelecionarEspecializacao(selectedMulti) { setEspecializacoesProficional(selectedMulti); }

  const reiniciarSenhaUsuario = useCallback(async () => {
    try {
      setModalReiniciarSenha(false);
      setAguarde(true);

      const response = await api.patch(`/web/usuario/senha`, {
        usuarioId: idUsuarioProfissional,
      });
      if (!response.data.success) {
        throw response.data;
      }

      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Senha reiniciada com sucesso!",
        description: `Nova senha: ${response.data.data.novaSenha}`,
        success: true,
      });
    } catch (err) {
      setAguarde(false);
      ShowToast(err.message, false);
    }
  }, [idUsuarioProfissional, setAguarde, setMessageBox]);

  const handleAtualizarUsuario = useCallback(
    async (_event, values) => {
      try {
        setModalUsuario(false);
        setAguarde(true);

        const response = await api.patch(`/web/usuario/${idUsuarioProfissional}`, {
          nome: values.nomeUsuario,
          email: values.emailUsuario,
          status: statusUsuario ? 1 : 2,
          perfilId: 5,
        });

        if (!response.data.success) {
          throw response.data;
        }

        Object.assign(registro, {
          usuarioNome: values.nomeUsuario,
          usuarioStatus: statusUsuario ? 1 : 2,
          usuarioEmail: values.emailUsuario,
        });

        setAguarde(false);
        ShowToast(response.data.message);
      } catch (err) {
        setAguarde(false);
        ShowToast(err.message, false);
      }
    },
    [setAguarde, idUsuarioProfissional, registro, statusUsuario]
  );

  useEffect(() => {
    setGeneros([
      {
        label: "Homem",
        value: "m",
      },
      {
        label: "Mulher",
        value: "f",
      },
      {
        label: "Outros",
        value: "o",
      },
    ]);
  },[]);

  useEffect(() => {
    async function getProfissional() {
      try {
        const values = queryString.parse(props.location.search);
        setAguarde(true);

        const responseEspecializacao = await api.get("/web/especializacao");
        if (responseEspecializacao.data.success) {
          const listaEspecializacao = responseEspecializacao.data.data.map((especializacao) => {
            return {
              label: especializacao.descricao,
              value: especializacao.especializacaoId,
            };
          });

          setEspecializacoes(listaEspecializacao);
        } else {
          setEspecializacoes([]);
        }

        const response = await api.get(`/web/profissional/${values.id}`);
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const profissional = response.data.data;

        setStatusUsuario(profissional.usuarioStatus === 1);
        setRecebeNotificacao(profissional.usuarioReceberNotificacao === 1);

        const profissionalEspecializacao = profissional.especializacaoProfissional.map((especializacao) => {
          return {
            label: especializacao.descricao,
            value: especializacao.especializacaoId,
          };
        });

        setEspecializacoesProficional(profissionalEspecializacao);

        const auxGenero = generos.filter(
          (genero) => genero.value === profissional.profissionalGenero
        );

        if (auxGenero[0]) {
          setGeneroSelecionado(auxGenero[0]);
        }

        Object.assign(profissional, {
          profissionalCpf: formatCpf(profissional.profissionalCpf),
          profissionalCelular: formatPhone(profissional.profissionalCelular),
          profissionalDataNascimento: format(
            parseISO(profissional.profissionalDataNascimento),
            "dd/MM/yyyy"
          ),
          profissionalDataCadastro: format(
            parseISO(profissional.profissionalDataCadastro),
            "dd/MM/yyyy hh:mm"
          ),
          profissionalDataAlteracao: profissional.profissionalDataAlteracao
            ? format(parseISO(profissional.profissionalDataAlteracao), "dd/MM/yyyy hh:mm")
            : "",
          usuarioDataUltimoLogin: profissional.usuarioDataUltimoLogin
            ? format(
                parseISO(profissional.usuarioDataUltimoLogin),
                "dd/MM/yyyy hh:mm"
              )
            : "",
          usuarioDataUltimoAcesso: profissional.usuarioDataUltimoAcesso
            ? format(
                parseISO(profissional.usuarioDataUltimoAcesso),
                "dd/MM/yyyy hh:mm"
              )
            : "",
          usuarioAvatar: profissional.usuarioAvatar ? (
              <img
                src={profissional.usuarioAvatar}
                alt=""
                className="avatar-md rounded-circle img-thumbnail"
              />
            ) : (
              <img
                src={avatarPadrao}
                alt=""
                className="avatar-md rounded-circle img-thumbnail"
              />
            ),
          carteiraDigitalSaldoDisponivel: formatValue(profissional.carteiraDigitalSaldoDisponivel),
          carteiraDigitalDataUltimoResgate: profissional.carteiraDigitalDataUltimoResgate ? format(parseISO(profissional.carteiraDigitalDataUltimoResgate), "dd/MM/yyyy") : "",
          carteiraDigitalValorVencendo: formatValue(profissional.carteiraDigitalValorVencendo),     
        });

        const listaDispositivo = response.data.data.dispositivos.map(
          (dispositivo) => {
            return {
              ...dispositivo,
              plataforma:
                dispositivo.plataforma === 1 ? (
                  <div className="text-center">
                    <span className="font-size-18 text-success">
                      <i className="bx bxl-android"></i>
                    </span>
                  </div>
                ) : (
                  <div className="text-center">
                    <span className="font-size-18">
                      <i className="bx bxl-apple"></i>
                    </span>
                  </div>
                ),
              padrao:
                dispositivo.padrao === 1 ? (
                  <div className="text-center">
                    <span className="badge-pill badge-soft-success font-size-11">
                      SIM
                    </span>
                  </div>
                ) : (
                  <div className="text-center">
                    <span className="badge-pill badge-soft-danger font-size-11">
                      NÃO
                    </span>
                  </div>
                ),
              dataUltimoAcesso: dispositivo.dataUltimoAcesso
                ? format(
                    parseISO(dispositivo.dataUltimoAcesso),
                    "dd/MM/yyyy HH:mm"
                  )
                : "",
              dataCadastro: dispositivo.dataCadastro
                ? format(parseISO(dispositivo.dataCadastro), "dd/MM/yyyy HH:mm")
                : "",
            };
          }
        );

        setDispositivos(listaDispositivo);

        setIdUsuarioProfissional(profissional.usuarioId);

        setRecebeNotificacao(profissional.usuarioReceberNotificacao === 1);
        setRegistro(profissional);
      } catch (err) {
        setRegistro([]);
        setDispositivos([]);
        setEspecializacoes([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getProfissional();
  }, [generos, props.location.search, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Informações do Profissional - Visualização"
            breadcrumbItem="Profissional"
          />

          {modalReiniciarSenha ? (
            <SweetAlert
              title="Deseja realmente reiniciar a senha deste usuário?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Reiniciar Senha"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={reiniciarSenhaUsuario}
              onCancel={() => {
                setModalReiniciarSenha(false);
              }}
            ></SweetAlert>
          ) : null}

          <Modal
            isOpen={modalUsuario}
            toggle={() => {
              setModalUsuario(false);
            }}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Dados do Usuário
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUsuario(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleAtualizarUsuario(e, v);
                }}
              >
                <FormGroup className="mb-3" row>
                  <Label
                    htmlFor="nomeUsuario"
                    md="3"
                    className="col-form-label"
                  >
                    Nome
                  </Label>
                  <Col md={9}>
                    <AvField
                      name="nomeUsuario"
                      className="form-control"
                      placeholder="Informe o nome do usuário"
                      type="text"
                      value={registro.usuarioNome}
                      required
                      errorMessage="Nome não informado"
                      autoComplete="off"
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="mb-3" row>
                  <Label
                    htmlFor="emailUsuario"
                    md="3"
                    className="col-form-label"
                  >
                    Email
                  </Label>
                  <Col md={9}>
                    <AvField
                      name="emailUsuario"
                      className="form-control"
                      placeholder="Informe o email do usuário"
                      type="email"
                      value={registro.usuarioEmail}
                      required
                      errorMessage="Email inválido"
                      autoComplete="off"
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="mb-3" row>
                  <Label htmlFor="status" md="3" className="col-form-label">
                    Ativo
                  </Label>
                  <Col md={2}>
                    <input
                      type="checkbox"
                      id="statusUsuario"
                      switch="bool"
                      checked={statusUsuario}
                      onChange={() => {
                        setStatusUsuario(!statusUsuario);
                      }}
                    />
                    <label
                      htmlFor="statusUsuario"
                      data-on-label=""
                      data-off-label=""
                    ></label>
                  </Col>
                </FormGroup>
                <div className="text-sm-right">
                  <SalvarButton />
                </div>
              </AvForm>
            </div>
          </Modal>

          {registro.profissionalNome && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="5">
                        <Media>
                          <div className="mr-3">
                            {registro.usuarioAvatar}
                          </div>
                          <Media body className="align-self-center">
                            <div className="text-muted">
                              <h5>
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setModalUsuario(true);
                                  }}
                                  to={() => {}}
                                  className="mr-1 text-warning"
                                >
                                  <i
                                    className="bx bx-pencil font-size-18 align-middle mr-1"
                                    id="editarTooltip"
                                  ></i>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="editarTooltip"
                                  >
                                    Editar dados do usuário
                                  </UncontrolledTooltip>
                                </Link>
                                {registro.usuarioNome}&ensp;
                                {registro.usuarioStatus === 1 ? (
                                  <span className="badge badge-pill badge-soft-success font-size-11">
                                    Ativo
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-soft-danger font-size-11">
                                    Inativo
                                  </span>
                                )}
                              </h5>
                              <p className="mb-1">
                                <EmailButton
                                  email={registro.usuarioEmail}
                                  tooltip
                                  visibleText
                                />
                              </p>
                              <p className="mb-0">
                                <WhatsAppButton
                                  fone={registro.profissionalCelular}
                                  tooltip
                                  visibleText
                                />
                              </p>
                            </div>
                          </Media>
                        </Media>
                      </Col>
                      <Col lg="7" className="align-self-center">
                        <div className="text-lg-center mt-4 mt-lg-0">
                          <Row>
                            <Col xs="4">
                              <div>
                                <i className="fas fa-calendar-check font-size-24"></i>
                                <p className="text-muted text-truncate mb-2">
                                  Último Atendimento
                                </p>
                                <h5 className="mb-0">
                                  {registro.carteiraDigitalDataUltimoResgate}
                                </h5>
                              </div>
                            </Col>

                            <Col xs="4">
                              <div>
                                <i className="fas fa-calendar font-size-24"></i>
                                <p className="text-muted text-truncate mb-2">
                                  Próximo Atendimento
                                </p>
                                <h5 className="mb-0">
                                  {registro.carteiraDigitalDataUltimoResgate}
                                </h5>
                              </div>
                            </Col>

                            <Col lg="4" className="d-none d-lg-block">
                              <div className="clearfix mt-4 mt-lg-0">
                                <Link
                                  to={`/`}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setModalReiniciarSenha(true);
                                  }}
                                  className="btn btn-rounded btn-danger"
                                >
                                  <i
                                    className="mdi mdi-lock font-size-16 align-middle mr-1"
                                    id="restSenhaTooltip"
                                  ></i>
                                  Reiniciar Senha
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTabProfissional === "1",
                      })}
                      onClick={() => {
                        setActiveTabProfissional("1");
                      }}
                    >
                      <i className="bx bxs-user d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">Dados Pessoais</p>
                    </NavLink>
                    <NavLink
                      className={classnames({
                        active: activeTabProfissional === "2",
                      })}
                      onClick={() => {
                        setActiveTabProfissional("2");
                      }}
                    >
                      {dispositivos.length > 0 && (
                        <span className="badge-pill badge-danger float-right">
                          {dispositivos.length}
                        </span>
                      )}
                      <i className="mdi mdi-cellphone-iphone d-block check-nav-icon mt-4 mb-2"></i>
                      <p className="font-weight-bold mb-4">Dispositivos</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTabProfissional}>
                      <TabPane tabId="1">
                        <div>
                          <Form>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="nome"
                                md="2"
                                className="col-form-label"
                              >
                                Nome
                              </Label>
                              <Col md="10">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nome"
                                  defaultValue={registro.profissionalNome}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="genero"
                                md="2"
                                className="col-form-label"
                              >
                                Gênero
                              </Label>
                              <Col md="10">
                                <Select
                                  placeholder="Selecione um Gênero"
                                  value={generoSelecionado}
                                  onChange={setGeneroSelecionado}
                                  options={optionGenero}
                                  classNamePrefix="select2-selection"
                                  isDisabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="cpf"
                                md="2"
                                className="col-form-label"
                              >
                                CPF
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cpf"
                                  defaultValue={registro.profissionalCpf}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="celular"
                                md="2"
                                className="col-form-label"
                              >
                                Celular
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="celular"
                                  defaultValue={registro.profissionalCelular}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="email"
                                md="2"
                                className="col-form-label"
                              >
                                Email
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  defaultValue={registro.usuarioEmail}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataNascimento"
                                md="2"
                                className="col-form-label"
                              >
                                Nascimento
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataNascimento"
                                  defaultValue={registro.profissionalDataNascimento}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="especializacao"
                                md="2"
                                className="col-form-label"
                              >
                                Especializações
                              </Label>
                              <Col md={10}>
                                <Select
                                  value={especializacoesProficional}
                                  isMulti={true}
                                  onChange={() => { handleSelecionarEspecializacao() }}
                                  options={optionEspecializacao}
                                  classNamePrefix="select2-selection"
                                  placeholder="Nenhuma Especialização"
                                  noOptionsMessage={() => "Nenhuma Especialização"} 
                                  isDisabled={true}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="biografia"
                                md="2"
                                className="col-form-label"
                              >
                                Biografia
                              </Label>
                              <Col md={10}>
                                <Input
                                  name="biografia"
                                  className="form-control"
                                  placeholder=""
                                  type="textarea"
                                  maxLength="225"
                                  rows="3"
                                  value={registro.profissionalBiografia}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataCadastro"
                                md="2"
                                className="col-form-label"
                              >
                                Data do Cadastro
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataCadastro"
                                  defaultValue={registro.profissionalDataCadastro}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataAlteracao"
                                md="2"
                                className="col-form-label"
                              >
                                Última Alteração
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataAlteracao"
                                  defaultValue={registro.profissionalDataAlteracao}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataUltimoLogin"
                                md="2"
                                className="col-form-label"
                              >
                                Último Login
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataUltimoLogin"
                                  defaultValue={registro.usuarioDataUltimoLogin}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="dataUltimoAcesso"
                                md="2"
                                className="col-form-label"
                              >
                                Último Acesso
                              </Label>
                              <Col md={10}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="dataUltimoAcesso"
                                  defaultValue={
                                    registro.usuarioDataUltimoAcesso
                                  }
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="recebeNotificacao"
                                md="2"
                                className="col-form-label"
                              >
                                Notificações
                              </Label>
                              <Col md={10}>
                                <input
                                  type="checkbox"
                                  id="recebeNotificacao"
                                  switch="bool"
                                  checked={recebeNotificacao}
                                  onChange={() => {
                                    setRecebeNotificacao(!recebeNotificacao);
                                  }}
                                  disabled
                                />
                                <label
                                  htmlFor="recebeNotificacao"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </Col>
                            </FormGroup>
                          </Form>
                        </div>
                      </TabPane>

                      <TabPane tabId="2">
                        <div>
                          <Row>
                            <Col className="col-12">
                              <DataTableDefault
                                dataSource={columnsDispositivo(dispositivos)}
                              />
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                <Row className="my-4">
                  <Col sm="12">
                    <div className="text-sm-right">
                      <VoltarButton
                        goBack={() => {
                          history.goBack();
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProfissionalExibirPage;
