const columnsTipoAnimal = (tipoAnimal) => {
  return {
    columns: [
      {
        label: "#",
        field: "tipoAnimalId",
      },
      {
        label: "Descrição",
        field: "descricao",
        sort: "asc",
      },
      {
        label: "Ações",
        field: "action",
        sort: "disabled",
      },
    ],
    rows: tipoAnimal,
  };
};

export default columnsTipoAnimal;
