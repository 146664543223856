import React from "react";
import { Redirect } from "react-router-dom";

// Painel de Controle
import Painel from "../pages/Painel/index";

// Cadastros
import CadastroEmpresa from "../pages/Cadastros/Empresa/Perfil";

import EmpresaAtendimentoListarPage from "../pages/Cadastros/Empresa/Atendimento/listarPage";
import EmpresaAtendimentoIncluirPage from "../pages/Cadastros/Empresa/Atendimento/incluirPage";
import EmpresaAtendimentoAlterarPage from "../pages/Cadastros/Empresa/Atendimento/alterarPage";
import EmpresaAtendimentoExibirPage from "../pages/Cadastros/Empresa/Atendimento/exibirPage";
import EmpresaTipoAtendimentoListarPage from "../pages/Cadastros/Empresa/TipoAtendimento/listarPage";
import EmpresaTipoAtendimentoIncluirPage from "../pages/Cadastros/Empresa/TipoAtendimento/incluirPage";
import EmpresaTipoAtendimentoAlterarPage from "../pages/Cadastros/Empresa/TipoAtendimento/alterarPage";
import EmpresaTipoAtendimentoExibirPage from "../pages/Cadastros/Empresa/TipoAtendimento/exibirPage";
import EmpresaRedeSocialListarPage from "../pages/Cadastros/Empresa/RedeSocial/listarPage";
import EmpresaRedeSocialIncluirPage from "../pages/Cadastros/Empresa/RedeSocial/incluirPage";
import EmpresaRedeSocialAlterarPage from "../pages/Cadastros/Empresa/RedeSocial/alterarPage";
import EmpresaRedeSocialExibirPage from "../pages/Cadastros/Empresa/RedeSocial/exibirPage";

import AnimalListarPage from "../pages/Cadastros/Animal/Animal/listarPage";
import AnimalIncluirPage from "../pages/Cadastros/Animal/Animal/incluirPage";
import AnimalAlterarPage from "../pages/Cadastros/Animal/Animal/alterarPage";
import AnimalExibirPage from "../pages/Cadastros/Animal/Animal/exibirPage";
import AnimalRacaListarPage from "../pages/Cadastros/Animal/Raca/listarPage";
import AnimalRacaIncluirPage from "../pages/Cadastros/Animal/Raca/incluirPage";
import AnimalRacaAlterarPage from "../pages/Cadastros/Animal/Raca/alterarPage";
import AnimalRacaExibirPage from "../pages/Cadastros/Animal/Raca/exibirPage";
import AnimalTipoAnimalListarPage from "../pages/Cadastros/Animal/TipoAnimal/listarPage";
import AnimalTipoAnimalExibirPage from "../pages/Cadastros/Animal/TipoAnimal/exibirPage";

import ProfissionalEspecializacaoListarPage from "../pages/Cadastros/Profissional/Especializacao/listarPage";
import ProfissionalEspecializacaoIncluirPage from "../pages/Cadastros/Profissional/Especializacao/incluirPage";
import ProfissionalEspecializacaoAlterarPage from "../pages/Cadastros/Profissional/Especializacao/alterarPage";
import ProfissionalEspecializacaoExibirPage from "../pages/Cadastros/Profissional/Especializacao/exibirPage";

import ProfissionalListarPage from "../pages/Cadastros/Profissional/Profissional/listarPage";
import ProfissionalIncluirPage from "../pages/Cadastros/Profissional/Profissional/incluirPage";
import ProfissionalAlterarPage from "../pages/Cadastros/Profissional/Profissional/alterarPage";
import ProfissionalExibirPage from "../pages/Cadastros/Profissional/Profissional/exibirPage";

import UsuarioListarPage from "../pages/Cadastros/Usuario/listarPage";
import UsuarioIncluirPage from "../pages/Cadastros/Usuario/incluirPage";
import UsuarioAlterarPage from "../pages/Cadastros/Usuario/alterarPage";
import UsuarioExibirPage from "../pages/Cadastros/Usuario/exibirPage";

import FaixaRetornoListarPage from "../pages/Cadastros/FaixaRetorno/listarPage";
import FaixaRetornoIncluirPage from "../pages/Cadastros/FaixaRetorno/incluirPage";
import FaixaRetornoAlterarPage from "../pages/Cadastros/FaixaRetorno/alterarPage";
import FaixaRetornoExibirPage from "../pages/Cadastros/FaixaRetorno/exibirPage";

import ClienteListarPage from "../pages/Cadastros/Cliente/listarPage";
/*import ClienteIncluirPage from "../pages/Cadastros/Cliente/incluirPage";
import ClienteAlterarPage from "../pages/Cadastros/Cliente/alterarPage";*/
import ClienteExibirPage from "../pages/Cadastros/Cliente/exibirPage";

import CategoriaListarPage from "../pages/Cadastros/Categoria/listarPage";
import CategoriaIncluirPage from "../pages/Cadastros/Categoria/incluirPage";
import CategoriaAlterarPage from "../pages/Cadastros/Categoria/alterarPage";
import CategoriaExibirPage from "../pages/Cadastros/Categoria/exibirPage";

import ServicoListarPage from "../pages/Cadastros/Servico/listarPage";
import ServicoIncluirPage from "../pages/Cadastros/Servico/incluirPage";
import ServicoAlterarPage from "../pages/Cadastros/Servico/alterarPage";
import ServicoExibirPage from "../pages/Cadastros/Servico/exibirPage";

import CadastroConfiguracao from "../pages/Cadastros/Configuracao";

// Agendamento
import AgendamentoAgenda from "../pages/Agendamento/Agenda";

// Vendas
import VendaHistorico from "../pages/Vendas/Historico";
import VendaRegistroManual from "../pages/Vendas/RegistroManual";

// Cashback
import CashbackExtrato from "../pages/Cashback/Extrato";

// Notificações
import NotificacaoEnvioManual from "../pages/Notificacao/EnvioManual";
import Notificacao from "../pages/Notificacao";

//Sorteio
import SorteioManual from "../pages/Sorteios/SorteioManual";

// Suporte
import SuporteContato from "../pages/Suporte/Contato";
import SuporteSobre from "../pages/Suporte/Sobre";

// Perfil do Usuário
import UserProfile from "../pages/Authentication/UserProfile";

// Autenticação
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

/*import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import LockScreen from "../pages/Authentication/LockScreen"; */

import RecuperarSenha from "../pages/Authentication/RecuperarSenha";
import AlterarSenha from "../pages/Authentication/AlterarSenha";
import ResetarSenha from "../pages/Authentication/ResetarSenha";

const userRoutes = [
  { path: "/painel", component: Painel },

  { path: "/cadastros/empresa/perfil", component: CadastroEmpresa },

  {
    path: "/cadastros/empresa/atendimento/listar",
    component: EmpresaAtendimentoListarPage,
  },
  {
    path: "/cadastros/empresa/atendimento/incluir",
    component: EmpresaAtendimentoIncluirPage,
  },
  {
    path: "/cadastros/empresa/atendimento/alterar",
    component: EmpresaAtendimentoAlterarPage,
  },
  {
    path: "/cadastros/empresa/atendimento/exibir",
    component: EmpresaAtendimentoExibirPage,
  },

  {
    path: "/cadastros/empresa/tipoatendimento/listar",
    component: EmpresaTipoAtendimentoListarPage,
  },
  {
    path: "/cadastros/empresa/tipoatendimento/incluir",
    component: EmpresaTipoAtendimentoIncluirPage,
  },
  {
    path: "/cadastros/empresa/tipoatendimento/alterar",
    component: EmpresaTipoAtendimentoAlterarPage,
  },
  {
    path: "/cadastros/empresa/tipoatendimento/exibir",
    component: EmpresaTipoAtendimentoExibirPage,
  },

  {
    path: "/cadastros/animal/listar",
    component: AnimalListarPage,
  },
  {
    path: "/cadastros/animal/incluir",
    component: AnimalIncluirPage,
  },
  {
    path: "/cadastros/animal/alterar",
    component: AnimalAlterarPage,
  },
  {
    path: "/cadastros/animal/exibir",
    component: AnimalExibirPage,
  },

  {
    path: "/cadastros/animal/raca/listar",
    component: AnimalRacaListarPage,
  },
  {
    path: "/cadastros/animal/raca/incluir",
    component: AnimalRacaIncluirPage,
  },
  {
    path: "/cadastros/animal/raca/alterar",
    component: AnimalRacaAlterarPage,
  },
  {
    path: "/cadastros/animal/raca/exibir",
    component: AnimalRacaExibirPage,
  },

  {
    path: "/cadastros/animal/tipoanimal/listar",
    component: AnimalTipoAnimalListarPage,
  },
  {
    path: "/cadastros/animal/tipoanimal/exibir",
    component: AnimalTipoAnimalExibirPage,
  },

  {
    path: "/cadastros/empresa/redesocial/listar",
    component: EmpresaRedeSocialListarPage,
  },
  {
    path: "/cadastros/empresa/redesocial/incluir",
    component: EmpresaRedeSocialIncluirPage,
  },
  {
    path: "/cadastros/empresa/redesocial/alterar",
    component: EmpresaRedeSocialAlterarPage,
  },
  {
    path: "/cadastros/empresa/redesocial/exibir",
    component: EmpresaRedeSocialExibirPage,
  },

  {
    path: "/cadastros/profissional/listar",
    component: ProfissionalListarPage,
  },
  {
    path: "/cadastros/profissional/incluir",
    component: ProfissionalIncluirPage,
  },
  {
    path: "/cadastros/profissional/alterar",
    component: ProfissionalAlterarPage,
  },
  {
    path: "/cadastros/profissional/exibir",
    component: ProfissionalExibirPage,
  },

  {
    path: "/cadastros/profissional/especializacao/listar",
    component: ProfissionalEspecializacaoListarPage,
  },
  {
    path: "/cadastros/profissional/especializacao/incluir",
    component: ProfissionalEspecializacaoIncluirPage,
  },
  {
    path: "/cadastros/profissional/especializacao/alterar",
    component: ProfissionalEspecializacaoAlterarPage,
  },
  {
    path: "/cadastros/profissional/especializacao/exibir",
    component: ProfissionalEspecializacaoExibirPage,
  },

  { path: "/cadastros/faixaretorno/listar", component: FaixaRetornoListarPage },
  {
    path: "/cadastros/faixaretorno/incluir",
    component: FaixaRetornoIncluirPage,
  },
  {
    path: "/cadastros/faixaretorno/alterar",
    component: FaixaRetornoAlterarPage,
  },
  { path: "/cadastros/faixaretorno/exibir", component: FaixaRetornoExibirPage },

  { path: "/cadastros/cliente/listar", component: ClienteListarPage },
  /*{ path: "/cadastros/cliente/incluir", component: ClienteIncluirPage },
  { path: "/cadastros/cliente/alterar", component: ClienteAlterarPage }, */
  { path: "/cadastros/cliente/exibir", component: ClienteExibirPage },

  { path: "/cadastros/usuario/listar", component: UsuarioListarPage },
  { path: "/cadastros/usuario/incluir", component: UsuarioIncluirPage },
  { path: "/cadastros/usuario/alterar", component: UsuarioAlterarPage },
  { path: "/cadastros/usuario/exibir", component: UsuarioExibirPage },

  { path: "/cadastros/categoria/listar", component: CategoriaListarPage },
  { path: "/cadastros/categoria/incluir", component: CategoriaIncluirPage },
  { path: "/cadastros/categoria/alterar", component: CategoriaAlterarPage },
  { path: "/cadastros/categoria/exibir", component: CategoriaExibirPage },

  { path: "/cadastros/servico/listar", component: ServicoListarPage },
  { path: "/cadastros/servico/incluir", component: ServicoIncluirPage },
  { path: "/cadastros/servico/alterar", component: ServicoAlterarPage },
  { path: "/cadastros/servico/exibir", component: ServicoExibirPage },

  { path: "/cadastros/configuracao", component: CadastroConfiguracao },

  { path: "/notificacao/envio-manual", component: NotificacaoEnvioManual },
  { path: "/notificacao", component: Notificacao },

  { path: "/agendamento/agenda", component: AgendamentoAgenda },

  { path: "/vendas/historico", component: VendaHistorico },
  { path: "/vendas/registro-manual", component: VendaRegistroManual },

  { path: "/sorteios/sorteio-manual", component: SorteioManual },

  { path: "/cashback/extrato", component: CashbackExtrato },

  { path: "/suporte/contato", component: SuporteContato },
  { path: "/suporte/sobre", component: SuporteSobre },

  { path: "/profile", component: UserProfile },
  { path: "/alterar-senha", component: AlterarSenha },

  { path: "/", exact: true, component: () => <Redirect to="/painel" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },

  /*
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/lock-screen", component: LockScreen }, */

  { path: "/recuperar-senha", component: RecuperarSenha },
  { path: "/resetar-senha", component: ResetarSenha },
];

export { userRoutes, authRoutes };
